<template>
  <json-field name="frequency" :rules="rules" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'EmployeesSetLoneWorkerFrequencyActionForm',
  inject: ['formHook'],
  data() {
    return {
      rules: [
        (v) =>
          v > 0 ||
          this.$t(
            'tt-entity-design.employees.lone-worker-fields.greater-than-zero',
          ),
        (v) =>
          v % 5 === 0 ||
          this.$t(
            'tt-entity-design.employees.lone-worker-fields.multiple-of-five',
          ),
      ],
    }
  },
})
</script>
