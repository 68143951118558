<template>
  <div class="d-flex w-100">
    <v-icon :color="isActive ? 'green' : 'grey'" small v-text="'mdi-earth'" />

    <span class="px-2 text-truncate" v-text="name" />

    <div v-if="!isActive">
      <TTooltipIcon
        icon="mdi-archive"
        icon-color="disabled"
        tooltip-text="components.region_filter.inactive"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import TTooltipIcon from '@/tt-ui/components/TTooltipIcon.vue'
import { UserRegion } from '@/tt-widget-components/components/treeview/utils'

export default Vue.extend({
  name: 'RegionListItem',
  components: {
    TTooltipIcon,
  },
  props: {
    region: {
      type: Object as PropType<UserRegion>,
      required: true,
    },
  },
  computed: {
    name(): string {
      return this.region.name
    },
    isActive(): boolean {
      return !!(this.region.status === 'ACTIVE')
    },
  },
})
</script>
