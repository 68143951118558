import { ValueOf } from '@/helpers/types/ValueOf'
import { SchedulerViewOptions } from './SchedulerWidgetHook'

export const SchedulerView = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
} as const

export type SchedulerWidgetState = {
  currentView: SchedulerViewOptions
  selectedDate: DateString
}

export type SchedulerView = ValueOf<typeof SchedulerView>

/**
 * string in the format 'YYYY-MM-DD'
 */
export type DateString = string

/**
 * string in the format 'YYYY-MM-DD HH:mm:ss'
 */
export type DateTimeString = string

/**
 * The event model for the UI.
 */
export type ViewEvent = {
  /**
   * The entity ID
   */
  id: number

  /**
   * Any string that represent the event to display in the UI.
   */
  name: string

  /**
   * In the format "YYYY-MM-DD HH:mm:ss"
   */
  start: DateTimeString

  /**
   * In the format "YYYY-MM-DD HH:mm:ss"
   */
  end: DateTimeString

  /**
   * The color name of the event, without variant.
   */
  color: string
}
