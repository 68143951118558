import { CTAType, CtaGroupsEnum, GroupTypeView, GroupedCtaTypes } from './types'
export * from './types'

/**
 * Assign each type to a group
 */
export const TYPE_GROUP_MAP: Record<CTAType, CtaGroupsEnum> = {
  // Mobile
  SLA_CROSSED_TICKET: CtaGroupsEnum.MOBILE,
  THRESHOLD_ALERT_TICKET: CtaGroupsEnum.MOBILE,
  THRESHOLD_WARNING_TICKET: CtaGroupsEnum.MOBILE,
  CANCELLATION_TIMER_EXCEEDED_TICKET: CtaGroupsEnum.MOBILE,
  INACTIVE_MOBILE_USER_TICKET: CtaGroupsEnum.MOBILE,
  WILL_BE_LATE_TICKET: CtaGroupsEnum.MOBILE,
  DISPATCH_TASK_INVALID_ADDRESS_TICKET: CtaGroupsEnum.MOBILE,

  // Runsheets
  RUNSHEET_STOPPED_LATE_TICKET: CtaGroupsEnum.RUNSHEETS,
  RUNSHEET_STARTED_LATE_TICKET: CtaGroupsEnum.RUNSHEETS,
  RUNSHEET_STOPPED_EARLY_TICKET: CtaGroupsEnum.RUNSHEETS,
  RUNSHEET_STARTED_EARLY_TICKET: CtaGroupsEnum.RUNSHEETS,

  // Safety Monitoring
  PANIC_TRIGGERED_TICKET: CtaGroupsEnum.SAFETY_MONITORING,
  GPS_DISABLED_TICKET: CtaGroupsEnum.SAFETY_MONITORING,
  ALLOW_UNQUALIFIED_EMPLOYEE_TICKET: CtaGroupsEnum.SAFETY_MONITORING,
  USER_IP_BLOCKED_TICKET: CtaGroupsEnum.SAFETY_MONITORING,
  REPORT_PRIORITY_TICKET: CtaGroupsEnum.SAFETY_MONITORING,
  UNAUTHORIZED_NUMBER_SIGNAL_TICKET: CtaGroupsEnum.SAFETY_MONITORING,

  // Shift Scheduling
  CLOCK_IN_OUTSIDE_ALLOWED_AREA_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  CLOCK_IN_WITHOUT_LAT_LON_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  UNCOVERED_SHIFT_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  SIGNAL_LATE_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  TIMER_EXCEEDED_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  CLOCK_OUT_EARLY_SHIFT_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,
  UNCLOSED_SHIFT_TICKET: CtaGroupsEnum.SHIFT_SCHEDULING,

  // Others
  PUNCH_IN_WRONG_NUMBER_TICKET: CtaGroupsEnum.OTHERS,
  CHECKPOINT_LATE_SCAN_TICKET: CtaGroupsEnum.OTHERS,
}

type Entry = [CTAType, CtaGroupsEnum]

const groupTypes = (acc, [type, group]: Entry): GroupedCtaTypes => {
  const oldGroupTypes = acc[group] || []

  return {
    ...acc,
    [group]: [...oldGroupTypes, type],
  }
}

export const groupCtaTypes = (typesToGroup: CTAType[]): GroupedCtaTypes => {
  const isInGroup = ([type, _]: Entry) => typesToGroup.includes(type)

  return Object.entries(TYPE_GROUP_MAP).filter(isInGroup).reduce(groupTypes, {})
}

export const TYPE_GROUPING: GroupedCtaTypes = Object.entries(
  TYPE_GROUP_MAP,
).reduce(groupTypes, {})

const createGroupI18nKey = (group: CtaGroupsEnum) =>
  `tt-entity-design.system-exception-tickets.group-titles.${group}`

const TYPE_GROUP_VIEW: Record<CtaGroupsEnum, GroupTypeView> = {
  [CtaGroupsEnum.MOBILE]: {
    icon: 'mdi-headset',
    color: 'blue',
    i18nKey: createGroupI18nKey(CtaGroupsEnum.MOBILE),
  },
  [CtaGroupsEnum.RUNSHEETS]: {
    icon: 'mdi-flag-checkered',
    color: 'purple',
    i18nKey: createGroupI18nKey(CtaGroupsEnum.RUNSHEETS),
  },
  [CtaGroupsEnum.SAFETY_MONITORING]: {
    icon: 'mdi-shield-account',
    color: 'red',
    i18nKey: createGroupI18nKey(CtaGroupsEnum.SAFETY_MONITORING),
  },
  [CtaGroupsEnum.SHIFT_SCHEDULING]: {
    icon: 'mdi-clock-time-four',
    color: 'orange',
    i18nKey: createGroupI18nKey(CtaGroupsEnum.SHIFT_SCHEDULING),
  },
  [CtaGroupsEnum.OTHERS]: {
    icon: 'mdi-alert-circle',
    color: 'grey',
    i18nKey: createGroupI18nKey(CtaGroupsEnum.OTHERS),
  },
}

export const getGroupTypeView = (group: CtaGroupsEnum): GroupTypeView =>
  TYPE_GROUP_VIEW[group]

export const getTypeView = (type: CTAType): GroupTypeView => {
  const typeGroup = Object.keys(TYPE_GROUPING).find((group: CtaGroupsEnum) =>
    TYPE_GROUPING[group].includes(type),
  )

  if (!typeGroup) {
    console.warn('CTA Type group not found for type:', type)
  }

  return TYPE_GROUP_VIEW[typeGroup]
}
