<template>
  <div v-html="template" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { modularManager, PresetTypes } from '@/tt-app-modular'

import { processSchedulerEventTemplate } from './helper'
import { SchedulerEventHandler } from './scheduler-event-handler'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'

type VueWithInjections = VueConstructor<
  Vue & ItemHookProvider & { entities: Record<string, any>[] }
>

export default (Vue as VueWithInjections).extend({
  name: 'SchedulerEvent',
  inject: ['getItemHook', 'entities'],
  computed: {
    userPreferences(): UserPreferenceInterface {
      return this.$appContext.authModule.getUserPreferences()
    },
    eventHandler(): SchedulerEventHandler {
      return modularManager.getResourcePreset(
        this.getItemHook().getResourceName(),
        PresetTypes.CALENDAR_EVENT,
      )?.data
    },
    template(): string {
      return this.eventHandler
        ? processSchedulerEventTemplate(
            this.eventHandler,
            this.getItemHook(),
            this.entities,
            this.userPreferences,
          )
        : `No calendar event preset for ${this.getItemHook().getResourceName()}`
    },
  },
  created() {
    this.setRequiredFields()
  },
  methods: {
    setRequiredFields(): void {
      if (!this.eventHandler?.requiredFields) return
      const { resourceMetaManager } = this.$appContext.widgetServices

      this.eventHandler
        .requiredFields({ resourceMetaManager })
        .forEach((attr) => this.getItemHook().addAttribute(attr))
    },
  },
})
</script>
