<template>
  <v-card flat>
    <div v-if="isLoading || !form">
      <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
    </div>
    <div v-else>
      <v-row no-gutters>
        <v-col>
          <t-alert
            v-show="!hasReportFields"
            class="mb-4"
            :alert-type="alertTypeIcon"
            :text="
              $t(
                'tt-entity-design.dispatch-tasks.form-fields.no-dispatcher-report-fields',
              )
            "
          />
          <ReportFieldsForm
            v-show="hasReportFields"
            :value="formModel"
            :form="form"
            @input="setReportFormValues($event)"
            @errors="reportErrors($event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import ReportFieldsForm from '@/tt-widget-views/reports/components/ReportFieldsForm.vue'
import { FormHookProvider } from '@/tt-widget-components'
import { DispatchTaskFormServiceProvider } from './types'
import { AlertConfig, AlertType, AlertTypes } from '@/tt-ui/types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormReportField',
  components: { ReportFieldsForm },
  inject: ['formHook', 'dispatchFormService'],
  computed: {
    form(): Record<string, any> | null {
      return this.dispatchFormService.getReportForm()
    },
    formModel(): Record<string, unknown> | null {
      return this.dispatchFormService.getReportFormModel()
    },
    isLoading(): boolean {
      return this.dispatchFormService.getIsLoading()
    },
    hasReportFields(): boolean {
      return this.dispatchFormService.getHasReportFields()
    },
    alertTypeIcon(): AlertConfig {
      return AlertTypes[AlertType.INFO]
    },
  },
  methods: {
    setReportFormValues(values: Record<string, unknown>): void {
      this.dispatchFormService.setReportFormValues(values)
    },
    reportErrors(errors: Record<string, unknown>): void {
      const { account, ...reportFields } = errors

      this.$emit('errors', reportFields)
    },
  },
})
</script>
