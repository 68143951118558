<template>
  <div>{{ $t('common.action_confirmation_question') }}</div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ActionFormConfirmation',
})
</script>
