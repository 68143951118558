<template>
  <div>
    <MapCoordinateSelector
      v-if="clientItems"
      class="pt-2 dispatch-form--location-map"
      :prevent-click="preventClick"
      :coordinates="coordinates"
      @input="$emit('input:set-position', $event)"
    />

    <!-- Location Tabs & Address/Coordinates fields -->
    <DispatchTasksFormLocationTabs
      @input:new-client="$emit('input:new-client', $event)"
    />

    <v-divider class="px-3 pt-3" />

    <DispatchTasksFormLocationRegion
      :latitude="coordinates.latitude"
      :longitude="coordinates.longitude"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import DispatchTasksFormLocationTabs from './DispatchTasksFormLocationTabs.vue'
import MapCoordinateSelector from '@/tt-widget-components/components/map/MapCoordinateSelector.vue'
import { FormHookProvider } from '@/tt-widget-components/types'
import { DispatchTaskFormServiceProvider } from './types'
import { ClientInterface, LocationType } from '../types'
import { Coordinates } from '@tracktik/tt-geo-proxy/lib/GeocodeSdk/types'
import DispatchTasksFormLocationRegion from './DispatchTasksFormLocationRegion.vue'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormLocationFields',
  components: {
    DispatchTasksFormLocationTabs,
    MapCoordinateSelector,
    DispatchTasksFormLocationRegion,
  },
  inject: ['formHook', 'dispatchFormService'],
  computed: {
    preventClick(): boolean {
      return (
        this.formHook().getPathValue('locationType') === LocationType.ADDRESS
      )
    },
    clientItems(): ClientInterface {
      return this.dispatchFormService.getClientItems()
    },
    coordinates(): Coordinates {
      return {
        latitude: this.formHook().getPathValue('location.latitude'),
        longitude: this.formHook().getPathValue('location.longitude'),
      }
    },
  },
})
</script>

<style scoped>
.dispatch-form--location-map >>> .map--container .maplibregl-map {
  border-radius: 4px;
}
</style>
