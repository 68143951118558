<template>
  <component
    :is="component"
    :close-on-content-click="false"
    left
    bottom
    offset-y
  >
    <template #activator="{ on }">
      <TopBarButton text="extensions.account.label">
        <v-btn
          tile
          text
          class="transparent opacity-blended h-100 w-100"
          small
          v-on="on"
        >
          <v-badge
            :value="presenceStatus"
            :color="badgeColor"
            bordered
            bottom
            dot
            offset-x="10"
            offset-y="10"
          >
            <v-avatar :size="36">
              <v-img :src="avatar" />
            </v-avatar>
          </v-badge>
          <!-- @todo use better strategy https://github.com/TrackTik/tt-platform/pull/2245 -->
          <span v-show="false" class="walkme user-name" v-text="name" />
        </v-btn>
      </TopBarButton>
    </template>

    <v-card color="level1" :style="{ width: isSmallScreen ? '100%' : '350px' }">
      <div class="user-info">
        <div class="avatar">
          <v-avatar :size="120">
            <v-img :src="avatar" height="120" />
          </v-avatar>
        </div>

        <div class="user-name">
          {{ name }}
        </div>
      </div>

      <v-divider />

      <v-card class="px-4" flat color="level1">
        <EntityItem
          :entity-id="userProfile.id"
          :entity="userProfile"
          resource-name="me"
        >
          <tt-attr-row name="jobTitle" />
          <tt-attr-row name="region">
            <tt-attr name="region.name" />
          </tt-attr-row>
          <tt-attr-row name="email" />
          <tt-attr-row name="primaryPhone" />
          <tt-attr-row name="address">
            <tt-attr name="address.addressLine1" />&nbsp;
            <tt-attr name="address.addressLine2" /><br />
            <tt-attr name="address.city" />&nbsp;
            <tt-attr name="address.postalCode" />&nbsp;
            <tt-attr name="address.state" />
          </tt-attr-row>
        </EntityItem>
      </v-card>

      <v-list dense color="level1">
        <v-list-item>
          <v-list-item-icon class="mr-2">
            <TTooltipIcon
              icon="mdi-web"
              icon-color="grey"
              :tooltip-text="$t('extensions.user-component.portal-domain')"
            />
          </v-list-item-icon>

          <v-list-item-content class="serverUrl">
            {{ serverUrl }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon class="mr-2">
            <TTooltipIcon
              icon="mdi-cellphone-link"
              icon-color="grey"
              :tooltip-text="$t('extensions.user-component.app-version')"
            />
          </v-list-item-icon>

          <v-list-item-content class="serverUrl">
            <div class="app-release text-caption" v-text="appRelease" />
          </v-list-item-content>
        </v-list-item>

        <LanguageSelect />

        <v-divider />

        <v-list-item @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t('user_section.sign_out')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </component>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { LayoutWindowEvent } from '@/tt-app-layout'

import { EmployeeResponse } from 'tracktik-sdk/lib/entities/employee'
import TopBarButton from '@/tt-app-layout/components/TopBarButton.vue'
import {
  PresenceStatusProvider,
  UserActivityStatus,
} from '@/tt-app-extensions/presence-tracking/types'
import {
  statusColorDictionary,
  DEFAULT_STATUS_COLOR,
} from '@/tt-app-extensions/presence-tracking/constants'

import LanguageSelect from '@/locales/components/LanguageSelect.vue'
import { PACKAGE_VERSION } from '@/helpers/package-version'
export default (Vue as VueConstructor<Vue & PresenceStatusProvider>).extend({
  name: 'UserSectionComponent',
  components: { TopBarButton, LanguageSelect },
  inject: {
    // if presenceStatus provider is not found, we don't want to display the badge,
    // we set the default value to false
    presenceStatus: { default: false },
  },
  computed: {
    isSmallScreen(): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    component(): 'VDialog' | 'VMenu' {
      return this.isSmallScreen ? 'VDialog' : 'VMenu'
    },
    serverUrl(): string {
      return new URL(this.$appContext.authModule.getServerUrl()).origin
    },
    name(): string {
      return `${this.$auth.getUserProfile()?.firstName} ${
        this.$auth.getUserProfile()?.lastName
      } `
    },
    avatar(): string {
      return this.$auth.getUserProfile()?.avatar ?? null
    },
    userProfile(): EmployeeResponse {
      return this.$auth.getUserProfile()
    },
    appRelease(): string {
      return PACKAGE_VERSION
    },
    userStatus(): UserActivityStatus | null {
      if (this.presenceStatus) {
        return this.presenceStatus()
      }

      return null
    },
    badgeColor(): string {
      return this.userStatus
        ? statusColorDictionary[this.userStatus]
        : DEFAULT_STATUS_COLOR
    },
  },
  methods: {
    async signOut() {
      // eslint-disable-next-line no-console
      await this.$pushService?.unregister().catch(console.error)
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIGN_OUT, null)
    },
  },
})
</script>

<style scoped>
.avatar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 8px 0;
}
.serverUrl {
  font-size: 0.8rem;
}
.serverIcon {
  margin: 0;
  padding: 0;
}
.user-name {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  padding-bottom: 8px;
}
</style>
