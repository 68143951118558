<template>
  <div class="operation-center--form-wrapper">
    <ProvideItemHook :provide="itemHook" class="fill-height">
      <v-tabs
        v-model="activeTab"
        slider-color="orange"
        background-color="transparent"
      >
        <v-tab v-for="({ title }, index) in tabs" :key="index">
          <span v-text="title" />
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" class="operation-center--tabs-content">
        <v-tab-item
          v-for="({ is, props }, index) in tabs"
          :key="index"
          class="fill-height"
        >
          <component :is="is" v-bind="{ ...props }" @cancel="$emit('back')" />
        </v-tab-item>
      </v-tabs-items>
    </ProvideItemHook>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, AsyncComponent, Component } from 'vue'

import { TranslateResult } from 'vue-i18n'
import { OPERATION_CENTERS } from '@/tt-entity-design/src/components/operation-centers/constants'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import OperationCentersEmployees from './OperationCentersEmployees.vue'
import OperationCentersRegions from './OperationCentersRegions.vue'
import { getAttr } from './utils'
import { DialogFormInterface } from '@/tt-app-layout'

type Tab = {
  title: TranslateResult
  is: Component | AsyncComponent | string
  props?: Record<string, any>
}
export default Vue.extend({
  name: 'OperationCentersFormEdit',
  props: {
    payload: {
      type: Object as PropType<DialogFormInterface>,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.payload.userContext.itemHook
    },
    tabs(): Tab[] {
      return [
        {
          title: this.$t('common.details'),
          is: 'GenericForm',
          props: {
            ...this.payload,
          },
        },
        {
          title: this.$t(
            createI18nResourceKey(OPERATION_CENTERS, getAttr('operators')),
          ),
          is: OperationCentersEmployees,
          props: {
            isEditMode: true,
          },
        },
        {
          title: this.$t(
            createI18nResourceKey(OPERATION_CENTERS, getAttr('regions')),
          ),
          is: OperationCentersRegions,
          props: {
            isEditMode: true,
          },
        },
      ]
    },
  },
})
</script>

<style scoped>
.operation-center--form-wrapper {
  height: 100%;
  overflow: hidden;
}
.operation-center--tabs-content {
  height: calc(100% - 25px);
  overflow-y: auto;
}
</style>
