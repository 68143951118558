<template>
  <div>
    <template v-if="loading">
      <v-skeleton-loader type="list-item, article, article" />
    </template>
    <template v-else>
      <v-alert type="info">
        {{ messageText }}
      </v-alert>
      <json-field v-if="isExtendable" name="childExtension" :items="items" />
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components/types'
import { ReportTemplateArchiveChildExtensionAction } from './types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>
export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplatesArchive',
  inject: ['formHook'],
  props: {
    isExtendable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    messageText(): string {
      return this.$t('tt-entity-design.report-templates.archive.message')
    },
    archiveChildrenText(): string {
      return this.$t(
        'tt-entity-design.report-templates.archive.ARCHIVE_CHILDREN',
      )
    },
    relinkChildrenText(): string {
      return this.$t(
        'tt-entity-design.report-templates.archive.RELINK_CHILDREN',
      )
    },
    items(): { text: string; value: string }[] {
      return [
        {
          text: this.archiveChildrenText,
          value: ReportTemplateArchiveChildExtensionAction.ARCHIVE_CHILDREN,
        },
        {
          text: this.relinkChildrenText,
          value: ReportTemplateArchiveChildExtensionAction.RELINK_CHILDREN,
        },
      ]
    },
  },
})
</script>
