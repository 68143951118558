import { AttendanceStatus } from './types'

export const ATTENDANCE_STATUS_CLASS: Record<AttendanceStatus, string> = {
  COMPLETED:
    'font-weight-bold text--darken-2 green--text green lighten-5 text-uppercase',
  IN_PROGRESS:
    'font-weight-bold text--darken-2 blue--text blue lighten-5 text-uppercase',
  LATE: 'font-weight-bold text--darken-2 orange--text orange lighten-5 text-uppercase',
  MISSED:
    'font-weight-bold text--darken-2 red--text red lighten-5 text-uppercase',
  SCHEDULED:
    'font-weight-bold text--darken-2 cyan--text cyan lighten-5 text-uppercase',
  ON_BREAK:
    'font-weight-bold text--darken-2 amber--text amber lighten-5 text-uppercase',
  OVER_CLOCK:
    'font-weight-bold text--darken-2 deep-purple--text deep-purple lighten-5 text-uppercase',
  EARLY_CLOCK_OUT:
    'font-weight-bold text--darken-2 pink--text pink lighten-5 text-uppercase',
  PTO: 'font-weight-bold text--darken-2 brown--text brown lighten-5 text-uppercase',
}

export const DEFAULT_ATTENDANCE_STATUS_COLOR =
  'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase'

export const MAIN_DASHBOARD_SUBSET_ATTENDANCE_STATUS: AttendanceStatus[] = [
  'SCHEDULED',
  'LATE',
  'IN_PROGRESS',
  'ON_BREAK',
  'OVER_CLOCK',
  'PTO',
]

export const SEARCH_VIEW_SUBSET_ATTENDANCE_STATUS: AttendanceStatus[] = [
  'MISSED',
  'COMPLETED',
  'EARLY_CLOCK_OUT',
]
