<template>
  <div>
    <DispatchTasksFormAssignButton
      v-if="!hasAssignedUser && !hasAssignedGroup"
      class="my-2"
      @input="setAssignment"
    />

    <DispatchAssignSelectedItem
      v-if="assignment"
      :key="`${assignment.id}-${assignment.resource}`"
      class="my-2"
      :resource="assignment.resource"
      :entity-id="assignment.id"
      @clear="clear(assignment.resource)"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import DispatchTasksFormAssignButton from './DispatchTasksFormAssignButton.vue'
import DispatchAssignSelectedItem from '../assign/DispatchAssignSelectedItem.vue'
import { FormHookProvider } from '@/tt-widget-components'
import { AddressInterface, PayloadAssignment, SelectPayload } from '../types'
import { Resources } from '../../../types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormAssignUser',
  components: {
    DispatchTasksFormAssignButton,
    DispatchAssignSelectedItem,
  },
  inject: ['formHook', 'dispatchFormService'],
  computed: {
    hasAssignedUser(): boolean {
      return !!this.selectedUser
    },
    hasAssignedGroup(): boolean {
      return !!this.assignedGroup
    },
    selectedUser(): number | null {
      return this.formHook().getPathValue('assignedUser')
    },
    assignedGroup(): number | null {
      return this.formHook().getPathValue('assignedGroup')
    },
    clientId(): number | null {
      return this.formHook().getPathValue('client')
    },
    taskId(): number | null {
      return this.formHook().getPathValue('taskType')
    },
    location(): AddressInterface | number | null {
      return this.formHook().getPathValue('location')
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    assignment():
      | PayloadAssignment['user']
      | PayloadAssignment['group']
      | null {
      return this.dispatchFormService.getAssignment()
    },
  },
  watch: {
    clientId(id) {
      if (!id) {
        this.clearAll()
      }
    },
    taskId(id) {
      if (!id) {
        this.clearAll()
      }
    },
  },
  created() {
    /**
     * When we edit dispatch task form location field is a number and we need to fetch the address information
     */
    if (typeof this.location === 'number') {
      this.fetchAddress(this.location).then((address: AddressInterface) => {
        this.formHook().setObjectValue('location', address)
      })
    }
    if (this.selectedUser) {
      this.dispatchFormService.setAssignment({
        resource: Resources.USERS,
        id: this.selectedUser,
      })
    }
    // In certain cases we have an assignedUser and an assignedGroup
    // in this case we will display the user
    if (this.assignedGroup && !this.hasAssignedUser) {
      this.dispatchFormService.setAssignment({
        resource: this.itemHook?.getRawValue('assignedGroupResource.type'),
        id: this.assignedGroup,
      })
    }
  },
  methods: {
    async fetchAddress(id) {
      return await this.$appContext.authModule
        .getApi()
        .get(Resources.ADDRESSES, id)
    },
    setAssignment({ resource, id }: SelectPayload): void {
      if (resource === Resources.USERS) {
        const payload: PayloadAssignment['user'] = {
          resource,
          id,
        }

        this.dispatchFormService.setAssignment(payload)
        this.formHook().setObjectValue('assignedUser', payload.id)

        if (this.hasAssignedGroup) {
          this.formHook().setObjectValue('assignedGroup', null)
        }
      } else {
        const payload: PayloadAssignment['group'] = {
          // @ts-ignore -- API schema is missing "positions"
          resource,
          id,
        }
        this.dispatchFormService.setAssignment(payload)
        this.formHook().setObjectValue('assignedGroup', payload)

        if (this.hasAssignedUser) {
          this.formHook().setObjectValue('assignedUser', null)
        }
      }
      this.$emit('back')
    },
    clear(resource: string) {
      const value = resource === 'users' ? 'assignedUser' : 'assignedGroup'

      this.dispatchFormService.setAssignment(null)
      this.formHook().setObjectValue(value, null)
    },
    clearAll() {
      this.dispatchFormService.setAssignment(null)
      if (this.hasAssignedUser) {
        this.formHook().setObjectValue('assignedUser', null)
      }
      if (this.hasAssignedGroup) {
        this.formHook().setObjectValue('assignedGroup', null)
      }
    },
  },
})
</script>
