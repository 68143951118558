import { Field } from 'tracktik-sdk/lib/common/entity-collection'
import { TaskTypesBillable } from '../types'

export const DEFAULT_WHITELISTED_VALUES: string[] = [
  'assignedGroup',
  'account',
  'report.reportTemplate',
  'report.account',
]

export const COORDINATES_WHITELISTED_VALUES: string[] = [
  'location.dispatchTask',
  'location.addressLine1',
  'location.city',
  'location.postalCode',
]

export const ADDRESS_WHITELISTED_VALUES: string[] = [
  'location.dispatchTask',
  'location.latitude',
  'location.longitude',
]

export const LOCATION_WHITELISTED_VALUE = 'location'

export const TASK_TYPES_FIELDS: Field[] = [
  { attribute: 'billable' },
  { attribute: 'reportTemplate' },
  { attribute: 'priority' },
]

export const CLIENT_ITEMS_FIELDS: Field[] = [
  { attribute: 'name' },
  { attribute: 'region.name' },
  { attribute: 'region.id' },
  { attribute: 'customId' },
  { attribute: 'address.id' },
  { attribute: 'address.addressLine1' },
  { attribute: 'address.addressLine2' },
  { attribute: 'address.city' },
  { attribute: 'address.state' },
  { attribute: 'address.latitude' },
  { attribute: 'address.longitude' },
]

export const BILLABLE_VALUES: Record<TaskTypesBillable, TaskTypesBillable> = {
  DO_NOT_REQUIRE_CONTRACT: 'DO_NOT_REQUIRE_CONTRACT',
  REQUIRE_CONTRACT: 'REQUIRE_CONTRACT',
}

export const TAB_KEYS = {
  MAIN: 'main',
  REPORT: 'report',
}
