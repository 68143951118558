// Enter your custom installation here
import SystemExceptionTicketsRelationAttr from './SystemExceptionTicketsRelationAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'
import SystemExceptionTicketsEscalationsAttr from './SystemExceptionTicketsEscalationsAttr.vue'
import SystemExceptionTicketsActionBar from './SystemExceptionTicketsActionBar.vue'
import SystemExceptionTicketsNotesListWidget from './notes/SystemExceptionTicketsNotesListWidget.vue'
import { modularManager } from '@/tt-app-modular'
import { Resources } from '../../types'
import { addPreviewTab, setAttributeView } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import SystemExceptionTicketsOperator from './SystemExceptionTicketsOperator.vue'
import SystemExceptionTicketsContactsListWidget from '@/tt-entity-design/src/components/system-exception-tickets/contacts/SystemExceptionTicketsContactsListWidget.vue'
import i18n from '@/plugins/i18n'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'
import SystemExceptionTicketsTypeAttr from './cta-types/SystemExceptionTicketsTypeAttr.vue'

const SYSTEM_EXCEPTION_TICKETS = Resources.SYSTEM_EXCEPTION_TICKETS

export default {
  install(Vue) {
    Vue.component(
      'SystemExceptionTicketsRelationAttr',
      SystemExceptionTicketsRelationAttr,
    )
    Vue.component(
      'SystemExceptionTicketsStatusAttr',
      SystemExceptionTicketsStatusAttr,
    )
    Vue.component(
      'SystemExceptionTicketsActionBar',
      SystemExceptionTicketsActionBar,
    )
    Vue.component(
      'SystemExceptionTicketsEscalationsAttr',
      SystemExceptionTicketsEscalationsAttr,
    )
    Vue.component(
      'SystemExceptionTicketsOperator',
      SystemExceptionTicketsOperator,
    )

    modularManager.registerActionBar(Resources.SYSTEM_EXCEPTION_TICKETS, {
      is: SystemExceptionTicketsActionBar,
    })

    setAttributeView(SYSTEM_EXCEPTION_TICKETS, 'type', {
      // @ts-ignore
      is: SystemExceptionTicketsTypeAttr,
    })

    addPreviewTab(SYSTEM_EXCEPTION_TICKETS, {
      title: createI18nResourceKey(
        Resources.SYSTEM_EXCEPTION_TICKETS,
        'comments',
      ),
      is: SystemExceptionTicketsNotesListWidget,
    })

    addPreviewTab(SYSTEM_EXCEPTION_TICKETS, {
      title: i18n.t('tt-entity-design.system-exception-tickets.contacts.title'),
      is: SystemExceptionTicketsContactsListWidget,
    })

    addPreviewTab(SYSTEM_EXCEPTION_TICKETS, {
      title: createI18nResourceKey(
        Resources.SYSTEM_EXCEPTION_TICKETS,
        'escalations',
      ),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'systemExceptionTicket',
        widget: {
          title: '',
          is: WidgetName.LIST_WIDGET,
          query: {
            resource: Resources.OPERATION_CENTER_ESCALATION_TICKETS,
            sort: [
              {
                attribute: 'level',
                direction: SortDirectionType.DESC,
              },
              {
                attribute: 'createdOn',
                direction: SortDirectionType.DESC,
              },
            ],
          },
        },
      },
    })
  },
}
