<template>
  <div class="ma-3 d-flex align-center">
    <tt-attr name="requiredOn" />
    <LoneWorkerCheckInStatus class="mx-3" />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import LoneWorkerCheckInStatus from './LoneWorkerCheckInStatus.vue'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'LoneWorkerCheckInsTile',
  components: { LoneWorkerCheckInStatus },
  inject: ['relationPrefix', 'getItemHook'],
})
</script>
