<template>
  <span :class="computedClass">
    <tt-attr :name="attributeName" />
  </span>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EntityChip',
  inject: ['getItemHook'],
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    chipClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedClass(): string {
      const rawValue = this.getItemHook().getRawValue(
        this.attributeName,
      ) as string
      if (
        rawValue === undefined ||
        rawValue === null ||
        rawValue.trim() === ''
      ) {
        return ''
      }

      return `v-chip v-chip--label v-size-small t-chip-container ${this.chipClass}`
    },
  },
  created() {
    this.getItemHook().addAttribute(this.attributeName)
  },
})
</script>
<style scoped>
.t-chip-container {
  background-color: inherit;
  font-size: 10px;
  height: fit-content !important;
  width: fit-content !important;
  font-weight: bold;
  justify-content: center;
  display: block;
  text-overflow: ellipsis;
}
</style>
