// Enter your custom installation here
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { ListWidgetModel } from '@/tt-widget-components'
import { modularManager, PresetLevels, PresetTypes } from '@/tt-app-modular'
import { registerResourceForm } from '@/tt-widget-entity-flow/helper'

import SiteTaskOccurrenceAssetsList from './SiteTaskOccurrenceAssetsList.vue'
import SiteTaskOccurrencesCompleteReportForm from './SiteTaskOccurrencesCompleteReportForm.vue'
import SiteTaskOccurrencesReasonToConvertAttrRow from './SiteTaskOccurrencesReasonToConvertAttrRow.vue'
import SiteTaskOccurrencesReasonToConvertCard from './SiteTaskOccurrencesReasonToConvertCard.vue'
import SiteTaskOccurrenceStatus from './SiteTaskOccurrenceStatus.vue'
import SiteTaskOccurrenceTime from './SiteTaskOccurrenceTime.vue'
import { Resources } from '../../types'
import { siteTaskOccurrencesSchedulerEventHandler } from './SiteTaskOccurrencesSchedulerEvent'
import { SiteTaskOccurrencesAction } from './types'
import CompleteReportJsonObject from './report/CompleteReportJsonObject.vue'

export default {
  install(Vue) {
    Vue.component('CompleteReportJsonObject', CompleteReportJsonObject)
    Vue.component('SiteTaskOccurrenceAssetsList', SiteTaskOccurrenceAssetsList)
    Vue.component(
      'SiteTaskOccurrencesCompleteReportForm',
      SiteTaskOccurrencesCompleteReportForm,
    )
    Vue.component(
      'SiteTaskOccurrencesReasonToConvertAttrRow',
      SiteTaskOccurrencesReasonToConvertAttrRow,
    )
    Vue.component(
      'SiteTaskOccurrencesReasonToConvertCard',
      SiteTaskOccurrencesReasonToConvertCard,
    )
    Vue.component('SiteTaskOccurrenceStatus', SiteTaskOccurrenceStatus)
    Vue.component('SiteTaskOccurrenceTime', SiteTaskOccurrenceTime)

    modularManager.addItem(
      `${Resources.SITE_TASK_OCCURRENCES}.preview.actions.top`,
      { is: 'SiteTaskOccurrencesReasonToConvertCard' },
    )

    modularManager.addResourcePreset(
      Resources.SITE_TASK_OCCURRENCES,
      PresetTypes.CALENDAR_EVENT,
      {
        title: 'Default',
        data: siteTaskOccurrencesSchedulerEventHandler,
        level: PresetLevels.DEFAULTS,
      },
    )

    registerResourceForm(
      Resources.SITE_TASK_OCCURRENCES,
      'SiteTaskOccurrencesCompleteReportForm',
      SiteTaskOccurrencesAction.CompleteReport,
    )
    modularManager.registerDynamicResourceJsonSchemaActions(
      Resources.SITE_TASK_OCCURRENCES,
      [SiteTaskOccurrencesAction.CompleteReport],
    )

    const sessionLogListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.SITE_TASK_OCCURRENCE_SESSION_LOGS,
        sort: {
          attribute: 'id',
          direction: 'DESC',
        },
      },
    }
    addPreviewTab(Resources.SITE_TASK_OCCURRENCES, {
      title: 'res.site-task-occurrence-sessions.attr.logs.label',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'session.id',
        valueAttribute: 'session.id',
        widget: sessionLogListWidget,
      },
      condition: ({ itemHook }) => !!itemHook.get('session.id'),
    })

    addPreviewTab(Resources.SITE_TASK_OCCURRENCES, {
      title:
        'res.site-task-occurrence-sessions.attr.checkpointTourSession.label',
      is: 'EntityRelationPreview',
      props: {
        name: 'session.checkpointTourSession',
      },
      condition: ({ itemHook }) =>
        !!itemHook.get('session.checkpointTourSession.id'),
    })

    addPreviewTab(Resources.SITE_TASK_OCCURRENCES, {
      title: 'res.task-site-instructions.attr.siteTaskAssets.label',
      is: 'SiteTaskOccurrenceAssetsList',
      condition: ({ itemHook }) => !!itemHook.get('taskSiteInstruction.id'),
    })
  },
}
