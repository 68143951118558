import {
  Filter,
  FilterOperatorType,
} from 'tracktik-sdk/lib/common/entity-filters'
import { mapEntityCollectionQuery } from 'tracktik-sdk/lib/connectors/rest-api-entity-helpers'

import BaseQueryManager from '@/tt-widget-components/base/BaseQueryManager'

import { TQLQuery } from '../widgets/TQLQuery/schema'

export default class TQLQueryManager extends BaseQueryManager<TQLQuery> {
  /**
   * Get the export URL
   */
  public getUrl(extraParams: { [k: string]: any } = {}) {
    const filters = [...this.filters] as Filter[]

    filters.push({
      attribute: 'tql',
      operator: FilterOperatorType.EQUAL,
      value: this.initialQuery.tql,
    })

    const addExtraParamsToFilters = ([attribute, value]) => {
      filters.push({
        attribute,
        operator: FilterOperatorType.EQUAL,
        value,
      })
    }

    Object.entries(extraParams).forEach(addExtraParamsToFilters)

    // Get the queries out
    const queries = mapEntityCollectionQuery({
      includeInactive: this.includeInactive,
      scope: this.scope,
      search: this.search,
      filters,
    })

    return `tql${queries}`
  }
}
