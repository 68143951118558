<template>
  <v-card v-if="totalAssets > 0" class="w-100 mb-3" flat hover>
    <v-card-actions v-if="!hideCollapsibleHeader" @click="show = !show">
      <v-btn text :ripple="false"> Assets ({{ totalAssets }}) </v-btn>

      <v-spacer />

      <v-btn icon>
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider />

        <v-card-text>
          <div v-if="!hideAssetLinkButton" class="d-flex justify-end">
            <TaskDispatchClientAssetLinkButton />
          </div>

          <div v-if="zoneAssets.length > 0" class="flex--column w-100">
            <div class="pl-3 pb-3 overline">
              Zone Assets ({{ zoneAssets.length }})
            </div>

            <TaskAssetsTree :assets="zoneAssets" />
          </div>

          <div v-if="siteAssets.length > 0" class="flex--column w-100">
            <div class="pl-3 pb-3 overline">
              Site Assets ({{ siteAssets.length }})
            </div>
            <TaskAssetsTree
              :assets="siteAssets"
              :on-opening-children="fetchSubAssets"
            />
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import TaskAssetsTree from './TaskAssetsTree.vue'
import TaskDispatchClientAssetLinkButton from './TaskDispatchClientAssetLinkButton.vue'
import { useAssetManager } from '../asset-manager'
import { TreeViewAsset } from '../asset-manager/types'

export default Vue.extend({
  name: 'TaskDispatchClientListAssets',
  components: {
    TaskAssetsTree,
    TaskDispatchClientAssetLinkButton,
  },
  props: {
    hideCollapsibleHeader: {
      type: Boolean,
      default: false,
    },
    hideAssetLinkButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const assetManager = useAssetManager()

    return { assetManager }
  },
  data: () => ({
    show: false,
  }),
  computed: {
    siteAssets(): TreeViewAsset[] {
      return this.assetManager().getSiteAssets()
    },
    zoneAssets(): TreeViewAsset[] {
      return this.assetManager().getZoneAssets()
    },
    totalAssets(): number {
      return this.siteAssets.length + this.zoneAssets.length
    },
  },
  created() {
    if (this.hideCollapsibleHeader) {
      this.show = true
    }
  },
  methods: {
    async fetchSubAssets(item: TreeViewAsset) {
      await this.assetManager().loadSubAssets(item.id)
    },
  },
})
</script>
