// Enter your custom installation here
import { addPreviewTab, setAttributeView } from '@/tt-widget-entity-flow/helper'
import {
  createI18nPluralResourceKey,
  createI18nResourceKey,
} from '@/tt-widget-entity-flow/ResourceTranslator'
import { ListWidgetModel } from '@/tt-widget-components'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { PreviewTabInterface } from '@/tt-widget-entity-flow/types'

import AttendanceStatusAttr from './AttendanceStatusAttr.vue'
import ShiftsDateTime from './ShiftsDateTime.vue'
import ShiftsDetailBilling from './ShiftsDetailBilling.vue'
import ShiftsDetailDate from './ShiftsDetailDate.vue'
import ShiftsDetailPayrun from './ShiftsDetailPayrun.vue'
import ShiftsDetailTimeSheet from './ShiftsDetailTimeSheet.vue'
import BreakRuleComplianceLogsList from './components/BreakRuleComplianceLogsList.vue'

import { Resources } from '../../types'
import ShiftEventActivities from './ShiftEventActivities.vue'
import LoneWorkerCheckInActivity from './LoneWorkerCheckInActivity.vue'

export default {
  install(Vue) {
    Vue.component('ShiftsDateTime', ShiftsDateTime)
    Vue.component('AttendanceStatusAttr', AttendanceStatusAttr)
    Vue.component('ShiftsDetailDate', ShiftsDetailDate)
    Vue.component('ShiftsDetailTimeSheet', ShiftsDetailTimeSheet)
    Vue.component('ShiftsDetailPayrun', ShiftsDetailPayrun)
    Vue.component('ShiftsDetailBilling', ShiftsDetailBilling)
    Vue.component('BreakRuleComplianceLogsList', BreakRuleComplianceLogsList)

    setAttributeView(Resources.SHIFTS, 'attendanceStatus', {
      is: 'AttendanceStatusAttr',
    })

    const shiftNotesListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.SHIFT_NOTES,
      },
    }
    const breakExceptionsListWidget: ListWidgetModel = {
      title: '',
      is: 'ListWidget',
      query: {
        resource: Resources.BREAK_RULE_EXCEPTIONS,
      },
    }

    const shiftNotes: PreviewTabInterface = {
      title: createI18nResourceKey(Resources.SHIFTS, 'notes'),
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'shift.id',
        widget: shiftNotesListWidget,
      },
    }

    const breakRuleExceptions: PreviewTabInterface = {
      title: 'tt-entity-design.exceptions',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'shift.id',
        widget: breakExceptionsListWidget,
      },
    }

    const breakRuleComplianceLogs: PreviewTabInterface = {
      title: 'tt-entity-design.compliance',
      is: 'BreakRuleComplianceLogsList',
    }

    const eventActivities: PreviewTabInterface = {
      title: createI18nPluralResourceKey(Resources.EVENT_ACTIVITIES),
      is: ShiftEventActivities,
    }

    const loneWorkerCheckInActivity: PreviewTabInterface = {
      title: 'tt-entity-design.shifts.tabs.loneWorkerCheckInActivity',
      is: LoneWorkerCheckInActivity,
    }

    addPreviewTab(Resources.SHIFTS, shiftNotes)
    addPreviewTab(Resources.SHIFTS, breakRuleExceptions)
    addPreviewTab(Resources.SHIFTS, eventActivities)
    addPreviewTab(Resources.SHIFTS, loneWorkerCheckInActivity)
    addPreviewTab(Resources.SHIFTS, breakRuleComplianceLogs)

    // Adding extra detail cards
    const dateDetail: PresetItem = {
      title: 'tt-entity-design.dates',
      data: { is: 'ShiftsDetailDate', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    const timeSheetDetail: PresetItem = {
      title: 'tt-entity-design.timesheet',
      data: { is: 'ShiftsDetailTimeSheet', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    const payrunDetail: PresetItem = {
      title: 'tt-entity-design.payrun-information',
      data: { is: 'ShiftsDetailPayrun', opened: false },
      order: 3,
      level: PresetLevels.DEFAULTS,
    }

    const billingDetail: PresetItem = {
      title: 'tt-entity-design.billing-information',
      data: { is: 'ShiftsDetailBilling', opened: false },
      order: 4,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.SHIFTS,
      PresetTypes.DETAILS_CARD,
      [dateDetail, timeSheetDetail, payrunDetail, billingDetail],
    )
  },
}
