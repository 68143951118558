<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <TChip :color="view.color" v-on="on" @click="$emit('click')">
        <v-icon :color="view.color" v-text="view.icon" />
        <span class="text-truncate pl-1" v-text="text" />
      </TChip>
    </template>

    <span v-text="text" />
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getTypeView } from './index'
import { GroupTypeView, CTAType } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsTypeAttr',
  inject: ['getItemHook'],
  computed: {
    key(): CTAType {
      return this.getItemHook().getRawValue('type')
    },
    text(): string {
      return this.getItemHook().get('type')
    },
    view(): GroupTypeView {
      return getTypeView(this.key)
    },
  },
  created() {
    this.getItemHook().addAttribute('type')
  },
})
</script>
