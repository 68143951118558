import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { ResourceMetaManagerInterface } from '@/tt-widget-factory'
import { UserPreferenceInterface } from '@tracktik/tt-authentication'

export type SchedulerEventHandler = {
  /**
   * The resource's attributes that must be requested to the API as "fields".
   * It must include all the attributes used in the template, and the ones
   * mapped to the SyncFusion Schedule component as "fields"
   */
  requiredFields: ({
    resourceMetaManager,
  }: {
    resourceMetaManager: ResourceMetaManagerInterface
  }) => string[]
  /**
   * HTML template as a string. Add styles inline. Example:
   * ```
   * <div>
   *   Some static text
   *   <strong>${myCoolAttribute}</strong>
   * </div>
   * ```
   */
  template: string
  /**
   * Returned object must contain the fields mapped to the Scheduler's
   * subject, startTime, and endTime, otherwise SyncFusion won't have access to
   * these properties and won't be able to place the event in the appropriate
   * calendar slot.
   */
  transform: (options: {
    itemHook: EntityItemHook
    entities: Record<string, any>[]
    userPreferences: UserPreferenceInterface
  }) => { id: number; [key: string]: any }
}

export const SCHEDULE_EVENT_CSS_CLASS_NAME = 'scheduler-event-preset'

export const wrapSchedulerEventTemplate = (
  template: string,
): string => /* html */ `
<div class="${SCHEDULE_EVENT_CSS_CLASS_NAME}" style="width: 100%">
  ${template}
</div>
`
