<template>
  <v-treeview
    :value="value"
    :items="items"
    :open.sync="openNodes"
    :filter="filterCallback"
    :search="focusOnSelected ? ' ' : undefined"
    :load-children="onOpeningChildren"
    transition
  >
    <template #prepend="{ item }">
      <!-- No permission lock icon -->
      <v-icon v-if="isDisabled(item)" small v-text="`mdi-lock`" />
      <!-- Checkbox -->
      <slot v-else name="checkbox" v-bind="{ item, onItemClick, isSelected }">
        <v-simple-checkbox
          dense
          hide-details
          :value="isSelected(item)"
          :disabled="readOnly"
          color="ttPrimary"
          class="d-flex"
          @input="onItemClick(item)"
        />
      </slot>
    </template>

    <template #label="{ item }">
      <div
        class="d-flex justify-flex-start label-container"
        :class="{ 'font-italic': isInactiveRegion(item) }"
      >
        <slot name="name" v-bind="{ item, onItemClick, isSelected }">
          <span
            :class="{
              'text--disabled': isInactiveRegion(item) || isDisabled(item),
            }"
            class="d-flex"
            :style="isDisabled(item) ? 'cursor: default;' : 'cursor: pointer;'"
            @click.stop="onItemClick(item)"
            v-text="item.name"
          />
        </slot>

        <div class="ml-2">
          <TTooltipIcon
            v-if="isInactiveRegion(item)"
            icon="mdi-archive"
            icon-color="disabled"
            tooltip-text="components.region_filter.inactive"
          />
        </div>

        <!-- do not show buttons on mobile -->
        <template v-if="showBatchSelectionButtons(item)">
          <v-btn
            v-if="showSubregionsButton(item)"
            :dark="!$vuetify.theme.dark"
            class="ml-2 regions-button text-truncate"
            color="ttPrimary"
            elevation="0"
            x-small
            @click="selectSubregions(item)"
          >
            <span
              class="d-inline text-truncate"
              v-text="$t('components.region_filter.select_subregions')"
            />
          </v-btn>

          <v-btn
            v-if="showRemoveButton(item)"
            class="ml-2 regions-button text-truncate"
            color="ttPrimary"
            outlined
            x-small
            @click="removeSubregions(item)"
          >
            <span
              class="d-inline text-truncate"
              v-text="$t('components.region_filter.deselect_subregions')"
            />
          </v-btn>
        </template>
      </div>
    </template>
  </v-treeview>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { TreeviewItem } from '@/tt-widget-components/components/treeview/types'
import { getAllChildren, getAllParentIdsFromTree, UserRegion } from './utils'

type Item = TreeviewItem
type ItemId = Item['id']

export default Vue.extend({
  name: 'TreeviewSelector',
  props: {
    /**
     * Array of treeview items to display in the tree.
     */
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    /**
     * Array of selected IDs.
     * The IDs must be strings.
     */
    value: {
      type: Array as PropType<ItemId[]>,
      default: () => [] as ItemId[],
      /**
       * Runtime check to ensures IDs are strings
       */
      validator: (value: unknown[]): boolean =>
        value.every((val) => typeof val === 'string'),
    },
    /**
     * If true, the whole tree is made read-only.
     */
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * Show only selected nodes and their parents.
     * Usually used with `readOnly` to show only the selection.
     */
    focusOnSelected: {
      type: Boolean,
      default: false,
    },
    /**
     * Callback to call when opening children of a node.
     * The callback should return a promise. Used to lazy-load extra information for the children.
     */
    onOpeningChildren: {
      type: Function as PropType<(childrenIds: ItemId[]) => Promise<void>>,
      default: null,
    },
    /**
     * Do not show the batch selection buttons on hover on a parent region (Select all / Unselect all).
     */
    hideBatchSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openNodes: [] as string[],
    }
  },
  computed: {
    filterCallback(): (item: Item) => boolean {
      return this.focusOnSelected ? (item) => this.isSelected(item) : undefined
    },
  },
  watch: {
    items: {
      handler() {
        // Do not automatically open nodes if we lazy load children
        if (this.onOpeningChildren) return

        this.openNodes = this.getOpenNodes()
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.openNodes = this.getOpenNodes()
  },
  methods: {
    showBatchSelectionButtons(item): boolean {
      return (
        !this.hideBatchSelection &&
        this.$vuetify.breakpoint.name !== 'xs' &&
        !this.readOnly &&
        !item.disabled
      )
    },
    isSelected(item: Item): boolean {
      return this.value.includes(item.id)
    },
    onItemClick(item: Item) {
      if (item.disabled) return

      if (this.isSelected(item)) this.unselectItem(item)
      else this.selectItem(item)
    },
    selectItem(item: Item) {
      const newSelection = [...this.value, item.id]

      this.onInput(newSelection)
    },
    unselectItem(item: Item) {
      const newSelection = this.value.filter((val) => val !== item.id)
      this.onInput(newSelection)
    },
    getOpenNodes(): ItemId[] {
      const { value, items } = this
      const topRegion = items[0]

      if (!topRegion) return []

      const hasSelection = value.filter(Boolean).length

      const getSelectedNodes = () => getAllParentIdsFromTree(value, topRegion)

      const getFirstLevelIds = () => {
        const firstLevelIds = (topRegion.children ?? []).map(({ id }) => id)

        return [topRegion.id, ...firstLevelIds]
      }

      return hasSelection ? getSelectedNodes() : getFirstLevelIds()
    },
    hasChildren(item: Item): boolean {
      return item.children && item.children?.length > 0
    },
    showSubregionsButton(item: Item): boolean {
      const childArray = []
      getAllChildren(item).forEach((child) => {
        if (child.id !== item.id) {
          childArray.push(child.id)
        }
      })

      return (
        this.hasChildren(item) &&
        !childArray.every((r) => this.value.includes(r))
      )
    },
    showRemoveButton(item: Item) {
      if (!this.hasChildren(item)) return false

      const childArray = []
      getAllChildren(item).forEach((child) => {
        if (child.id !== item.id) {
          childArray.push(child.id)
        }
      })

      return childArray.some((r) => this.value.includes(r))
    },
    removeSubregions(selectedItem: Item) {
      const allChildrenIdsToRemove = getAllChildren(selectedItem).map(
        (item) => item.id,
      )

      const ids = this.value.filter(
        (id) => !allChildrenIdsToRemove.includes(id),
      )

      this.onInput(ids)
    },
    selectSubregions(selectedItem: Item) {
      const allChildrenIdsToAdd = getAllChildren(selectedItem).map(
        (item) => item.id,
      )

      const newModel = [...this.value, ...allChildrenIdsToAdd]

      const ids = [...new Set(newModel)]
      this.onInput(ids)
    },
    onInput(ids: ItemId[]): void {
      if (this.readOnly) return

      this.$emit('input', ids)
    },
    isDisabled(item: Item): boolean {
      return !!item.disabled
    },
    // @TODO: to review how to remove status
    isInactiveRegion(item: Partial<UserRegion>): boolean {
      return item.status !== 'ACTIVE'
    },
  },
})
</script>

<style scoped>
.regions-button {
  display: none;
  opacity: 0.7;
}
.label-container:hover > .regions-button {
  display: block;
}
/* Wider zone for the :hover */
.v-treeview >>> .v-treeview-node {
  width: 100%;
}

/** Fix horizontal width 100% and avoid horizontal scrollbar */
.v-treeview >>> .v-treeview-node--leaf {
  margin: 0px !important;
}

/* Properly align child items */
.v-treeview >>> .v-treeview-node__children {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

/* 
  Hide the select checkbox if item is disabled .
  We display a lock icon instead in the #prepend slot.
*/
.v-treeview >>> .v-treeview-node__checkbox.v-icon--disabled {
  display: none;
}
</style>
