export const TQLExportFormat = {
  CSV: 'CSV',
  JSON: 'JSON',
  PDF: 'PDF',
  HTML: 'HTML',
}

/**
 * Prefix for private columns in a TQL query.
 * Columns with this prefix (e.g., `__id`) will be hidden in the UI and exports.
 */
export const TQL_PRIVATE_COLUMN_PREFIX = '__'
