<template>
  <div>
    <v-snackbar
      v-if="payload"
      v-model="layoutManager.state.snackBar.state"
      data-cy="snackbar"
      :color="color"
      dark
      transition="slide-y-reverse-transition"
      max-width="450"
      @input="$event ? reset() : null"
    >
      <template #default>
        <component
          :is="payload.is"
          v-if="payload.is"
          v-bind="{ ...payload.props, message: payload.message }"
        />
        <div v-else>
          <span class="subtitle-1 font-weight-medium" v-text="payload.title" />
          <span v-text="payload.message" />
        </div>
      </template>
      <template #action>
        <v-btn text icon dark small @click="reset">
          <v-icon small v-text="'close'" />
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script lang="ts">
import { SnackBarInterface, SnackBarTypes } from '../types'
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutSnackBarLauncher',
  methods: {
    reset() {
      this.layoutManager.state.snackBar.state = false
      this.layoutManager.state.snackBar.payload = null
    },
  },
  data() {
    return {
      timeout: null as NodeJS.Timeout,
    }
  },
  watch: {
    'layoutManager.state.snackBar.state'(val: boolean) {
      if (!val || !this.isPhone) {
        return
      }
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.layoutManager.state.snackBar.state = false
        this.timeout = null
      }, 3000)
    },
  },
  computed: {
    payload(): SnackBarInterface {
      return this.layoutManager.state.snackBar.payload
    },
    icon(): string {
      const type = this.payload?.type ?? SnackBarTypes.INFO
      const mapping = {
        [SnackBarTypes.INFO]: 'mdi-information-outline',
        [SnackBarTypes.ERROR]: 'mdi-alert-circle-outline',
        [SnackBarTypes.SUCCESS]: 'mdi-checkbox-marked-circle-outline',
      }

      return mapping[type] ?? 'info'
    },
    color(): string {
      const type = this.payload?.type ?? SnackBarTypes.INFO
      const mapping = {
        [SnackBarTypes.INFO]: 'info',
        [SnackBarTypes.ERROR]: 'error',
        [SnackBarTypes.SUCCESS]: 'success',
      }

      return mapping[type] ?? 'info'
    },
  },
})
</script>

<style lang="scss">
@import 'vuetify/src/styles/settings/_variables';

.v-snack__action {
  align-self: start;
  margin-top: $spacer * 2;
}
</style>
