<template>
  <v-dialog
    v-model="state.dialog.state"
    :fullscreen="isMobile"
    :persistent="persistent"
    :width="isMobile ? null : state.dialog.width"
    :content-class="state.dialog.state ? 'd-flex' : 'd-none'"
    class="h-100"
  >
    <v-card :style="{ height }">
      <AppWindow
        v-model="layoutManager.state.dialog.state"
        :landing-page="layoutManager.state.dialog.page"
        closable
        :event="eventType.DIALOG"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'

import { disableDocumentScroll, enableDocumentScroll } from './scroll'
import { LayoutWindowEvent } from '../types'

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutDialog',
  props: {
    lockScroll: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isOpen(): boolean {
      return this.state.dialog.state
    },
    height(): string {
      const height = this.layoutManager.state.dialog.height

      return height ? `${height}px` : ''
    },
    persistent(): boolean {
      return !!this.state.dialog.page?.props?.persistent
    },
  },
  watch: {
    isOpen: 'emitClosedAndToggleLockScroll',
    lockScroll: 'toggleLockScroll',
  },
  methods: {
    emitClosedAndToggleLockScroll(): void {
      if (!this.isOpen) {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSED, null)
      }
      this.toggleLockScroll()
    },
    toggleLockScroll(): void {
      if (this.lockScroll && this.isOpen) {
        disableDocumentScroll()
      } else {
        enableDocumentScroll()
      }
    },
  },
})
</script>
