<template>
  <CollapsibleHeader :title="$t(titleTranslationKey)" :opened="false">
    <div class="scroll--container">
      <AccountContactDetails
        v-if="computedContacts.length > 0"
        :title="accountEmcContactsTitle"
        :contacts="computedContacts"
      />
      <SiteZonesContactsDetail
        v-if="showSitesZoneContacts"
        :zone-contacts="formatedZoneEmc"
        :title="siteZoneTitleTranslationKey"
      />
      <RegionContactDetails class="pt-2" />
    </div>
  </CollapsibleHeader>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import AccountContactDetails from '../components/AccountContactDetails.vue'
import SiteZonesContactsDetail from '../components/SiteZonesContactsDetail.vue'
import RegionContactDetails from '../components/RegionEmergencyContactDetails.vue'
import {
  ContactApiArrayType,
  ContactType,
  RegionContactType,
  SiteZoneEmcContactResponseType,
  ZONE,
} from '@/tt-cta-contacts/types'
import { formatContactObject } from '@/tt-cta-contacts/utils'
import {
  createCtaContactsManager,
  CtaContactsManager,
} from '@/tt-cta-contacts/CtaContacts'
import { FormatedZoneContactType } from '@/tt-entity-design/src/components/system-exception-tickets/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'EmergencyContactsList',
  components: {
    AccountContactDetails,
    SiteZonesContactsDetail,
    RegionContactDetails,
  },
  inject: ['getItemHook'],
  data: () => ({
    titleTranslationKey:
      'tt-entity-design.system-exception-tickets.emergency-contacts.title',
    siteZoneTitleTranslationKey: 'emergency-contacts.zone-contacts',
    rawZoneEmc: {} as SiteZoneEmcContactResponseType,
    loading: true,
    formatedZoneEmc: [] as FormatedZoneContactType[],
  }),
  computed: {
    accountType(): string {
      return this.getItemHook().getRawValue('accountRelation.type')
    },
    accountEmcContactsTitle(): string {
      return this.accountType === ZONE
        ? 'emergency-contacts.zone-contacts'
        : 'emergency-contacts.site-contacts'
    },
    emergencyContactAssignments(): ContactApiArrayType {
      return this.getItemHook().getRawValue(
        'accountRelation.emergencyContactAssignments',
      )
    },
    computedContacts(): Array<ContactType | RegionContactType> {
      const contactArray: Array<ContactType | RegionContactType> = []
      if (
        this.emergencyContactAssignments &&
        this.emergencyContactAssignments.length > 0
      ) {
        this.emergencyContactAssignments.map((item) => {
          const formattedItem = formatContactObject(item.contact)
          contactArray.push(formattedItem)
        })
      }

      return contactArray
    },
    showSitesZoneContacts(): boolean {
      return this.accountType !== ZONE
    },
    clientId(): string {
      return !this.showSitesZoneContacts
        ? ''
        : this.getItemHook().getRawValue('accountRelation.id')
    },
    ctaContactsManager(): CtaContactsManager {
      return createCtaContactsManager(this.$auth.getApi())
    },
  },
  watch: {
    async clientId(newValue) {
      if (newValue && newValue !== '') {
        this.rawZoneEmc = await this.fetchZoneEmergencyContacts()
        this.loading = false
      }
    },
    rawZoneEmc(newValue: SiteZoneEmcContactResponseType) {
      if (newValue.zones.length > 0) {
        newValue.zones.map((item) => {
          const zoneName = item.name
          const formattedArray = [] as Array<RegionContactType | ContactType>
          item.emergencyContactAssignments.map((item) => {
            formattedArray.push(formatContactObject(item.contact))
          })
          this.formatedZoneEmc.push({
            zoneName: zoneName,
            contacts: formattedArray,
          })
        })
      }
    },
  },
  async created() {
    if (this.clientId && this.formatedZoneEmc.length === 0) {
      this.rawZoneEmc = await this.fetchZoneEmergencyContacts()
      this.loading = false
    }
  },
  methods: {
    async fetchZoneEmergencyContacts() {
      return this.ctaContactsManager.fetchSiteZonesEmergencyContact(
        this.clientId,
      )
    },
  },
})
</script>
<style scoped>
.scroll--container {
  max-height: 50%;
  overflow-y: auto;
  padding-right: 10px;
}
</style>
