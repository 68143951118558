<template>
  <ShiftsDateTime />
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ShiftRequiredAttr',
  provide: {
    relationPrefix: 'shift',
  },
})
</script>
