<template>
  <TChipValue
    class="shift--attendance-status"
    :text="localizedStatus"
    :chip-class="chipClass"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getAttendanceStatusClass } from './utils'
import TChipValue from '@/tt-ui/components/TChipValue.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AttendanceStatusAttr',
  components: { TChipValue },
  inject: ['getItemHook'],
  computed: {
    localizedStatus(): string {
      return this.getItemHook().get('attendanceStatus')
    },
    attendanceStatus(): string {
      return this.getItemHook().getRawValue('attendanceStatus')
    },
    chipClass(): string {
      return getAttendanceStatusClass(this.attendanceStatus)
    },
  },
})
</script>
<style scoped>
.shift--attendance-status {
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  line-height: 1.5;
  padding: 0 6px;
}
</style>
