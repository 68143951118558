<template>
  <span :class="color" class="color-pill" v-text="localizedStatus" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { COLOR_MAP } from './constants'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'LoneWorkerCheckInStatus',
  inject: ['relationPrefix', 'getItemHook'],
  computed: {
    localizedStatus(): string {
      return this.getItemHook().get('status')
    },
    color(): string {
      return COLOR_MAP[this.rawValue]
    },
    rawValue(): string {
      return this.getItemHook().getRawValue('status')
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
})
</script>
