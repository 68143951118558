<template>
  <div>
    <v-checkbox
      v-model="checkbox"
      :label="$t(translationKey('save-location'))"
    ></v-checkbox>
    <div v-if="checkbox">
      <span
        class="subtitle-2 pb-2"
        v-text="$t(translationKey('form-fields.new-client'))"
      />

      <v-row no-gutters>
        <v-col class="mr-2">
          <v-text-field
            v-model="firstName"
            outlined
            dense
            :rules="requiredField"
            :error="!firstName"
            :label="$t(translationKey('form-fields.first-name'))"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="lastName"
            outlined
            dense
            :rules="requiredField"
            :error="!lastName"
            :label="$t(translationKey('form-fields.last-name'))"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="mr-2">
          <v-text-field
            v-model="email"
            outlined
            dense
            :error="!email"
            :rules="emailRules"
            :label="$t(translationKey('form-fields.email'))"
          ></v-text-field
        ></v-col>
        <v-col>
          <v-text-field
            v-model="primaryPhone"
            outlined
            dense
            :rules="requiredField"
            :error="!primaryPhone"
            :label="$t(translationKey('form-fields.primary-phone'))"
          ></v-text-field
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { FormHookProvider } from '@/tt-widget-components'
import { ErrorObject } from '@tracktik/tt-json-schema-form'
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormNewClientInformationsFields',
  inject: ['formHook', 'dispatchFormService'],
  data() {
    return {
      firstName: '',
      lastName: '',
      requiredField: [(v) => !!v || this.$t('common.required_field')],
      email: '',
      emailRules: [
        (v) => !!v || this.$t('common.required_field'),
        (v) =>
          /.+@.+/.test(v) ||
          this.$t('tt-entity-design.dispatch-tasks.email-valid'),
      ],
      primaryPhone: '',
    }
  },
  computed: {
    formValue(): object {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        primaryPhone: this.primaryPhone,
      }
    },
    error(): ErrorObject[] {
      return [
        {
          dataPath: `.location`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    isSavedLocation(): boolean {
      return this.dispatchFormService.getIsSavedLocation()
    },
    checkbox: {
      get(): boolean {
        return this.isSavedLocation || false
      },
      set(value: boolean) {
        this.dispatchFormService.setIsSavedLocation(value)
      },
    },
  },
  watch: {
    formValue: {
      immediate: true,
      handler(values) {
        for (const [key, value] of Object.entries(values)) {
          if (!value) {
            this.setError(key)
          } else {
            this.removeError(key)
          }
        }
        this.$emit('input', values)
      },
    },
    isSavedLocation: {
      immediate: true,
      handler(isSavedLocation) {
        if (isSavedLocation) {
          for (const [key, value] of Object.entries(this.formValue)) {
            if (!value) {
              this.setError(key)
            } else {
              this.removeError(key)
            }
          }
        } else {
          for (const key of Object.keys(this.formValue)) {
            this.removeError(key)
          }
        }
      },
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
    setError(key: string) {
      this.formHook().setCustomError(`location.${key}`, this.error)
    },
    removeError(key: string) {
      this.formHook().setCustomError(`location.${key}`, null)
    },
  },
})
</script>
