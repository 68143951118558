import { AppContext } from '@/tt-app-context'
import {
  installWidgetEntityFlowComponents,
  registerEntityIntents,
} from '@/tt-widget-entity-flow'
import { importEntityDesigns } from '@/tt-entity-design/src'
import { installDocumentService } from '@/tt-entity-documents'
import Vue from 'vue'
import { modularManager } from '@/tt-app-modular'
import {
  NotificationService,
  plugin as NotificationPlugin,
} from '@tracktik/tt-notifications'
import { createPushService } from '@/tt-firebase'
import UserSection from '@/tt-app-extensions/user-section'
import { MenuLogo } from '@/tt-app-extensions/extensions'
import FullScreen from '@/tt-app-extensions/full-screen'
import Workspace from '@/tt-app-extensions/workspace'
import DarkLight from '@/tt-app-extensions/dark-light'
import { InstallOptions } from '@/types'

export default {
  /**
   * Install function with options
   * @param appContext
   * @param options
   */
  install(appContext: AppContext, options: InstallOptions) {
    if (options.entityIntents) {
      registerEntityIntents(appContext)
    }
    if (options.defaultExtensions) {
      installDefaultExtensions()
    }

    if (options.entityDesigns) {
      importEntityDesigns(Vue)
    }

    if (options.pushService) {
      initPushService(appContext)
    }

    installWidgetEntityFlowComponents()
    installDocumentService(appContext)
  },
}

function installDefaultExtensions() {
  modularManager.registerExtension(UserSection)
  modularManager.registerExtension(MenuLogo)
  modularManager.registerExtension(FullScreen)
  modularManager.registerExtension(DarkLight)
  modularManager.registerExtension(Workspace)
}

const initPushService = ({ authModule, eventManager }: AppContext) => {
  Vue.use(NotificationPlugin as any)
  Vue.prototype.$pushService = createPushService(
    'insight-studio',
    authModule,
    eventManager,
  )
}

declare module 'vue/types/vue' {
  interface Vue {
    $pushService: NotificationService
  }
}
