<template>
  <div>
    <json-field
      v-for="propertyName in properties"
      :key="propertyName"
      :name="propertyName"
    >
      <template #label>
        <CompleteReportLabel>
          {{ fieldLabel(propertyName) }}
        </CompleteReportLabel>
      </template>
    </json-field>
  </div>
</template>

<script lang="ts">
import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { getFieldAbsoluteName } from '@tracktik/tt-json-schema-form'

import Vue, { VueConstructor } from 'vue'
import CompleteReportLabel from './CompleteReportLabel.vue'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & NamespaceProvider>
).extend({
  name: 'CompleteReportJsonObject',
  components: { CompleteReportLabel },
  provide(): { namespace: string } {
    return {
      namespace: this.absoluteName,
    }
  },
  inject: ['namespace', 'formHook'],
  props: {
    name: { type: String, required: true },
  },
  computed: {
    properties(): string[] {
      return this.formHook().getProperties(this.absoluteName)
    },
    absoluteName(): string {
      const { name, namespace } = this

      return getFieldAbsoluteName({ name, namespace })
    },
  },
  methods: {
    fieldLabel(propertyName: string): string {
      return this.formHook().getFieldLabel(
        `${this.absoluteName}.${propertyName}`,
      )
    },
  },
})
</script>
