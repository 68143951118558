<template>
  <div class="report-template-fields-translation">
    <template v-if="loading">
      <div v-for="index in 5" :key="index">
        <v-divider v-show="index > 1" />
        <div class="d-flex">
          <v-skeleton-loader
            class="flex-grow-1 mt-2"
            height="40"
            tile
            type="card-heading"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="field in fields"
        :key="field.id"
        class="report-template-field"
      >
        <v-text-field
          v-model="field.label"
          outlined
          :label="field.placeholder"
          @change="emitCurrentValue"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { CollectionQuery } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '../../types'
import { FormHookProvider } from '@/tt-widget-components/types'
import { ModelTranslation } from '../model-translations/types'

type ReportTemplateField = {
  id: number
  label: string
  placeholder: string
}
type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplateFieldsTranslation',
  inject: ['formHook'],
  props: {
    /**
     * Entity ID
     */
    entityId: { type: Number, required: true },
    /**
     * Language selected
     */
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: [] as ReportTemplateField[],
      loading: false,
      /**
       * Cancels the request that is currently fetching the selected items
       */
      cancelFetch: null as (() => void) | null,
    }
  },
  computed: {
    filterAttribute(): string {
      return 'reportTemplate'
    },
    resource(): string {
      return Resources.REPORT_TEMPLATE_FIELDS
    },
    translationResource(): string {
      return Resources.MODEL_TRANSLATIONS
    },
  },
  watch: {
    entityId: {
      immediate: true,
      handler() {
        this.fetchFields()
      },
    },
  },
  methods: {
    emitCurrentValue(): void {
      this.emitInput(this.fields)
    },
    emitInput(value: ReportTemplateField[]): void {
      /**
       * @event input
       * @type {ReportTemplateField[]}
       */
      this.$emit('input', value)
    },
    async fetchFields(): Promise<void> {
      this.loading = true
      this.cancelFetch?.()
      this.fields = []

      const cleanAll = () => {
        this.loading = false
        this.cancelFetch = null
      }

      const query: CollectionQuery = {
        resource: this.resource,
        filters: [
          {
            attribute: this.filterAttribute,
            operator: FilterOperatorType.EQUAL,
            value: this.entityId,
          },
        ],
        sort: { attribute: 'displayOrder', direction: 'DESC' },
      }

      const request =
        this.$appContext.widgetServices.resourceDataManager.cancelableGetCollection(
          query,
        )

      this.cancelFetch = () => request.cancel('Canceled by component')

      await request
        .run()
        .then((response) => {
          const { items } = response
          this.fields = items.map((item) => ({
            id: item.id,
            label: '',
            placeholder: item.label,
          }))
        })
        .catch((error) => this.$crash.captureException(error))
        .finally(cleanAll)

      await this.fetchTranslations()
    },
    async fetchTranslations() {
      if (!this.language) {
        return
      }
      this.loading = true
      for (const field of this.fields) {
        try {
          const response = await this.$appContext.authModule
            .getApi()
            .getAll(this.translationResource, {
              filters: [
                {
                  attribute: 'modelClass',
                  operator: FilterOperatorType.EQUAL,
                  value: 'ReportTag',
                },
                {
                  attribute: 'modelId',
                  operator: FilterOperatorType.EQUAL,
                  value: field.id,
                },
                {
                  attribute: 'language',
                  operator: FilterOperatorType.EQUAL,
                  value: this.language,
                },
              ],
            })
          const label = response.items.find(
            (item: ModelTranslation) => item.fieldName === 'description',
          ) as ModelTranslation
          field.label = label.translation || ''
        } catch (err) {
          this.$crash.captureException(err)
        } finally {
          this.emitCurrentValue()
        }
      }
      this.loading = false
    },
  },
})
</script>
