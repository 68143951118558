var l = { VITE_HERE_MAP_API_KEY: "N_dhLfSwWLUbJ20VyqqeqDOdAcs5WtFCZrH7gzPQud0" };
const T = {
  log: (...s) => {
    (l.NODE_ENV === "development" || l.NODE_ENV === "test") && console.log("[DEV]", ...s);
  },
  warn: (...s) => {
    (l.NODE_ENV === "development" || l.NODE_ENV === "test") && console.warn("[DEV]", ...s);
  }
};
var E = /* @__PURE__ */ ((s) => (s.TIMEOUT = "TIMEOUT", s))(E || {});
const _ = (s) => {
  var w;
  const a = (...e) => s.development && T.log("[tt-browser-channel]", ...e), o = (w = s.broadcastChannel) != null ? w : new BroadcastChannel(s.channelName), c = /* @__PURE__ */ new Map(), h = (e, t) => new Promise((r, d) => {
    const n = Math.random(), g = setTimeout(() => {
      a(
        "Message timeout! Most probably because there is no other tab listening to the same channel."
      ), c.delete(n), d(new Error(E.TIMEOUT));
    }, 1e3), v = () => clearTimeout(g);
    c.set(n, () => (a("Message delivered!"), c.delete(n), v(), r(void 0)));
    const i = {
      messageType: "new-message",
      messageID: n,
      // @ts-ignore
      payload: { name: e, data: t }
    };
    a("Sending message...", i), o.postMessage(i);
  }), M = (e) => {
    const t = {
      messageType: "acknowledgement",
      messageID: e
    };
    o.postMessage(t);
  }, N = (e) => Object.keys(s.onMessageReceived || {}).includes(e), m = (e) => {
    var d;
    a("New message received...", e.data);
    const { messageID: t, messageType: r } = e.data;
    if (r === "acknowledgement") {
      const n = c.get(t);
      n == null || n();
    } else if (r === "new-message") {
      M(t);
      const n = (d = e.data.payload) == null ? void 0 : d.name;
      if (!N(n)) {
        console.warn(`No handler found for event named: ${n}`);
        return;
      }
      const g = s.onMessageReceived[n];
      g(e.data.payload.data);
    } else
      console.warn("Unknown message:", e.data);
  };
  o.onmessage = m;
  const u = () => {
    a("Destroying browser channel..."), c.clear(), o.removeEventListener("message", m), o.close();
  };
  return a("Browser channel created!"), {
    send: h,
    destroy: u
  };
};
export {
  E as BrowserChannelError,
  _ as createBrowserChannel
};
