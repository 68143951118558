import { StatusValue } from '@/tt-entity-design/src/components/lone-worker-check-ins/types'

export const COLOR_MAP: Record<StatusValue, string> = {
  CANCELLED:
    'font-weight-bold text--darken-2 red--text red lighten-5 text-uppercase',
  LATE: 'font-weight-bold text--darken-2 red--text red lighten-5 text-uppercase',
  OK: 'font-weight-bold text--darken-2 green--text green lighten-5 text-uppercase',
  OPEN: 'font-weight-bold text--darken-2 blue--text blue lighten-5 text-uppercase',
  REQUESTED_CHECK_IN:
    'font-weight-bold text--darken-2 orange--text orange lighten-5 text-uppercase',
}

export const DEFAULT_COLOR =
  'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase'
