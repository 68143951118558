import Vue from 'vue'

import { MapWidgetModel } from '@/tt-widget-components/types'
import { isEmpty } from '@/helpers/isEmpty'

export interface CurrentMapInfo {
  lat: number
  lng: number
  zoom: number
}

export interface GeoEntityModel {
  id: number
  lat: number
  lng: number

  [k: string]: any
}

export interface MarkerModel {
  id: number
  point: {
    lat: number
    lng: number
  }
  meta: {
    icon: string
  }
  data: {
    [k: string]: any
  }
}

export interface BaseMapOptions {
  onClickHandler: (event: Event, item: MarkerModel | any) => void
  onClosePreview: () => void
}

export enum MarkerType {
  MARKER = 'MARKER',
  CLUSTER = 'CLUSTER',
}

export enum MapTypes {
  NORMAL = 'NORMAL',
  NIGHT = 'NIGHT',
  SATELLITE = 'SATELLITE',
  SATELLITE_NIGHT = 'SATELLITE_NIGHT',
  TRAFFIC = 'TRAFFIC',
}

export default abstract class BaseMapManager {
  public model: MapWidgetModel
  public markers: MarkerModel[] = []
  public ready = false
  public currentMapInfo: CurrentMapInfo = Vue.observable({
    lat: null,
    lng: null,
    zoom: null,
  })
  protected markerMode: MarkerType = MarkerType.CLUSTER
  mapType: MapTypes
  onClickHandler: (event: Event, item: MarkerModel | any) => void
  onClosePreview: () => void

  abstract init(mapElement: Element, mapType: MapTypes)
  abstract destroy()
  abstract apply()
  abstract centerMap()
  abstract goToDefaultPosition()
  abstract positionMap()
  abstract beforeSetItems()
  abstract afterSetItems()
  abstract onSetClusterMode()
  abstract onSetMarkerMode()
  abstract onAfterSetMapType()
  abstract setZoom(zoom: number)

  /**
   * Set the mode
   * @param model
   */
  constructor(
    model: MapWidgetModel,
    { onClickHandler, onClosePreview }: BaseMapOptions,
  ) {
    this.model = model
    this.onClosePreview = onClosePreview
    this.onClickHandler = onClickHandler
  }

  updateModel(model: MapWidgetModel) {
    this.model = model
  }

  onClick(event: any, item: MarkerModel | any) {
    this.onClickHandler(event, item)
  }

  setMapType(mapType: MapTypes) {
    this.mapType = mapType
    this.onAfterSetMapType()
  }

  private parseNumber(coordinate: string | number) {
    return typeof coordinate === 'string'
      ? Number.parseFloat(coordinate)
      : coordinate
  }

  get isCluster() {
    return this.markerType === MarkerType.CLUSTER
  }

  /**
   * Used in a v-model
   * @param val
   */
  set isCluster(val: boolean) {
    this.markerType = val ? MarkerType.CLUSTER : MarkerType.MARKER
  }

  get markerType() {
    return this.markerMode
  }

  set markerType(type: MarkerType) {
    if (this.markerMode === type || !this.ready) {
      return
    }
    this.markerMode = type
    if (type === MarkerType.CLUSTER) {
      this.onSetClusterMode()
    } else {
      this.onSetMarkerMode()
    }
  }

  /**
   * Set items
   * @param items
   */
  setItems(items: GeoEntityModel[] = []) {
    this.beforeSetItems()

    if (!items.length) {
      this.markers = []
      this.afterSetItems()

      return
    }

    this.markers = items
      .filter((item: GeoEntityModel) => {
        return (
          !(isEmpty(item.lat) && isEmpty(item.lng)) &&
          !(item.lat == 0 && item.lng == 0)
        )
      })
      .map((item: GeoEntityModel): MarkerModel => {
        return {
          data: { ...item },
          point: {
            lat: this.parseNumber(item.lat),
            lng: this.parseNumber(item.lng),
          },
          id: item.id,
          meta: {
            // @ts-ignore -- Property 'icon' does not exist on type 'MapOptions'
            icon: 'account',
          },
        }
      })

    this.afterSetItems()
  }
}
