<template>
  <div class="resizebox--container" :style="containerStyle">
    <div class="resizebox--content" :style="contentStyle">
      <slot v-if="!growLeft" />

      <div
        class="resizebox--separator__outer"
        :style="outerStyle"
        @mousedown="onMouseDown"
        @touchstart="onTouchDown"
      >
        <div class="resizebox--separator__inner" />
      </div>

      <slot name="side-content" />

      <slot v-if="growLeft" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

const PERCENTAGE_DIVIDER = 7
const PIXEL_DIVIDER = 1
const HOVERABLE_WIDTH = 30 // px of invisible separator that can be hovered

export default Vue.extend({
  name: 'ResizeBox',
  props: {
    usePixel: {
      default: false,
      type: Boolean,
    },
    growLeft: {
      default: false,
      type: Boolean,
    },
    maxWidth: {
      default: 60,
      type: Number,
    },
    minWidth: {
      default: 20,
      type: Number,
    },
    startWidth: {
      default: 25,
      type: Number,
    },
  },
  data() {
    return {
      width: 0,
      mousedown: false,
      clientX: 0,
      deltaX: 0,
    }
  },
  computed: {
    containerStyle(): Record<string, any> {
      return {
        width: this.cssWidth,
        minWidth: this.cssWidth,
        maxWidth: this.cssWidth,
      }
    },
    outerStyle(): Record<string, any> {
      const middle = `-${HOVERABLE_WIDTH / 2}px`
      const position = this.growLeft ? { left: middle } : { right: middle }

      return {
        width: `${HOVERABLE_WIDTH}px`,
        ...position,
      }
    },
    contentStyle(): Record<string, any> {
      return this.growLeft ? {} : { paddingRight: `${HOVERABLE_WIDTH / 2}px` }
    },
    calcWidth(): number {
      const width = Math.round(this.width - this.deltaX)

      return Math.max(this.minWidth, Math.min(width, this.maxWidth))
    },
    cssWidth(): string {
      return `${this.calcWidth}${this.usePixel ? 'px' : '%'}`
    },
    divider(): number {
      return this.usePixel ? PIXEL_DIVIDER : PERCENTAGE_DIVIDER
    },
    direction(): number {
      return this.growLeft ? -1 : 1
    },
  },
  mounted() {
    this.startWidth === null
      ? (this.width = this.minWidth)
      : (this.width = this.startWidth)

    document.addEventListener('mouseup', this.resizeCancel)
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('touchmove', this.onTouchMove)
    document.addEventListener('touchcancel', this.resizeCancel)
    document.addEventListener('touchend', this.resizeCancel)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.resizeCancel)
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('touchmove', this.onTouchMove)
    document.removeEventListener('touchcancel', this.resizeCancel)
    document.removeEventListener('touchend', this.resizeCancel)
  },
  methods: {
    onMouseDown(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()
      this.mousedown = true
      this.clientX = event.clientX
    },
    onTouchDown(event: TouchEvent) {
      event.stopPropagation()
      event.preventDefault()
      this.mousedown = true
      this.clientX = event.touches[0].clientX
    },
    resizeCancel() {
      this.mousedown = false
      this.applyWidthChanges()
    },
    applyWidthChanges() {
      this.width = this.calcWidth
      this.clientX = 0
      this.deltaX = 0
    },
    resizeMove(xPosition: number) {
      if (this.mousedown) {
        const delta = this.clientX - xPosition
        this.deltaX += (delta * this.direction) / this.divider
        this.clientX = xPosition
        this.$emit('size-change', this.calcWidth)
      }
    },
    onMouseMove(event: MouseEvent) {
      this.resizeMove(event.clientX)
    },
    onTouchMove(event: TouchEvent) {
      this.resizeMove(event.touches[0].clientX)
    },
  },
})
</script>

<style scoped>
.resizebox--container {
  display: flex;
  height: 100%;
}
.resizebox--content {
  position: relative;
  width: 100%;
  height: 100%;
}

.resizebox--separator__outer {
  height: 100%;

  position: absolute;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: ew-resize;
  flex-shrink: 0;
}

.resizebox--separator__inner {
  width: 5px;
  height: 100%;
  border-radius: 1em;
}

.resizebox--separator__outer:hover > .resizebox--separator__inner {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
