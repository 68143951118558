<template>
  <v-file-input
    :value="file"
    :accept="accept"
    truncate-length="10"
    prepend-icon=""
    prepend-inner-icon="mdi-file-image-outline"
    counter
    show-size
    small-chips
    outlined
    dense
    v-bind="$attrs"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <template #label>
      <slot name="label" />
    </template>
  </v-file-input>
</template>

<script lang="ts">
import Vue from 'vue'

const accept = `image/png, image/jpeg`

export default Vue.extend({
  name: 'ImageInput',
  props: {
    file: {
      type: File,
    },
  },
  data: () => ({
    accept,
  }),
  computed: {
    rules() {
      return [
        (value) =>
          !value ||
          value.size < 20_000_000 ||
          'Image size should be less than 20 MB!',
      ]
    },
  },
})
</script>

<style scoped>
.tt-img-resize {
  display: block;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
}
</style>
