<template>
  <TChip :color="color">
    <v-icon :color="color" v-text="`mdi-circle-medium`" />
    {{ statusText }}
  </TChip>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { getStatusColor } from './utils'
import { Status } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsStatusAttr',
  inject: ['getItemHook'],
  computed: {
    statusKey(): Status {
      return this.getItemHook().getRawValue('status')
    },
    statusText(): string {
      return this.getItemHook().get('status')
    },
    color(): string {
      return getStatusColor(this.statusKey)
    },
  },
  created() {
    this.getItemHook().addAttribute('status')
  },
})
</script>
