<template>
  <div>
    <v-virtual-scroll
      v-if="displayedRegions.length"
      v-slot="{ item }"
      :items="displayedRegions"
      bench="5"
      height="250"
      item-height="45"
    >
      <v-list-item @click="toggle(item)">
        <v-list-item-action>
          <v-simple-checkbox
            :value="isSelected(item)"
            color="ttPrimary"
            @click="toggle(item)"
          />
        </v-list-item-action>

        <v-list-item-content>
          <RegionListItem :region="item" />
        </v-list-item-content>
      </v-list-item>
    </v-virtual-scroll>

    <TNoData v-else />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import RegionListItem from './RegionListItem.vue'
import { UserRegion } from '@/tt-widget-components/components/treeview/utils'
import { filterRegions } from './utils'
import TNoData from '@/tt-ui/components/TNoData.vue'
import { updateDOM } from '@/helpers/dom/updateDOM'

export default Vue.extend({
  name: 'RegionListMultipleSelector',
  components: { RegionListItem, TNoData },
  props: {
    userRegions: {
      type: Array as PropType<UserRegion[]>,
      required: true,
    },
    search: { type: String, default: '' },
    /**
     * Selected region IDs (v-model binding)
     */
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showSelected: { type: Boolean, default: false },
  },
  computed: {
    displayedRegions(): UserRegion[] {
      return filterRegions(
        this.userRegions,
        this.showSelected,
        this.value,
        this.search,
      )
    },
  },
  methods: {
    async toggle(region: UserRegion) {
      const selectedId = region.id
      if (!selectedId) return

      const newValue = this.value.includes(selectedId)
        ? this.value.filter((id) => id !== selectedId)
        : [...this.value, selectedId]

      await updateDOM()

      this.$emit('input', newValue)
    },
    isSelected(region: UserRegion) {
      const value = this.value ?? []

      return value.includes(region.id)
    },
  },
})
</script>
