import { Component } from 'vue'
import {
  Attribute,
  FieldTypes,
  RelationTypes,
} from '@/tt-widget-factory/services/resource-meta/types'
import { Resources } from '../tt-entity-design/src/types'
import { CustomFilterMapType } from '@/tt-entity-filter/types'
import CustomFilterRegionRelation from '@/tt-widget-components/components/regions/CustomFilterRegionRelation.vue'
import CustomCtaTypeFilter from '@/tt-entity-design/src/components/system-exception-tickets/cta-types/CustomCtaTypeFilter.vue'

/**
 * Filters by name
 */
const filterResourceMap: {
  [resource: string]: Component
} = {
  [Resources.REGIONS]: CustomFilterRegionRelation,
}

/**
 * Custom filter for CTA type
 */
const customFilterMap: CustomFilterMapType = {
  [Resources.SYSTEM_EXCEPTION_TICKETS]: {
    attributeName: 'type',
    is: {
      component: CustomCtaTypeFilter,
    },
  },
}
/**
 * Filters by type
 */
const filterTypeMap: {
  [type in FieldTypes]?: string
} = {
  // Specials
  [FieldTypes.Enum]: 'FilterEnum',
  [FieldTypes.Relation]: 'FilterRelation',
  // Temporal Fields
  [FieldTypes.Date]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.DateTime]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.TimeStampNumber]: 'FilterDateTimeAttributeAdapter',
  [FieldTypes.TimeStampDate]: 'FilterDateTimeAttributeAdapter',
  // Strings
  [FieldTypes.Label]: 'FilterString',
  [FieldTypes.PhoneNumber]: 'FilterString',
  [FieldTypes.Email]: 'FilterString',
  [FieldTypes.TextArea]: 'FilterString',
  [FieldTypes.CSV]: 'FilterString',
  // Numbers
  [FieldTypes.Integer]: 'FilterNumber',
  [FieldTypes.Number]: 'FilterNumber',
  [FieldTypes.CurrencyRate]: 'FilterNumber',
  [FieldTypes.Currency]: 'FilterNumber',
  [FieldTypes.Decimal]: 'FilterNumber',
  // Boolean
  [FieldTypes.Boolean]: 'FilterBoolean',
}

/**
 * Get the attribute filter component name by type
 * @param type
 */
export function getAttributeFilterComponentByType(
  type?: string,
): string | null {
  if (!type) {
    type = FieldTypes.Label
  }

  return filterTypeMap[type] ?? null
}

/**
 * Get the attribute filter component name
 * @param attribute
 */
export function getAttributeFilterComponent(
  attribute: Attribute,
): string | Component | null {
  const filterConfig = customFilterMap[attribute.resource]
  if (filterConfig && attribute.name === filterConfig.attributeName) {
    return filterConfig.is.component
  }

  return (
    filterResourceMap[attribute.relation?.resource] ??
    getAttributeFilterComponentByType(attribute.type)
  )
}

/**
 * Checks if an attribute is filterable
 *
 * @param attributeMeta
 */
export function isAttributeFilterable(attributeMeta: Attribute) {
  const type = attributeMeta.type ?? FieldTypes.Label
  // Exclude has one relations as they are hidden IDS
  if (
    type === FieldTypes.Relation &&
    (attributeMeta.relation?.type === RelationTypes.HasOne ||
      attributeMeta.relation.embed)
  ) {
    return false
  }

  return Object.keys(filterTypeMap).includes(type)
}
