import { Resources } from '@/tt-entity-design/src/types'

import { Entity } from '@/tt-widget-components/base/EntityCollector/type'


export const weekDays = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
] as const
export const weekEnds = ['SATURDAY', 'SUNDAY'] as const
export enum DayOptions {
  'WEEKDAYS' = 'WEEKDAYS',
  'EVERYDAY' = 'EVERYDAY',
  'WEEKENDS' = 'WEEKENDS',
  'HOLIDAYS' = 'HOLIDAYS',
}

type extraItem = (typeof EXTRA_ITEMS)[number]

export type DayOfWeek = (typeof REAL_DAYS)[number]

export type DayData = SchedulingData & {
  dayOfWeek: DayOfWeek
  dayOfWeekStart?: DayOfWeek
  dayOfWeekEnd?: DayOfWeek
}

/**
 * @todo: make it better typed
 */
export interface SchedulingGroupBatch extends Entity {
  days: Partial<Record<extraItem, SchedulingData>>
  holidays?: Record<string, ExceptionData>
  others?: Record<string, ExceptionData>
}

export const REAL_DAYS = [...weekDays, ...weekEnds] as const

export const EXTRA_ITEMS = [
  DayOptions.WEEKDAYS,
  DayOptions.EVERYDAY,
  DayOptions.WEEKENDS,
  ...REAL_DAYS,
] as const

export type ExceptionType = {
  label: string
  id: number
  children?: ExceptionType[]
  parent?: number
}
export enum rangeTimeKeys {
  START_TIME_KEY = 'rangeStartTime',
  END_TIME_KEY = 'rangeEndTime',
  LABEL = 'dayOfWeek',
  DO_NOT_PERFORM = 'doNotPerform',
}

export type SchedulingEntity = Entity & {
  dayOfWeek?: string
  dayOfWeekStart?: string
}

export type SchedulingExceptionTypeEntity = Entity & {
  exceptionType?: number
  perform?: PerformType
  timeFrom?: string
  timeTo?: string
}

export type SchedulingGroupEntity = Entity & {
  name: string
  zonePosition?: number
  beginServiceDate?: string
  [key: string]: unknown
}

export type SchedulingEntityResource =
  | typeof Resources.MOBILE_SCHEDULES
  | typeof Resources.MOBILE_RUNSHEETS
  | typeof Resources.SITE_TASK_SCHEDULE_TIMINGS

export type SchedulingData = {
  [rangeTimeKeys.START_TIME_KEY]: string
  [rangeTimeKeys.END_TIME_KEY]: string
}

export enum PerformType {
  PERFORM_TASK = 'PERFORM_TASK',
  DONT_PERFORM_TASK = 'DONT_PERFORM_TASK',
  PERFORM_ON_EXCEPTION_DAY = 'PERFORM_ON_EXCEPTION_DAY',
}

export type ExceptionData = Partial<SchedulingData> & {
  performType?: PerformType
}

export const SchedulingExceptionType: Record<
  SchedulingEntityResource,
  Resources
> = {
  [Resources.MOBILE_SCHEDULES]: Resources.MOBILE_SCHEDULE_EXCEPTION_TYPES,
  [Resources.MOBILE_RUNSHEETS]: Resources.MOBILE_RUNSHEET_EXCEPTION_TYPES,
  [Resources.SITE_TASK_SCHEDULE_TIMINGS]:
    Resources.SITE_TASK_SCHEDULE_EXCEPTION_TYPES,
}

export const SchedulingGroupType: Record<SchedulingEntityResource, Resources> =
  {
    [Resources.MOBILE_SCHEDULES]: Resources.MOBILE_SCHEDULE_GROUPS,
    [Resources.MOBILE_RUNSHEETS]: Resources.MOBILE_RUNSHEET_GROUPS,
    [Resources.SITE_TASK_SCHEDULE_TIMINGS]: Resources.SITE_TASK_SCHEDULES,
  }

export type SchedulingEntityKeyNames = 'dayOfWeekStart' | 'groupName'

export const SchedulingEntityKeys = {
  [Resources.MOBILE_SCHEDULES]: {
    dayOfWeekStart: 'dayOfWeekStart',
    groupName: 'scheduleGroup',
  },
  [Resources.MOBILE_RUNSHEETS]: {
    dayOfWeekStart: 'dayOfWeek',
    groupName: 'runsheetGroup',
  },
  [Resources.SITE_TASK_SCHEDULE_TIMINGS]: {
    dayOfWeekStart: 'dayOfWeekStart',
    groupName: 'schedule',
  },
} satisfies Record<
  SchedulingEntityResource,
  Record<SchedulingEntityKeyNames, string>
>

export enum DaysSetupOptions {
  Customize = 'customize',
  Everyday = 'everyday',
}

export enum RunsheetStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
}
