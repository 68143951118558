import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'
import { EventManagerInterface } from '@tracktik/tt-event-manager'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { AppContextEventPayload } from '@/tt-app-context'

type ConfirmDeleteData = {
  resourceName: string
  entityId: number
  message: string
}

export type ConfirmExecuteActionsData = {
  resourceName: string
  entityId: number
  actionName: string
  message: string
  data?: any
  onAccept: (result: any) => void
}

export type ConfirmExecuteActionsDependencies = {
  eventManager: EventManagerInterface<AppContextEventPayload>
  persister: EntityPersistRunner
}

export const confirmDeleteEntity = (
  { entityId, message, resourceName }: ConfirmDeleteData,
  { eventManager, persister }: ConfirmExecuteActionsDependencies,
): void => {
  const onAccept = () => persister.deleteEntity(resourceName, entityId)

  eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
    message,
    accept: onAccept,
  })
}

export const confirmExecuteEntityAction = (
  { actionName, entityId, message, resourceName, data, onAccept }: ConfirmExecuteActionsData,
  { eventManager, persister }: ConfirmExecuteActionsDependencies,
): void => {
  eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
    message,
    accept: async () => persister.executeEntityAction(resourceName, actionName, entityId, data)
      .then(onAccept),
  })
}
