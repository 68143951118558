<template>
  <v-chip
    label
    small
    class="text--darken-2 lighten-5 px-2 text-truncate"
    :class="computedClass"
  >
    <slot />
  </v-chip>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TChip',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    computedClass(): string {
      return `${this.color} ${this.color}--text`
    },
  },
})
</script>

<style scoped>
.v-chip {
  display: block;
  font-size: 12px;

  width: fit-content;
  height: fit-content;

  font-weight: bold;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: inherit;
}
</style>
