<template>
  <ApiImageHandler :id="value" :initial-img-url="preview" @uploaded="emitNewId">
    <template #default="{ localImgUrl, file, uploadFile }">
      <v-card v-if="localImgUrl" v-bind="$attrs" class="mb-3">
        <img :src="localImgUrl" width="100%" />
      </v-card>
      <!-- @TODO: extract ApiImageHandler to a service instead of a HOC -->
      <ImageInput
        v-bind="{ ...$attrs, disabled: isReadOnly }"
        :file="file"
        v-on="imageInputListeners"
        @input="uploadDeactivated ? $emit('input', null) : uploadFile($event)"
      >
        <template #label>
          <slot name="label" />
        </template>
      </ImageInput>
    </template>
  </ApiImageHandler>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import ApiImageHandler from './ApiImageHandler.vue'
import ImageInput from './ImageInput.vue'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ImageField',
  components: { ApiImageHandler, ImageInput },
  inject: {
    formHook: { default: undefined },
  },
  props: {
    preview: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    uploadDeactivated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    //eslint-disable-next-line @typescript-eslint/ban-types
    imageInputListeners(): Record<string, Function | Function[]> {
      //Remove the input event to prevent the imageinput component emitting its value to the parent
      const { input: _, ...listeners } = this.$listeners

      return listeners
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
  },
  methods: {
    emitNewId(id) {
      this.$emit('input', id)
    },
  },
})
</script>
