<template>
  <div>
    <t-alert class="mb-4" :alert-type="hintAlertType" :text="hintText" />
    <json-field name="asset" />
    <json-field name="forDispatchable" />
    <json-field name="forSchedulable" />
    <json-field name="forSiteTask" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { AlertConfig, AlertType, AlertTypes } from '@/tt-ui/types'

export default Vue.extend({
  name: 'TaskSiteInstructionsLinkAsset',
  computed: {
    hintAlertType(): AlertConfig {
      return AlertTypes[AlertType.INFO]
    },
    hintText(): string {
      return this.$tc('tt-entity-design.task-site-instructions.link-asset.hint')
    },
  },
})
</script>
