<template>
  <div>
    <v-divider />
    <v-toolbar flat text>
      <json-valid
        v-if="isDev"
        :loading="debouncing"
        :show-text="false"
        :valid="!disabled"
        :errors="errors"
        :valid-text="$t('common.form.valid')"
        :invalid-text="$t('common.form.invalid')"
      />

      <v-spacer />
      <v-btn
        v-if="!isReportPage && !isEditForm"
        :disabled="disabled"
        raised
        color="success"
        elevation="0"
        class="ma-3"
        @click="$emit('click:next')"
      >
        <span v-text="$t('common.next.btn')" />
      </v-btn>
      <div v-else>
        <v-btn class="ma-3" color="grey" outlined @click="$emit('click:back')">
          <span v-text="$t('common.cancel.btn')" />
        </v-btn>

        <v-btn
          :disabled="disabled"
          color="success"
          class="ma-3"
          elevation="0"
          :loading="loading"
          @click="$emit('click:submit')"
        >
          <span v-text="$t('common.save.btn')" />
        </v-btn>
      </div>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { ErrorObject } from 'ajv'
import { FormHookProvider } from '@/tt-widget-components'
import { DispatchTaskFormServiceProvider } from './types'
import { TAB_KEYS } from './constants'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormButton',
  inject: ['dispatchFormService'],
  props: {
    tabKey: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debouncing: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object as PropType<Record<string, ErrorObject[]>>,
      default: () => ({}),
    },
  },
  computed: {
    isDev(): boolean {
      return process.env.NODE_ENV !== 'production'
    },
    isEditForm(): boolean {
      return this.dispatchFormService.getIsEditForm()
    },
    isReportPage(): boolean {
      return TAB_KEYS.REPORT === this.tabKey
    },
  },
})
</script>
