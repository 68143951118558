<template>
  <ProvideItemHook :key="uri" :provide="actualItemHook">
    <slot />
  </ProvideItemHook>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { EventLifeCycleManager } from '@/tt-widget-entity-flow/EventLifeCycleManager'
import {
  EntityIntentTypes,
  ResourceUpdatedInterface,
} from '@/tt-widget-entity-flow/intents'

export default Vue.extend({
  name: 'EntityItem',
  props: {
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      default: null,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    resourceName: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      eventManager: null as null | EventLifeCycleManager,
      internalItemHook: this.itemHook
        ? null
        : // @ts-ignore -- methods not seen from data
          (this.createItemHook() as EntityItemHook | null),
    }
  },
  computed: {
    uri(): string {
      return this.resourceName + '/' + this.entityId
    },
    actualItemHook(): EntityItemHook {
      return this.itemHook ?? this.internalItemHook
    },
  },
  mounted() {
    this.eventManager = new EventLifeCycleManager(this.$eventManager)

    this.eventManager.subscribeTo(
      EntityIntentTypes.RESOURCE_UPDATED,
      (payload: ResourceUpdatedInterface) => {
        if (payload.resource === this.resourceName) {
          this.actualItemHook.fetchWait()
        }
      },
    )
  },
  beforeDestroy() {
    this.eventManager.destroy()
    this.internalItemHook?.beforeDestroy()
  },
  methods: {
    createItemHook(): EntityItemHook {
      return new EntityItemHook(this.$appContext, {
        resourceName: this.resourceName,
        entityId: this.entityId,
        entity: { id: this.entityId, ...this.entity },
      })
    },
  },
})
</script>
