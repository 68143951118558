<template>
  <div class="flex-container">
    <v-autocomplete
      v-model="model"
      :no-data-text="$t(`common.no_results`)"
      :hide-no-data="loading || noItems"
      :loading="loading && 'yellow darken-2'"
      :allow-overflow="false"
      :search-input="search"
      clearable
      persistent-hint
      outlined
      open-on-clear
      dense
      solo
      flat
      loader-height="3"
      max-width="100%"
      :menu-props="{
        closeOnContentClick: true,
        closeOnClick: true,
      }"
      v-bind="{
        ...$attrs,
        errorMessages,
        placeholder,
        label,
        items: itemsWithValue,
      }"
      v-on="$listeners"
      @click:clear="clearAndFetch"
      @focus="fetchValue"
      @update:search-input="setSearchAndDebounce"
      @change="onItemSelected($event)"
    />
  </div>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { PropType } from 'vue'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import QueryManager from '@/tt-widget-components/base/QueryManager'
import { AppContext } from '@/tt-app-context'
import { CollectionQuery } from '@/tt-widget-components'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { ContextAttributeMap } from '@/tt-widget-components/base/contextAttributeMap'
import { SelectItem } from '@/tt-entity-design/src/components/alarms/types'
import {
  createViewItem,
  handleError,
} from '@/tt-entity-design/src/components/alarms/utils'

const createQueryManager = (
  appContext: AppContext,
  resource: string,
  queryOptions?: Partial<CollectionQuery>,
  modelContext?: ContextAttributeMap,
) =>
  new QueryManager({ ...queryOptions, resource }, appContext.contextManager, {
    services: appContext.widgetServices,
    contextAttributeMap: modelContext,
  })

export default BaseInput.extend({
  name: 'AlarmsFormCustomRelationField',
  components: { EntityPresetRelation },
  props: {
    /**
     * Query options to be added to the request
     */
    query: {
      type: Object as PropType<Partial<CollectionQuery>>,
      required: true,
    },
    attribute: {
      type: Array as PropType<string[]>,
      default: () => ['id'],
    },
  },
  data() {
    return {
      loading: false,
      items: [] as SelectItem[],
      itemCount: 0,
      itemSelected: {} as SelectItem,
      /**
       * Cancels the request that is currently fetching the selected items
       */
      cancelFetch: null as (() => void) | null,
      /**
       * The current search sync with the `v-autocomplete`
       */
      search: '',
    }
  },
  computed: {
    noItems(): boolean {
      return this.itemCount === 0 && this.items.length === 0
    },
    _debounceFetch(): DebouncedFunc<() => void> {
      return debounce(() => {
        this.fetchValue()
      }, 250)
    },
    /**
     * We add value to the list of items to display it
     */
    itemsWithValue(): SelectItem[] {
      if (this.value) {
        const isPartOfFetchedItems = this.items.find(
          (item) => item.value === this.value,
        )
        const items = [
          isPartOfFetchedItems
            ? createViewItem(
                isPartOfFetchedItems.text,
                isPartOfFetchedItems.value,
              )
            : createViewItem(
                `${this.value} (custom ${this.attribute[0]})`,
                this.value,
              ),
          ...this.items,
        ]

        return [...new Set(items)]
      }

      return [...new Set(this.items)]
    },
  },
  methods: {
    onItemSelected(item: string) {
      this.itemSelected = this.items.find((search) => search.value === item)
    },
    setSearchAndDebounce(search?: string) {
      const newSearch = search || ''
      if (newSearch === this.search) return
      this.search = newSearch
      this.cancelFetch?.()
      this.loading = true
      this._debounceFetch()
    },
    clearAndFetch() {
      this.search = ''
      this.fetchValue()
    },
    createCancellableRequest(query: CollectionQuery) {
      return this.$appContext.widgetServices.resourceDataManager.cancelableGetCollection(
        query,
      )
    },
    createWhereQl(search: string): string {
      return `code like '%${search}%' OR name like '%${search}%'`
    },
    async fetchValue() {
      this._debounceFetch.cancel()
      this.cancelFetch?.()

      this.loading = true

      const queryManager = createQueryManager(
        this.$appContext,
        this.query.resource,
        this.query,
      )

      if (this.search && this.itemSelected?.text !== this.search) {
        if (this.attribute.length === 1) {
          queryManager.setCustomFilters([
            {
              attribute: this.attribute[0],
              operator: FilterOperatorType.CONTAINS,
              value: this.search,
            },
          ])
        } else {
          const whereQL = this.createWhereQl(this.search)
          queryManager.setWhereQL(whereQL)
        }
      }
      const onSuccess = ({ items, itemCount }) => {
        this.items = [
          ...items.map((item) => {
            if (this.attribute.length === 1) {
              return createViewItem(
                item[this.attribute[0]],
                item[this.attribute[0]],
              )
            } else {
              return createViewItem(
                `${item[this.attribute[0]]} (${item[this.attribute[1]]})`,
                item[this.attribute[0]],
              )
            }
          }),
        ]

        if (this.search) {
          if (itemCount === 0) {
            this.items = [
              {
                // setting on custom only the first attr as
                // that is what we use as value
                text: `${this.search} (custom ${this.attribute[0]})`,
                value: this.search,
              },
            ]
          } else {
            this.items = [...this.items]
          }
        }
        this.itemCount = itemCount
      }

      const cleanAll = () => {
        this.loading = false
        this.cancelFetch = null
      }
      const request = this.createCancellableRequest(queryManager.query)

      this.cancelFetch = () => request.cancel('Canceled by component')

      await request.run().then(onSuccess).catch(handleError).finally(cleanAll)
    },
  },
  beforeDestroy() {
    this.cancelFetch?.()
    this._debounceFetch.cancel()
  },
})
</script>

<style scoped>
.v-select__selection {
  display: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
