import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'id',
    // adding to the id as we had to
    // remove escalationLevel column by product request
    component: {
      is: 'SystemExceptionTicketsEscalationsAttr',
    },
    style: {
      width: 100,
    },
  },
  'updatedOn',
  'type',
  'createdBy',
  {
    attributeName: 'relation',
    component: { is: 'SystemExceptionTicketsRelationAttr' },
  },
  {
    attributeName: 'status',
    component: {
      is: 'SystemExceptionTicketsStatusAttr',
    },
  },
  {
    attributeName: 'assignedOperator',
    component: {
      is: 'SystemExceptionTicketsOperator',
    },
  },
] as ColumnInputDefinition[]
