<template>
  <EntityCollection
    dense
    :query="query"
    :is-sorted="isSorted"
    @loading="loading = $event"
  >
    <template #default="{ itemHook }">
      <v-list-item @click="emitInput(itemHook)">
        <v-list-item-action>
          <v-simple-checkbox
            :value="isSelected(itemHook)"
            color="ttPrimary"
            @click="emitInput(itemHook)"
          />
        </v-list-item-action>
        <v-list-item-content>
          <tt-preset-view
            type="relationReference"
            class="d-inline-block text-truncate"
          />
        </v-list-item-content>
      </v-list-item>
    </template>
  </EntityCollection>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { CollectionQuery } from '@/tt-widget-components'

import { EntityItemHook } from '../EntityItemHook'
import EntityCollection from './EntityCollection.vue'

const castToString = (itemHook: EntityItemHook): string =>
  String(itemHook.entityReference.entityId || '')

export default Vue.extend({
  name: 'EntityCollectionListWrapper',
  components: { EntityCollection },
  props: {
    value: {
      type: Array as PropType<string[]>,
    },
    query: {
      type: Object as PropType<CollectionQuery>,
      required: true,
    },
    isSorted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    isSelected(itemHook: EntityItemHook) {
      const value = this.value ?? []

      return value.includes(castToString(itemHook))
    },
    emitInput(itemHook: EntityItemHook) {
      this.$emit('input', castToString(itemHook))
    },
  },
})
</script>
