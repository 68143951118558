import isEmpty from 'lodash/isEmpty'

import { EmptyValueRule } from '@tracktik/tt-json-schema-form'

import i18n from '@/plugins/i18n'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ActionFormTypes, modularManager } from '@/tt-app-modular'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import {
  DialogFormInterface,
  DialogInterface,
  LayoutWindowEvent,
} from '@/tt-app-layout'
import { FormSchemaTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { getResourceDefinitions } from '@/tt-entity-forms/EntityViewDefinitions'

import { BaseEntityIntent } from '../intents/BaseEntityIntent'
import {
  displayErrorMessages,
  getEditFormName,
  parseErrorMessages,
} from './helpers'
import { EntityEditIntentInterface, EntityIntentTypes } from './types'
import {
  getResourceFormDefinition,
  getResourceFormWidth,
  getResourceStandaloneForm,
  isResourceFormStandaloneComponent,
} from '../helper'
import { ResourceTranslator } from '../ResourceTranslator'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

type EditFormType = ActionFormTypes.EDIT | ActionFormTypes.FULL_ENTITY
const { EDIT, FULL_ENTITY } = ActionFormTypes

export class EntityEditIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.EDIT
  }

  async getDialogFormBuilder({
    buildDefinitions = (definitions) => definitions,
    entityId,
    formOptions,
    resourceName,
    title = ' ',
    itemHook: customItemHook,
    onSuccess = (updatedEntity) => {},
  }: EntityEditIntentInterface): Promise<DialogFormBuilder> {
    const currentEntityObject = await this.getEntityForEdit(
      resourceName,
      entityId,
    )
    /**
     * Make the fetched entity data available to the item hook for relation
     * filter variables resolution
     */

    const itemHook = (() => {
      if (customItemHook) {
        customItemHook.data = { ...currentEntityObject }

        return customItemHook
      }

      return new EntityItemHook(
        this.appContext,
        { resourceName, entityId, entity: currentEntityObject },
        { fetchEnabled: false, hasFetched: true, fetchActions: false },
      )
    })()

    const { eventManager, entityServices, widgetServices } = this.appContext
    const editFormName = getEditFormName(resourceName)

    const getDefaultConfig = (): Partial<Action> => {
      const schema =
        this.appContext.widgetServices.resourceMetaManager.getFormSchema(
          resourceName,
          FormSchemaTypes.EDIT,
        )

      return { schema }
    }

    const getDynamicJsonSchemaFormConfig = async (): Promise<
      Partial<Action>
    > => {
      const actions =
        await widgetServices.resourceActionManager.getEntityActions(
          resourceName,
          entityId,
          { jsonFormSchema: true, includeOperations: true },
        )

      const configSchema = actions.find(({ actionName }) => actionName === EDIT)
      if (!configSchema) {
        throw new Error(
          "Failed to build form: 'edit' schema is not in the list of available actions",
        )
      }

      // @ts-ignore -- json schema type is missing components
      const { components } = getDefaultConfig().schema ?? {}

      const schema = { components, ...configSchema.schema }

      return { ...configSchema, schema }
    }

    const hasDynamicSchema = modularManager.hasDynamicResourceJsonSchemaAction(
      resourceName,
      EDIT,
    )

    const resourceDefinitions = getResourceDefinitions(this.appContext)

    const customEditForm = getResourceFormDefinition(
      editFormName,
      resourceName,
      EDIT,
    )

    const customFullEntityForm = getResourceFormDefinition(
      editFormName,
      resourceName,
      FULL_ENTITY,
    )

    // If EDIT form is specifically register, use it
    // if not, fallback to the FULL_ENTITY form
    // If nothing is registered, will use the default layout
    const customForm = !isEmpty(customEditForm)
      ? customEditForm
      : customFullEntityForm

    const configSchema = hasDynamicSchema
      ? await getDynamicJsonSchemaFormConfig()
      : getDefaultConfig()

    const { definitions: dynamicDefinitions, ...dynamicFormOptions } =
      configSchema?.formOptions ?? {}

    const defaultDefinitions = {
      ...resourceDefinitions,
      ...customForm,
      ...dynamicDefinitions,
    }
    const definitions = buildDefinitions(defaultDefinitions, editFormName)

    const dialogWidth = getResourceFormWidth(resourceName)

    const submitFn: DialogFormInterface['submit'] = (entity) =>
      entityServices.persister.updateEntity(resourceName, entity, entityId)

    const successFn: DialogFormInterface['success'] = (_, updatedEntity) => {
      eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: i18n.t('common.operation_successful'),
      })
      onSuccess(updatedEntity)
    }

    const errorFn: DialogFormInterface['error'] = (error) => {
      const messages = parseErrorMessages({ error, resourceName })
      displayErrorMessages(messages, eventManager)
    }

    return new DialogFormBuilder(title, editFormName, {
      eventManager,
      i18n,
    })
      .setJsonSchema(configSchema.schema)
      .addToInitialModel(currentEntityObject)
      .addToFormOptions({
        emptyValues: EmptyValueRule.KEEP,
        removeAdditional: true,
      })
      .addToFormOptions({ ...dynamicFormOptions, ...formOptions })
      .addToContext({ resourceName, itemHook })
      .addToDefinitions(definitions)
      .setTranslateFunction(ResourceTranslator.getFormCallback(resourceName))
      .onSubmit(submitFn)
      .setWidth(dialogWidth)
      .onSuccess(successFn)
      .onError(errorFn)
  }

  async run(payload: EntityEditIntentInterface) {
    const editFormName = getEditFormName(payload.resourceName)

    const hasStandaloneComponentRegistered = (type: EditFormType) =>
      isResourceFormStandaloneComponent(
        editFormName,
        payload.resourceName,
        type,
      )

    const dispatchStandaloneComponent = async (type: EditFormType) => {
      const dialogForm = (await this.getDialogFormBuilder(payload)).getState()

      const dialog: DialogInterface = {
        is: getResourceStandaloneForm(editFormName, payload.resourceName, type),
        title: dialogForm.title,
        props: {
          payload: dialogForm,
          persistent: true,
        },
      }
      // Display Dialog with standalone component
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG,
        dialog,
      )
    }

    if (hasStandaloneComponentRegistered(EDIT)) {
      dispatchStandaloneComponent(EDIT)
    } else if (hasStandaloneComponentRegistered(FULL_ENTITY)) {
      dispatchStandaloneComponent(FULL_ENTITY)
    } else {
      const builder = await this.getDialogFormBuilder(payload)
      builder.displayDialog()
    }
  }
}
