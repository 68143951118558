<template>
  <div>
    <div v-for="(zone, zoneIndex) in zoneContacts" :key="zoneIndex">
      <span
        v-if="zone.contacts && zone.contacts.length > 0"
        class="contact-details--title pb-2 pt-2"
      >
        {{ `${$t(createTranslationKey(title))} (${zone.zoneName})` }}
      </span>
      <div
        v-for="(contact, index) in zone.contacts"
        :key="index"
        class="pa-4 v-card v-card--flat v-sheet theme--light"
      >
        <div v-for="(value, key) in contact" :key="key">
          <v-row no-gutters class="ma-1">
            <v-col class="attribute-row-label" cols="4">
              <span>{{
                $t(createTranslationKey(`contacts-detail-labels.${key}`))
              }}</span>
            </v-col>
            <v-col class="ml-1 attribute-row-value">
              <div>
                <span>{{ value }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider style="opacity: 0.5" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { FormatedZoneContactType } from '@/tt-entity-design/src/components/system-exception-tickets/types'
export default Vue.extend({
  name: 'SiteZonesContactsDetail',
  props: {
    zoneContacts: {
      type: Array as PropType<Array<FormatedZoneContactType>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    createTranslationKey(key: string) {
      return `tt-entity-design.system-exception-tickets.${key}`
    },
  },
})
</script>
