<template>
  <span>
    <v-tooltip top :disabled="!isTextTruncated">
      <template #activator="{ on }">
        <span ref="label" v-on="on">
          <slot />
        </span>
      </template>
      <slot />
    </v-tooltip>
  </span>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
export default (
  Vue as VueConstructor<Vue & FormHookProvider & NamespaceProvider>
).extend({
  name: 'CompleteReportLabel',
  data() {
    return {
      observer: null as ResizeObserver | null,
      width: 0,
      parentWidth: 0,
    }
  },
  computed: {
    debouncedUpdateWidths(): DebouncedFunc<() => void> {
      return debounce(this.updateWidths, 300)
    },
    isTextTruncated(): boolean {
      // The vuetify css scales the label to 75%
      const scalingFactor = 0.75,
        // Manually tested tolerance for the label to grow in pixels
        labelTolerance = 24

      return this.width * scalingFactor - labelTolerance > this.parentWidth
    },
  },
  mounted() {
    this.initObserver()
    this.debouncedUpdateWidths()
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    updateWidths(): void {
      const box = this.$refs.label as unknown as Node & { offsetWidth: number }
      this.width = box.offsetWidth || 0
      this.parentWidth = this.$el.parentElement?.offsetWidth || 0
    },
    /**
      This observer will react when the element is resized and update if we need to show the tooltip on the label or not
    **/
    initObserver(): void {
      const observer = new ResizeObserver(() => {
        this.debouncedUpdateWidths()
      })

      observer.observe(this.$el.parentElement)
      this.observer = observer
    },
  },
})
</script>
