<template>
  <div>
    <json-field name="language" />
    <template v-if="loading">
      <v-skeleton-loader type="list-item, article, article" />
    </template>
    <template v-else>
      <v-alert type="info">
        {{ reportTemplateAlertText }}
      </v-alert>
      <json-field name="title" />
      <json-field name="description" />
      <v-alert type="info">
        {{ reportFieldsAlertText }}
      </v-alert>
      <json-field
        name="reportFields"
        as="ReportTemplateFieldsTranslation"
        :entity-id="entityId"
        :language="language"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components/types'
import { Resources } from '../../types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { ModelTranslation } from '../model-translations/types'

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'ReportTemplatesAddTranslation',
  inject: ['formHook'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    entityId(): number {
      return this.itemHook.entityReference.entityId
    },
    itemHook(): EntityItemHook {
      return this.formHook().getUserContextValue('itemHook')
    },
    translationResource(): string {
      return Resources.MODEL_TRANSLATIONS
    },
    reportFieldsAlertText(): string {
      return this.$t(
        'tt-entity-design.report-templates.form.translation.report-fields-alert',
      )
    },
    reportTemplateAlertText(): string {
      return this.$t(
        'tt-entity-design.report-templates.form.translation.report-template-alert',
      )
    },
    language(): string {
      return this.formHook().getPathValue('language')
    },
  },
  watch: {
    language: {
      immediate: true,
      handler(language: string) {
        this.fetchTranslations(language)
      },
    },
  },
  methods: {
    async fetchTranslations(language: string) {
      if (!language) {
        return
      }
      this.loading = true
      try {
        const response = await this.$appContext.authModule
          .getApi()
          .getAll(this.translationResource, {
            filters: [
              {
                attribute: 'modelClass',
                operator: FilterOperatorType.EQUAL,
                value: 'ReportTag',
              },
              {
                attribute: 'modelId',
                operator: FilterOperatorType.EQUAL,
                value: this.entityId,
              },
              {
                attribute: 'language',
                operator: FilterOperatorType.EQUAL,
                value: this.language,
              },
            ],
          })
        const title = response.items.find(
          (item: ModelTranslation) => item.fieldName === 'description',
        ) as ModelTranslation
        const description = response.items.find(
          (item: ModelTranslation) => item.fieldName === 'extra',
        ) as ModelTranslation
        this.setFieldValue('title', title?.translation || '')
        this.setFieldValue('description', description?.translation || '')
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loading = false
      }
    },
    setFieldValue(field: string, value: string) {
      this.formHook().setObjectValue(field, value)
    },
  },
})
</script>
