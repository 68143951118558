<template>
  <v-chip
    x-small
    label
    class="pl-3 pr-2"
    @click.capture.prevent.stop="copyToClipboard"
  >
    <span>{{ value }}</span>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-icon
          x-small
          class="mx-0 pr-0 pl-2"
          color="primary"
          dark
          style="opacity: 0"
          v-on="on"
          v-text="`mdi-content-copy`"
        />
      </template>

      <span v-text="$t('common.copy-to-clipboard')" />
    </v-tooltip>
  </v-chip>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { LayoutWindowEvent } from '@/tt-app-layout/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'TChipIdentifier',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      const onSuccess = () =>
        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_SUCCESS,
          {
            message: this.$t('Copied to clipboard'),
          },
        )

      const onError = () =>
        this.$appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message: this.$t('Could not copy to clipboard'),
          },
        )

      if (navigator?.clipboard?.writeText)
        navigator.clipboard
          .writeText(this.value.toString())
          .then(onSuccess)
          .catch(onError)
      else onError()
    },
  },
})
</script>

<style scoped>
.v-chip {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--v-primary-base) !important;
  background-color: var(--v-muted-base) !important;
  cursor: pointer;
  transition: opacity 0.2s;
}

.v-chip:hover >>> .v-icon {
  opacity: 1 !important;
}
</style>
