<template>
  <tt-tile
    v-bind="$props"
    :class="{ 'system-exception-tickets--tile': !showElapsedTime }"
  >
    <div slot="title">
      <tt-attr
        name="type"
        class="font-weight-bold system-exception-tickets--attr"
      />
    </div>
    <div slot="line2" class="pb-5">
      <tt-attr name="createdOn" class="system-exception-tickets--attr" />
    </div>
    <div slot="line3">
      <template v-if="assignedOperator">
        <tt-attr name="assignedOperator" />
      </template>
      <div v-else>
        <span
          v-text="$t('tt-entity-design.system-exception-tickets.not-assigned')"
        />
      </div>
    </div>
    <div slot="line4">
      <SystemExceptionTicketsRelationAttr
        class="system-exception-tickets--relation"
      />
    </div>
    <div slot="actionRight">
      <SystemExceptionTicketsStatusAttr />
    </div>
    <div
      v-if="showElapsedTime"
      slot="actionRight"
      class="system-exception-tickets--line"
    >
      <div slot="line2">
        <span
          class="system-exception-tickets--line font-weight-bold text-uppercase grey--text"
          v-text="$t('tt-entity-design.system-exception-tickets.elapsed-time')"
        />
      </div>
      <div slot="line3" class="pb-1 text-end">
        <span
          class="system-exception-tickets--line text-h6 text-capitalize font-weight-bold"
          v-text="currentElapsedTime"
        />
      </div>
    </div>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import SystemExceptionTicketsRelationAttr from './SystemExceptionTicketsRelationAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'
import { getElapsedTime } from '@/helpers/formats/dates/utils'
import { getDifference } from '@/helpers/formats/dates/dates'
import { StatusEnum } from './types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'SystemExceptionTicketsTile',
  components: {
    SystemExceptionTicketsRelationAttr,
    SystemExceptionTicketsStatusAttr,
  },
  inject: ['getItemHook'],
  data() {
    return {
      intervalId: null as number | null,
      currentElapsedTime: 'N/A',
    }
  },
  computed: {
    showElapsedTime(): boolean {
      return this.getItemHook().getRawValue('status') !== StatusEnum.CLOSED
    },
    assignedOperator(): number {
      return this.getItemHook().getRawValue('assignedOperator.id')
    },
    createdOn(): string {
      return this.getItemHook().getRawValue('createdOn')
    },
  },
  mounted() {
    this.updateElapsedTime()
    this.intervalId = window.setInterval(this.updateElapsedTime, 1000)
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  created() {
    this.getItemHook().addAttribute('assignedOperator.id')
    this.getItemHook().addAttribute('createdOn')
    this.getItemHook().addAttribute('status')
  },
  methods: {
    updateElapsedTime() {
      if (!this.createdOn) {
        this.currentElapsedTime = 'N/A'

        return
      }
      const now = new Date().toISOString()
      const differenceInSeconds = getDifference(this.createdOn, now, 'seconds')
      const UPDATE_INTERVAL = 24 * 60 * 60
      if (differenceInSeconds > UPDATE_INTERVAL) {
        this.currentElapsedTime = getElapsedTime(this.createdOn)
        if (this.intervalId) {
          clearInterval(this.intervalId)
          this.intervalId = null
        }
      } else {
        this.currentElapsedTime = getElapsedTime(this.createdOn) ?? 'N/A'
      }
    },
  },
})
</script>

<style scoped>
.system-exception-tickets--line {
  line-height: 1.2;
}

.system-exception-tickets--tile ::v-deep .v-list-item__action {
  align-self: flex-start;
}

.system-exception-tickets--attr ::v-deep .entity-attribute-cell {
  cursor: text !important;
}

.system-exception-tickets--relation {
  cursor: pointer !important;
}
</style>
