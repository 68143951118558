<template>
  <tt-tile>
    <tt-attr-img slot="avatar" name="user.avatar" />
    <tt-attr slot="title" name="user.name" />
    <tt-attr slot="line2" name="createdOn" />
    <div v-if="isEditAllowed" slot="actionRight">
      <v-btn
        x-small
        dark
        absolute
        right
        color="blue"
        class="mr-4 pa-1"
        @click="editLog"
      >
        <v-icon class="logs--edit-button">edit</v-icon>
      </v-btn>
    </div>
    <tt-attr slot="actionRight" name="status">
      <WorkflowStatusesReference />
    </tt-attr>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'
import { Resource } from '@/tt-entity-design/src/schema-types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { getEditFormState } from '@/tt-entity-design/src/tools/helpers/form-state-generator'
import { updateDOM } from '@/helpers/dom'
import { EntityEditIntentInterface } from '@/tt-widget-entity-flow'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'WorkflowInstanceLogsTile',
  inject: ['getItemHook'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    entityId(): number {
      return this.getItemHook().getRawValue('id')
    },
    isEditAllowed(): boolean {
      const resourceName = Resources.WORKFLOW_INSTANCE_LOGS
      const allowedArray = (this.getItemHook().appContext?.allowEntityEdit ||
        []) as Resource[]

      return allowedArray?.includes(resourceName) || false
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.workflow-instance-logs.${key}`
    },
    async editLog() {
      this.loading = true

      await updateDOM()

      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.WORKFLOW_INSTANCE_LOGS,
        title: '',
        entityId: this.entityId,
        itemHook: this.itemHook,
      }
      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        title: this.translationKey('form.edit-form-title'),
        props: { payload },
      })

      this.loading = false
    },
  },
})
</script>
<style scoped>
.logs--edit-button {
  font-size: 15px;
}
</style>
