<template>
  <WidgetFactory :widget="widget"></WidgetFactory>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { ListWidgetModel } from '@/tt-widget-components'
import { Resources } from '@/tt-entity-design/src/types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'BreakRuleComplianceLogsList',
  inject: ['getItemHook'],
  computed: {
    widget(): ListWidgetModel | null {
      const shiftId = this.getItemHook().getRawValue('id')

      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: Resources.BREAK_RULE_COMPLIANCE_LOGS,
          filters: [
            {
              attribute: 'shift',
              operator: FilterOperatorType.EQUAL,
              value: shiftId,
            },
          ],
        },
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('id')
  },
})
</script>
