<template>
  <div class="flex--column">
    <span v-text="message" />
    <div class="flex--column mt-4">
      <span v-text="$t('You can override lone worker frequency.')" />
      <v-btn
        class="mt-2"
        :loading="isloading"
        small
        outlined
        @click="openOverrideFrenquencyForm"
      >
        <span class="mr-2" v-text="$t('common.open.btn')" />
        <v-icon small v-text="'mdi-open-in-new'" />
      </v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from '../form/types'
import {
  OverrideFrenquencyServiceType,
  overrideFrenquencyService,
} from './override-lone-worker-frequency-service'

export default (
  Vue as VueConstructor<Vue & DispatchTaskFormServiceProvider>
).extend({
  name: 'CallToActionSnackBarContent',
  props: {
    employeeId: {
      type: Number,
      required: true,
    },
    dispatchTaskId: {
      type: Number,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    overrideFrequencyService(): OverrideFrenquencyServiceType {
      return overrideFrenquencyService(this.$appContext)
    },
    isloading(): boolean {
      return this.overrideFrequencyService.getIsLoading()
    },
  },
  methods: {
    openOverrideFrenquencyForm() {
      this.overrideFrequencyService.openOverrideFrenquencyForm(
        this.employeeId,
        this.dispatchTaskId,
      )
    },
  },
})
</script>
