<template>
  <v-tooltip :top="tooltipDirection.top" :bottom="tooltipDirection.bottom">
    <template #activator="{ on }">
      <v-icon :color="iconColor" small class="pl-1" v-on="on">
        {{ icon }}
      </v-icon>
    </template>

    <span class="pr-1" v-text="$t(tooltipText)" />
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'TTooltipIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: 'ttPrimary',
    },
    openDirection: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'top',
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
  computed: {
    tooltipDirection(): { top: boolean; bottom: boolean } {
      if (this.openDirection === 'top') {
        return { top: true, bottom: false }
      } else {
        return { top: false, bottom: true }
      }
    },
  },
})
</script>
