<template>
  <v-app id="schedule-groups-view">
    <v-main>
      <MetadataLoadingGuard>
        <ProvideLayoutManager :layout-manager="manager">
          <BoundingBox
            v-slot="{ initialized, top: topMargin }"
            @change="onBoundingBoxChange"
          >
            <app-window
              v-if="initialized"
              closable
              :landing-page="landingPage"
              :on-close="() => $emit('close')"
            />
            <AppLayoutSnackBarLauncher />
            <AppLayoutSideSheet :top="topMargin" />
            <AppLayoutAlertLauncher />
          </BoundingBox>
        </ProvideLayoutManager>
      </MetadataLoadingGuard>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'

import AppLayoutAlertLauncher from '@/tt-app-layout/components/AppLayoutAlertLauncher.vue'
import AppLayoutSideSheet from '@/tt-app-layout/components/AppLayoutSideSheet.vue'
import AppLayoutSnackBarLauncher from '@/tt-app-layout/components/AppLayoutSnackBarLauncher.vue'
import { PageInterface } from '@/tt-app-layout'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'

import BoundingBox, { BoundingBoxData } from '../../components/BoundingBox.vue'
import MetadataLoadingGuard from '../../components/MetadataLoadingGuard.vue'

export default Vue.extend({
  name: 'PeriodicListView',
  components: {
    AppLayoutAlertLauncher,
    AppLayoutSideSheet,
    AppLayoutSnackBarLauncher,
    BoundingBox,
    MetadataLoadingGuard,
    ProvideLayoutManager,
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager),
      boundingBoxData: undefined as BoundingBoxData,
    }
  },
  computed: {
    landingPage(): PageInterface {
      return {
        is: 'PeriodicList',
        props: {
          clientId: this.clientId,
        },
      }
    },
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
  methods: {
    onBoundingBoxChange(data: BoundingBoxData): void {
      this.boundingBoxData = data
    },
  },
})
</script>
