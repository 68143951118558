<template>
  <div class="flex--column">
    <!-- TOP -->
    <TButtonToggle
      v-if="canShowSwitch"
      v-model="showInactive"
      :text="$t('components.region_filter_show.inactive')"
      class="ma-3"
      style="width: fit-content"
    />

    <v-divider class="mx-3" />

    <!-- Regions Tree -->
    <TreeviewSelector
      v-model="selectedIds"
      :items="treeItems"
      class="px-6 pt-3 pb-4 flex--column"
      style="overflow: auto"
    />

    <!-- BOTTOM -->
    <v-divider class="mx-3" />

    <v-toolbar flat>
      <v-spacer />

      <v-btn
        :disabled="!selectedIds.length"
        text
        color="ttPrimary"
        @click="clear"
      >
        <span v-text="$t('common.clear.btn')" />
      </v-btn>

      <v-btn class="ml-2 white--text" color="ttPrimary" @click="apply">
        <span v-text="$t('common.apply.btn')" />
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { RegionFilterPayload } from '@/tt-widget-entity-flow/intents/RegionFilterIntent'
import { isActive } from '@/tt-region-manager/utils'
import { Filter } from '@/tt-widget-components'
import { convertRegionsToTreeItems, UserRegion } from '../treeview/utils'
import { TreeviewItem } from '../treeview/types'
import TreeviewSelector from '../treeview/TreeviewSelector.vue'

const filterSelectedValues = (value: Filter['value']): string[] =>
  (value ?? '')
    .toString()
    .split(',')
    .filter((item) => item !== '')

export default Vue.extend({
  name: 'RegionFilterModal',
  components: {
    TreeviewSelector,
  },
  props: {
    payload: {
      type: Object as PropType<RegionFilterPayload>,
      required: true,
    },
  },
  data() {
    return {
      showInactive: false,
      selectedIds: [], // check if isDirty
    }
  },
  computed: {
    userRegions(): UserRegion[] {
      return this.payload.userRegions || []
    },
    /**
     * Retro-compatible feature, to only show the switch for inactive regions
     * if we have the `status` attribute in the regions from the API user sessions (LAB-1272)
     */
    canShowSwitch(): boolean {
      return this.userRegions.some((region) => !!region.status)
    },
    regionsToDisplay(): UserRegion[] {
      return this.showInactive
        ? this.userRegions
        : this.userRegions.filter(isActive)
    },
    treeItems(): TreeviewItem[] {
      return convertRegionsToTreeItems(
        this.$appContext.regionManager,
        this.regionsToDisplay,
      )
    },
  },
  watch: {
    'payload.filter.value': {
      handler() {
        this.selectedIds = filterSelectedValues(this.payload.filter.value)
      },
      immediate: true,
    },
  },
  methods: {
    clear(): void {
      this.payload.onUpdate([])
      this.payload.onClose()
    },
    apply() {
      this.payload.onUpdate(this.selectedIds)
      this.payload.onClose()
    },
  },
})
</script>
