<template>
  <EntityRelationListCustomPreviewWrapper
    attribute-name="loneWorkerCheckIns"
    :show-toolbar="false"
    :allow-actions="false"
  />
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LoneWorkerCheckInActivity',
})
</script>
