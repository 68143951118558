<template>
  <div class="fill-height">
    <MetadataLoadingGuard>
      <WidgetFactory :widget="widget" @update:hook="updateHook" />
    </MetadataLoadingGuard>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Resources } from '@/tt-entity-design/src/types'
import {
  AttributeFilter,
  CustomFilter,
  SchedulerTimelineWidgetModel,
  Sort,
} from '@/tt-widget-components'
import { WidgetName } from '@/tt-widget-components/lib/names'
import SchedulerWidgetHook from '@/tt-widget-components/widgets/Scheduler/SchedulerWidgetHook'
import MetadataLoadingGuard from '@/tt-widget-views/components/MetadataLoadingGuard.vue'
import SchedulerEvent from '@/tt-widget-components/widgets/Scheduler/SchedulerEvent.vue'

import {
  createLeaveRequestFilterByPeriod,
  isInjectedInPayroll,
} from './helpers'
import { LeaveRequestInjectedOrigin } from '../types'

Vue.component('SchedulerEvent', SchedulerEvent)

export default Vue.extend({
  name: 'LeaveRequestsTimelineView',
  components: { MetadataLoadingGuard },
  props: {
    allowActions: {
      type: Boolean,
      default: true,
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
    startedOn: {
      type: String,
      default: () => '',
    },
    endedOn: {
      type: String,
      default: () => '',
    },
    /* Product Decision to keep track of the origin of the Leave Request
     * Based on the place this view is used we are going to see different filters:
     * - Payroll -> Filter by Period (currently it has no support for presets)
     * - Dashboard -> Filter by Start Date (keep current production behavior, with support for presets)
     *
     * More info: https://tracktik.atlassian.net/browse/BOSS-4090?focusedCommentId=381434
     */
    origin: {
      type: String as PropType<LeaveRequestInjectedOrigin>,
      default: '',
    },
  },
  data() {
    return {
      widgetHook: null as SchedulerWidgetHook,
    }
  },
  computed: {
    employeeSort(): Sort {
      return {
        attribute: 'employee.name',
        direction: 'ASC',
      }
    },
    widget(): SchedulerTimelineWidgetModel {
      return {
        title: '',
        is: WidgetName.SCHEDULER_TIMELINE_WIDGET,
        allowActions: this.allowActions,
        query: {
          resource: Resources.LEAVE_REQUESTS,
          extension: ['affectedShifts'],
          sort: [this.employeeSort],
        },
        attributeMap: {
          titleAttribute: 'leaveType.name',
          startAttribute: 'startedOn',
          endAttribute: 'endedOn',
        },
        groupResourceAttributeMap: {
          resourceNameAttribute: 'employee',
          idAttribute: 'employee.id',
          titleAttribute: 'employee.name',
          descriptionAttribute: 'employee.jobTitle',
          imageUrlAttribute: 'employee.avatar',
        },
        toolbar: {
          filterOptions: {
            filters: !this.isInPayroll ? [this.statusAttributeFilter] : [],
            customFilters: this.isInPayroll ? [this.byPeriodFilter] : [],
            allowSearch: true,
          },
        },
      }
    },
    isInPayroll(): boolean {
      return isInjectedInPayroll(this.origin)
    },
    byPeriodFilter(): string | CustomFilter {
      if (this.startedOn && this.endedOn) {
        return createLeaveRequestFilterByPeriod(this.startedOn, this.endedOn)
      }

      return 'byPeriod'
    },
    statusAttributeFilter(): AttributeFilter {
      return {
        attributeName: 'status',
      }
    },
  },
  methods: {
    updateHook(hook: SchedulerWidgetHook): void {
      this.widgetHook = hook
    },
  },
})
</script>
