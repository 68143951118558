import { AppContext } from '@/tt-app-context'
import { RegionId } from '@/tt-region-manager/types'
import { OperationCenterRegionResponseType } from './types'
import { Resources } from '@/tt-entity-design/src/types'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

export const fetchRegionListFromOC = async (
  appContext: AppContext,
): Promise<RegionId[]> => {
  const regionIds: RegionId[] = []
  const authModule = appContext.authModule
  const api = authModule.getApi()

  try {
    const response = await api.getAll<OperationCenterRegionResponseType>(
      Resources.OPERATION_CENTER_REGIONS,
      {
        fields: [{ attribute: 'region.id' }],
        filters: [
          {
            attribute: 'operationCenter',
            operator: FilterOperatorType.IN,
            value: authModule.getOperationCenterIds(),
          },
        ],
      },
    )

    response.items?.forEach((item) => {
      regionIds.push(item.region.id.toString())
    })
    appContext.regionManager.setContextRegionsList(regionIds)

    return regionIds ?? []
  } catch (error) {
    console.error('Failed to fetch region list:', error)
  }
}
