<template>
  <DateTimeField
    v-model="model"
    v-bind="{ ...$attrs, ...effectiveOptions, disabled: isReadOnly }"
    v-on="$listeners"
  >
    <template #label>
      <slot name="label" />
    </template>
  </DateTimeField>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { convertPrefToMoment } from '@/helpers/formats/dates/utils'
import { FormHookProvider } from '@/tt-widget-components'

type DateTimeFieldOptions = {
  userDatetimeFormat?: string
  timeZone?: string
  locale?: string
}

/**
 * DateTimeField wrapped with provided timezone, locale, and format
 */
export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'DateTimeFieldWrapper',
  inject: {
    formHook: { default: undefined },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Object as PropType<DateTimeFieldOptions>,
      default: null,
    },
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
    effectiveOptions(): DateTimeFieldOptions {
      const userPref = this.$auth.getUserPreferences()

      const userDatetimeFormat = convertPrefToMoment(userPref.dateTimeFormat)
      const timeZone = userPref.timeZone
      const locale = this.$i18n.locale

      return {
        ...{ userDatetimeFormat, locale, timeZone },
        ...this.options,
      }
    },
  },
})
</script>
