<template>
  <tt-attr name="status">
    <EntityChip
      attributeName="status"
      class="alarm-status-name"
      :chip-class="chipClass"
    />
  </tt-attr>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { getStatusClass } from './utils'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import EntityChip from '@/tt-entity-design/src/components/EntityChip.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AlarmsStatusAttr',
  components: {
    EntityChip,
  },
  inject: ['getItemHook'],
  computed: {
    statusKey(): string {
      return this.getItemHook().getRawValue('status')
    },
    chipClass(): string {
      return getStatusClass(this.statusKey)
    },
  },
})
</script>
<style scoped>
.alarm-status-name {
  font-size: 12px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  line-height: 1.5;
  padding: 0 6px;
}
</style>
