<template>
  <div v-if="loading">
    <v-skeleton-loader type="table-cell" />
  </div>
  <div v-else>
    <v-select
      v-model="selectedFeatures"
      v-bind="{
        ...vuetifyDefaults,
      }"
      multiple
      :items="items"
      :placeholder="label"
      :label="label"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { VueWithFormHookInjection } from '@/helpers/components/types'
import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'
import { ReportTemplateFeatures } from './types'
import { getFeatureAssociationsForReportTemplate } from '@/tt-entity-design/src/components/report-templates/helpers'

export interface FeatureSelectItem {
  text: string
  value: Feature
}

export interface Feature {
  feature: string
}

export default (Vue as VueWithFormHookInjection).extend({
  name: 'ReportTemplateFeatureAssociationField',
  inject: ['formHook'],
  props: {
    features: {
      type: Object as PropType<Record<ReportTemplateFeatures, string>>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Array as PropType<Feature[]>,
      default: null,
    },
    allowFeatureDisassociation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vuetifyDefaults,
      loading: true,
      disabledFeatures: [],
    }
  },
  computed: {
    isEditing(): boolean {
      return Boolean(this.formHook().getPathValue('id'))
    },
    reportTemplateId(): number {
      return this.formHook().getPathValue('id')
    },
    selectedFeatures: {
      get(): Feature[] {
        return this.value
      },
      set(features: Feature[]) {
        this.formHook().setObjectValue(this.name, features)
      },
    },
    items(): FeatureSelectItem[] {
      return Object.entries(this.features).map(([feature, text]) => {
        return {
          text,
          value: { feature },
          disabled: this.isFeatureDisabled(feature),
        }
      }) as FeatureSelectItem[]
    },
  },
  created() {
    if (this.isEditing) {
      this.loadFeatureAssociation()
    } else {
      this.loadingDone()
    }
  },
  methods: {
    async loadFeatureAssociation() {
      try {
        const response = await getFeatureAssociationsForReportTemplate(
          this.$auth,
          this.reportTemplateId,
        )
        const features = response.associatedFeatures.map(
          (associatedFeature) => {
            this.disabledFeatures = [
              ...this.disabledFeatures,
              associatedFeature.feature,
            ]

            return { feature: associatedFeature.feature }
          },
        )
        this.selectedFeatures = features
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loadingDone()
      }
    },
    isFeatureDisabled(feature: string): boolean {
      return (
        !this.allowFeatureDisassociation &&
        this.disabledFeatures.includes(feature)
      )
    },
    loadingDone() {
      this.loading = false
    },
  },
})
</script>
<style>
/* Making the disabled options a bit transparent to distinct between enabled and disabled options */
.v-list-item--disabled {
  opacity: 0.5;
}
</style>
