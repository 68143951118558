<template>
  <tt-tile>
    <tt-attr slot="title" name="name" />
    <template #actionRight>
      <v-btn icon @click="edit">
        <v-icon small v-text="`mdi-pencil`" />
      </v-btn>
    </template>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import {
  EntityEditIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DataViewModularHooksTile',
  inject: ['getItemHook'],
  methods: {
    edit() {
      const payload: EntityEditIntentInterface = {
        resourceName: Resources.DATA_VIEW_MODULAR_HOOKS,
        entityId: this.getItemHook().getEntityId(),
      }

      this.$eventManager.dispatchEvent(EntityIntentTypes.EDIT, payload)
    },
  },
})
</script>
