import { AttributeEnum } from '@/tt-entity-design/src/schema-types'
import { Resources } from '@/tt-entity-design/src/types'

export enum CtaGroupsEnum {
  MOBILE = 'mobile',
  RUNSHEETS = 'runsheets',
  SAFETY_MONITORING = 'safety_monitoring',
  SHIFT_SCHEDULING = 'shift_scheduling',
  OTHERS = 'others',
}
export type GroupTypeView = {
  icon: string
  color: string
  i18nKey: string
}

export type CTAType = AttributeEnum<
  typeof Resources.SYSTEM_EXCEPTION_TICKETS,
  'type'
>

export type GroupedCtaTypes = Record<CtaGroupsEnum, CTAType[]>
