import { PluginFunction } from 'vue'

const ReportConditionSetWizardPage = () =>
  import(
    /* webpackChunkName: "ReportConditionSetWizardPage" */
    './pages/ReportConditionSetWizardPage.vue'
  )
const ReportLibraryFormPage = () =>
  import(
    /* webpackChunkName: "ReportLibraryFormPage" */
    './pages/ReportLibraryFormPage.vue'
  )
const ReportTemplateBuilderJsonField = () =>
  import(
    /* webpackChunkName: "ReportTemplateBuilderJsonField" */
    './components/ReportTemplateBuilderJsonField.vue'
  )
const ReportTemplateBuilderJsonObject = () =>
  import(
    /* webpackChunkName: "ReportTemplateBuilderJsonObject" */
    './components/ReportTemplateBuilderJsonObject.vue'
  )
const ReportTemplateFieldFormPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldFormPage" */
    './pages/ReportTemplateFieldFormPage.vue'
  )
const ReportTemplateFieldGroupFormPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldGroupFormPage" */
    './pages/ReportTemplateFieldGroupFormPage.vue'
  )
const ReportTemplateFieldsPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldsPage" */
    './pages/ReportTemplateFieldsPage.vue'
  )
const ReportTemplateFieldsView = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldsView" */
    './views/ReportTemplateFieldsView.vue'
  )

const ReportTemplateListView = () =>
  import('./views/ReportTemplateListView.vue')

const ReportTemplateView = () => import('./views/ReportTemplateView.vue')

const ReportTemplateOverviewView = () =>
  import('./views/ReportTemplateOverviewView.vue')

export {
  ReportTemplateFieldsView,
  ReportTemplateListView,
  ReportTemplateView,
  ReportTemplateOverviewView,
}

export const ReportTemplatesPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ReportConditionSetWizardPage', ReportConditionSetWizardPage)
  Vue.component('ReportLibraryFormPage', ReportLibraryFormPage)
  Vue.component(
    'ReportTemplateBuilderJsonField',
    ReportTemplateBuilderJsonField,
  )
  Vue.component(
    'ReportTemplateBuilderJsonObject',
    ReportTemplateBuilderJsonObject,
  )
  Vue.component('ReportTemplateFieldFormPage', ReportTemplateFieldFormPage)
  Vue.component(
    'ReportTemplateFieldGroupFormPage',
    ReportTemplateFieldGroupFormPage,
  )
  Vue.component('ReportTemplateFieldsPage', ReportTemplateFieldsPage)
  Vue.component('ReportTemplateFieldsView', ReportTemplateFieldsView)
  Vue.component('ReportTemplateListView', ReportTemplateListView)
  Vue.component('ReportTemplateView', ReportTemplateView)
  Vue.component('ReportTemplateOverviewView', ReportTemplateOverviewView)
}
