<template>
  <WidgetFactory :widget="widget" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import { ListWidgetModel } from '@/tt-widget-components/schemas-types'

import { FilterOperatorType } from '@/tt-widget-factory'

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & { relationPrefix: string }>
).extend({
  name: 'ShiftEventActivities',
  inject: ['relationPrefix', 'getItemHook'],
  computed: {
    widget(): ListWidgetModel | null {
      const employeeId = this.getItemHook().getRawValue('employee.id')
      const startsOn = this.getItemHook().getRawValue('startsOn')
      const endsOn = this.getItemHook().getRawValue('endsOn')

      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: Resources.EVENT_ACTIVITIES,
          filters: [
            {
              attribute: 'user',
              operator: FilterOperatorType.EQUAL,
              value: employeeId ?? '',
            },
            {
              attribute: 'createdOn',
              operator: FilterOperatorType.BETWEEN,
              value: [startsOn, endsOn],
            },
          ],
        },
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('employee.id')
    this.getItemHook().addAttribute('startsOn')
    this.getItemHook().addAttribute('endsOn')
  },
})
</script>
