import i18n from '@/plugins/i18n'
import {
  SchedulerEventHandler,
  wrapSchedulerEventTemplate,
} from '@/tt-widget-components/widgets/Scheduler/scheduler-event-handler'

import { getSiteTaskScheduleTypeColor } from './site-task-schedule-type'

const requiredFields: SchedulerEventHandler['requiredFields'] = () => [
  'checkpointTour.name',
  'client.id',
  'description',
  'durationMinutes',
  'position.name',
  'rangeStartTime',
  'taskType.name',
  'type',
]

const transform: SchedulerEventHandler['transform'] = ({ itemHook }) => {
  const typeRaw = itemHook.getRawValue('type')

  const getTime = (): string => {
    const startTime = itemHook.get('rangeStartTime')
    const duration = itemHook.get('durationMinutes')

    return duration ? `${startTime} (${duration})` : startTime
  }

  const getType = (): { color: string; label: string } => ({
    color: getSiteTaskScheduleTypeColor(typeRaw) ?? 'N/A',
    label:
      itemHook.get('type') ??
      i18n.t('tt-entity-design.site-task-schedules.new'),
  })

  return {
    id: itemHook.getEntityId(),
    rangeStartTime: itemHook.getRawValue('rangeStartTime'),
    taskType: itemHook.get('taskType.name') ?? 'N/A',
    description: itemHook.get('description') ?? 'N/A',
    position: itemHook.get('position.name') ?? 'N/A',
    time: getTime() ?? 'N/A',
    checkpointTour: itemHook.get('checkpointTour.name') ?? 'N/A',
    type: getType(),
  }
}

const template: SchedulerEventHandler['template'] =
  wrapSchedulerEventTemplate(/* html */ `
<div style="background-color: #ffffff; color: #57626e; font-size: 12px; padding: 8px 15px 15px">
  <div style="color: #343a40">
    <strong>\${taskType}</strong>
  </div>
  <div style="color: #343a40" title="\${description}">
    \${description}
  </div>
  <div>
    <strong>\${position}</strong>
  </div>
  <div>\${time}</div>
  <div>\${checkpointTour}</div>
  <div>
    <strong style="background-color: \${type.color}; border-radius: 2px; color: #ffffff; display: inline-block; font-size: 0.815em; line-height: 1.5em; padding: 0 5px; text-transform: uppercase">
      \${type.label}
    </strong>
  </div>
</div>
`)

export const siteTaskSchedulesSchedulerEventHandler: SchedulerEventHandler = {
  requiredFields,
  template,
  transform,
}
