<template>
  <div style="position: relative">
    <TLoadingBar v-show="isLoading" />
    <tt-attr-row-card v-bind="$props">
      <tt-attr-row name="taskType" />
      <tt-attr-row name="site" />
      <tt-attr-row name="jobInstructions" />
      <tt-attr-row name="generalInstructions" />
      <tt-attr-row name="checkPointTour" />
      <tt-attr-row name="priceTier" />
      <tt-attr-row name="dispatchSla" />
      <tt-attr-row name="generalDispatchSla" />

      <!--
        Use v-show so the attributes are included in the first request
        Using v-if causes a second request when the condition changes to true
      -->
      <div v-show="isAssetTrackerEnabled">
        <tt-attr-row name="dispatchableAssets" />
        <tt-attr-row name="schedulableAssets" />
        <tt-attr-row name="siteTaskAssets" />
      </div>

      <tt-attr-row name="vendor" />
    </tt-attr-row-card>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { createClientFeatureManager } from '@/tt-feature-manager/ClientFeatureManager'
import { EntityFeatureManager } from '@/tt-feature-manager/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

const ASSET_TRACKER_FEATURE_CODE = 'AssetHubFeature'

type VueWithInjections = VueConstructor<Vue & ItemHookProvider>

export default (Vue as VueWithInjections).extend({
  name: 'TaskSiteInstructionsDetail',
  inject: ['getItemHook'],
  data() {
    return {
      featureManager: null as EntityFeatureManager | null,
    }
  },
  computed: {
    clientId(): number | null {
      return this.getItemHook().get('site.id') ?? null
    },
    isAssetTrackerEnabled(): boolean {
      return (
        this.featureManager?.hasFeature(ASSET_TRACKER_FEATURE_CODE) ?? false
      )
    },
    isLoading(): boolean {
      return this.featureManager?.isLoading() ?? true
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        this.resetFeatureManager()
      },
    },
  },
  methods: {
    resetFeatureManager(): void {
      const api = this.$auth.getApi()
      this.featureManager =
        this.clientId && createClientFeatureManager(this.clientId, { api })
    },
  },
})
</script>
