<template>
  <div>
    <v-tabs
      v-model="tab"
      no-gutters
      slider-color="orange"
      class="small-tabs mb-2 flex-grow-0"
      dense
      small
      short
    >
      <v-tab
        v-text="$t('tt-entity-design.dispatch-tasks.form-tabs.new-address')"
      />
      <v-tab
        v-text="$t('tt-entity-design.dispatch-tasks.form-tabs.coordinates')"
      />
    </v-tabs>

    <v-tabs-items v-model="tab" class="px-3 pt-2">
      <v-tab-item>
        <json-field name="location.name" :rules="rules" autocomplete="off" />
        <json-field name="location.addressLine1" autocomplete="off" />
        <json-field name="location.addressLine2" autocomplete="off" />
        <v-row no-gutters>
          <v-col cols="6" class="pr-4">
            <json-field name="location.city" autocomplete="off" />
          </v-col>
          <v-col cols="6">
            <json-field name="location.state" autocomplete="off" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-4">
            <json-field name="location.postalCode" autocomplete="off" />
          </v-col>
          <v-col cols="6">
            <json-field name="location.country" autocomplete="off" />
          </v-col>
        </v-row>

        <DispatchTasksFormNewClientInformationsFields
          v-if="!isEditForm"
          @input="$emit('input:new-client', $event)"
        />
      </v-tab-item>

      <v-tab-item>
        <json-field name="location.latitude" />
        <json-field name="location.longitude" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script lang="ts">
import { FormHookProvider } from '@/tt-widget-components'
import { ErrorObject } from '@tracktik/tt-json-schema-form'
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import DispatchTasksFormNewClientInformationsFields from './DispatchTasksFormNewClientInformationsFields.vue'
import { LocationType } from '../types'
import { Geocoding, createGeocodeSdk } from '@tracktik/tt-geo-proxy'
import debounce from 'lodash/debounce'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormLocationTabs',
  components: {
    DispatchTasksFormNewClientInformationsFields,
  },
  inject: ['formHook', 'dispatchFormService'],
  data() {
    return {
      loading: false,
      tab: 0,
      rules: [] as Array<(v: string | number) => string | boolean>,
    }
  },
  computed: {
    locationTypeValue(): LocationType {
      return this.formHook().getPathValue('locationType')
    },
    isEditForm(): boolean {
      return this.dispatchFormService.getIsEditForm()
    },
    isSavedLocation(): boolean {
      return this.dispatchFormService.getIsSavedLocation()
    },
    error(): ErrorObject[] {
      return [
        {
          dataPath: `.location`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    locationName(): string {
      return this.formHook().getPathValue('location.name')
    },
    locationRegion(): number {
      return this.formHook().getPathValue('location.region')
    },
    searchString(): string {
      const line1 = this.formHook().getPathValue('location.addressLine1')
      const line2 = this.formHook().getPathValue('location.addressLine2')
      const city = this.formHook().getPathValue('location.city')
      const state = this.formHook().getPathValue('location.state')
      const postalCode = this.formHook().getPathValue('location.postalCode')
      const country = this.formHook().getPathValue('location.country')

      return [line1, line2, city, state, postalCode, country]
        .filter(Boolean)
        .join(', ')
    },
    debounceSearchFn(): () => void {
      return debounce(() => this.reverseGeocode(), 500)
    },
  },
  watch: {
    tab: {
      handler(value) {
        if (value === 0) {
          this.setLocationType(LocationType.ADDRESS, true)
        } else if (value === 1) {
          this.setLocationType(LocationType.COORDINATES, false)
        }
      },
      immediate: true,
    },
    locationName(value) {
      if (value && this.isSavedLocation) {
        this.formHook().setCustomError('location.name', null)
      }
      if (!value && this.isSavedLocation) {
        this.formHook().setCustomError('location.name', this.error)
      }
    },
    locationRegion(value) {
      if (value) {
        this.formHook().setCustomError('location.region', null)
      }
      if (!value) {
        this.formHook().setCustomError('location.region', this.error)
      }
    },
    isSavedLocation(value) {
      if (value) {
        this.rules = [(v) => !!v || this.$t('common.required_field')]
        if (!this.locationName) {
          this.formHook().setCustomError('location.name', this.error)
        }
      } else {
        this.rules = []
        this.formHook().setCustomError('location.name', null)
        this.formHook().setCustomError('location.region', null)
      }
    },
    searchString(currentSearch, previousSearch) {
      if (currentSearch !== previousSearch) this.debounceSearchFn()
    },
  },
  created() {
    // Set the tab based on the location type
    this.tab = this.locationTypeValue === LocationType.COORDINATES ? 1 : 0

    if (!this.locationRegion) {
      this.formHook().setCustomError('location.region', this.error)
    }
  },
  methods: {
    setLocationType(type: LocationType, autoGeoCode: boolean) {
      this.dispatchFormService.setErrorsToWhiteList(type)
      this.formHook().setObjectValue('locationType', type)
      this.formHook().setObjectValue('location.type', type)
      this.formHook().setObjectValue('location.autoGeoCode', autoGeoCode)

      if (type === LocationType.COORDINATES) {
        this.dispatchFormService.setIsSavedLocation(false)
      }
    },
    async reverseGeocode() {
      this.loading = true

      const results = await createGeocodeSdk(this.$appContext.authModule)
        .fetchGeocoding(this.searchString)
        .catch((error) => {
          console.error('Error fetching geocoding', error)

          return [] as Geocoding[]
        })

      if (results.length === 1) {
        const { longitude, latitude } = results[0].coordinates

        if (longitude && latitude) {
          this.formHook().setObjectValue('location.latitude', latitude)
          this.formHook().setObjectValue('location.longitude', longitude)
        }
      }

      this.loading = false
    },
  },
})
</script>
