<template>
  <div v-if="canViewResource">
    <ResourceAllowedOperations
      v-slot="{ allowsCreation: allowCreation }"
      :resource-name="resourceName"
    >
      <EntitySelectorField
        v-if="!missingVariableValues.length && !isLoading"
        v-model="model"
        :resource="resourceName"
        v-bind="{
          allowCreation: !blockCreation && allowCreation,
          loading,
          queryOptions,
          modelContext,
          disabled: isReadOnly,
          ...$props,
          ...$attrs,
        }"
        style="width: 100%"
        v-on="$listeners"
      >
        <template #label>
          <slot name="label" />
        </template>
      </EntitySelectorField>

      <v-skeleton-loader
        v-else-if="isActionForm && isLoading"
        type="heading"
        class="relation-field-skeleton mb-4"
      />

      <v-text-field
        v-else
        disabled
        error
        :error-messages="missingVariableErrorMessage"
        prepend-inner-icon="mdi-alert-circle-outline"
        v-bind="{ ...$attrs }"
      />
    </ResourceAllowedOperations>
  </div>
  <v-text-field
    v-else
    disabled
    v-bind="{ ...$props, ...$attrs, value: '******' }"
    error
    :error-messages="$t(`common.missing-view-permission-resource`)"
    prepend-inner-icon="mdi-lock"
    style="width: 100%"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import { PropType, VueConstructor } from 'vue'

import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { FormLabelTypes, JSONSchema7 } from '@tracktik/tt-json-schema-form'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import i18n from '@/plugins/i18n'
import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { FormHookProvider } from '@/tt-widget-components'
import { RelationFilter } from '@/tt-widget-factory/services/resource-meta/types'

import {
  extractMustacheVariables,
  convertRelationFiltersToQueryOptions,
  replaceVariables,
  TTC_RELATION_FILTER,
  TTC_RELATION_NAME,
  removeVariablePrefix,
  isCurrentUserId,
  isEntityAttribute,
  isFormField,
} from './utils/RelationField'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { ContextAttributeMap } from '@/tt-widget-components/base/contextAttributeMap'

type VueWithInjections = VueConstructor<
  InstanceType<typeof BaseInput> & FormHookProvider & { namespace: string }
>

export default (BaseInput as VueWithInjections).extend({
  name: 'RelationField',
  inject: {
    formHook: { from: 'formHook' },
    namespace: { from: 'namespace' },
  },
  props: {
    /**
     * Additional query options that will be added to the request
     */
    additionalQueryOptions: {
      type: Object as PropType<EntityCollectionRequestOptions>,
      default: (): EntityCollectionRequestOptions => ({}),
    },
    /**
     * Override the default of showing the creation button on the field
     */
    blockCreation: { type: Boolean, default: false },
    /**
     * Force loading state
     */
    loading: { type: Boolean, default: null },
    /**
     * Name of the resource to be fetched from the API
     */
    resource: { type: String, default: undefined },
    /**
     * Field's value
     */
    value: {
      type: [Number, String, Array],
      default: null,
    },
    modelContext: {
      type: Object as PropType<ContextAttributeMap>,
      default: () => ({}),
    },
  },
  computed: {
    isReadOnly(): boolean {
      return this.formHook()?.isReadOnly() || false
    },
    isLoading(): boolean {
      return !!(this.loading || this.itemHook?.loading)
    },
    schema(): JSONSchema7 {
      const schema = this.formHook().getField(this.name)?.props?.$schema

      if (!schema)
        console.warn(
          `Could not find schema in formHook for field path: ${this.name}`,
        )

      return schema || {}
    },
    resourceName(): string {
      const resourceName = this.resource || this.schema[TTC_RELATION_NAME]

      if (!resourceName)
        console.warn('No resource provided in schema for relation')

      return resourceName
    },
    canViewResource(): boolean {
      return ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.$appContext),
        this.resourceName,
      )
    },
    relationFilters(): RelationFilter {
      return this.schema[TTC_RELATION_FILTER] || {}
    },
    /**
     * The main form resource. Not the current relation resource.
     */
    formHookResource(): string {
      return this.formHook().getUserContextValue('resourceName')
    },
    actionFormName(): string {
      return this.formHook().getUserContextValue('action')
    },
    isActionForm(): boolean {
      return !!this.actionFormName
    },
    itemHook(): EntityItemHook | undefined {
      return this.formHook().getUserContextValue('itemHook')
    },
    variableValuesMap(): Record<string, any> {
      const createMapEntries = (fieldName) => [
        fieldName,
        this.getVariableValue(fieldName),
      ]

      return Object.fromEntries(
        extractMustacheVariables(this.relationFilters).map(createMapEntries),
      )
    },
    missingVariableValues(): string[] {
      /**
       * Missing entity values (ie: `$entity.id`) won't prevent the UI from sending the request,
       * thus we remove them from the relation filters.
       *
       * The UI will only prevent the request if a `$form.property` value is missing.
       */
      const isNotSpecialVariable = ([mustacheVariable, _]: [string, unknown]) =>
        !isCurrentUserId(mustacheVariable) &&
        !isEntityAttribute(mustacheVariable)

      const valueIsMissing = ([_, value]) => isNil(value)

      return Object.entries(this.variableValuesMap)
        .filter(isNotSpecialVariable)
        .filter(valueIsMissing)
        .map(([mustacheVariable]) => mustacheVariable)
    },
    missingVariableErrorMessage(): string {
      const translateVariable = (attr) =>
        this.isActionForm
          ? this.translateActionAttribute(attr)
          : this.translateEntityAttribute(attr)

      const missingVariables = this.missingVariableValues
        .map(translateVariable)
        .join(', ')

      const errorMsg = i18n.t(
        'tt-entity-forms.relation-fields.missing-variables-error',
      )

      return `${errorMsg} (${missingVariables})`
    },
    queryOptions(): EntityCollectionRequestOptions {
      const relationFiltersWithStaticValues = replaceVariables(
        this.relationFilters,
        this.variableValuesMap,
      )

      const { filters, ...queryOptions } = convertRelationFiltersToQueryOptions(
        relationFiltersWithStaticValues,
      )
      const { filters: additionalFilters, ...additionalQueryOptions } =
        this.additionalQueryOptions

      return {
        filters: [...(filters || []), ...(additionalFilters || [])],
        ...queryOptions,
        ...additionalQueryOptions,
      }
    },
  },
  methods: {
    translateActionAttribute(attr: string) {
      return ResourceTranslator.translateActionAttribute(
        this.formHookResource,
        this.actionFormName,
        removeVariablePrefix(attr),
        FormLabelTypes.LABEL,
        undefined,
        { i18n: this.$i18n },
      )
    },
    translateEntityAttribute(attr: string) {
      const { resourceMetaManager } = this.$appContext.widgetServices

      return ResourceTranslator.translateAttribute(
        this.formHookResource,
        removeVariablePrefix(attr),
        FormLabelTypes.LABEL,
        undefined,
        { i18n: this.$i18n, resourceMetaManager },
      )
    },
    getFormValue(formFieldName: string): unknown {
      return this.formHook().getPathValue(formFieldName)
    },
    getEntityAttributeValue(entityAttributeName: string): unknown {
      return this.itemHook?.getRawValue(entityAttributeName)
    },
    getVariableValue(mustacheVariable: string): unknown {
      // special variables
      if (isCurrentUserId(mustacheVariable)) return this.$auth.getUserId()

      if (isFormField(mustacheVariable)) {
        const fieldName = removeVariablePrefix(mustacheVariable)

        return this.getFormValue(fieldName)
      }

      if (isEntityAttribute(mustacheVariable)) {
        const attributeName = removeVariablePrefix(mustacheVariable)

        return this.getEntityAttributeValue(attributeName)
      }

      /**
       * Fallback to old behaviour to stay retro-compatible (no `$form` or `$entity` prefix).
       * To be removed after this API ticket is deployed : https://tracktik.atlassian.net/browse/API-2089
       */
      return this.isActionForm
        ? // for action forms, we map values against the API entity
          this.getEntityAttributeValue(mustacheVariable)
        : // for create / edit forms, we map values against the current form fields
          this.getFormValue(mustacheVariable)
    },
  },
  watch: {
    /**
     * When any dependency filter value changes,
     * we reset the outdated relation to avoid any invalid selection
     */
    variableValuesMap(newVal, oldVal): void {
      if (isEqual(newVal, oldVal)) return
      this.model = null
    },
    missingVariableValues: {
      handler(newVal: string[]) {
        if (this.itemHook) {
          newVal
            .filter(isEntityAttribute)
            .map(removeVariablePrefix)
            .forEach(this.itemHook.addAttribute)
        }
      },
      immediate: true,
    },
    formHookResource: {
      handler() {
        if (!this.formHookResource)
          console.warn(
            'no "resourceName" provided in the formHook "user-context"',
          )
      },
      immediate: true,
    },
  },
})
</script>

<style scoped>
.relation-field-skeleton >>> .v-skeleton-loader__heading {
  width: 100%;
}
</style>
