<template>
  <TPriorityIcon
    v-if="priority && priority.value"
    :color="priority.color"
    :icon="priority.icon"
    :value="priority.value"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTasksPriorityTypes, PriorityConfig } from '@/tt-ui/types'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksPriorityAttr',
  inject: ['getItemHook'],
  computed: {
    priority(): PriorityConfig {
      const priorityValue = this.getItemHook()
        .getRawValue('priorityRating')
        ?.toString()

      const priorityDetails = DispatchTasksPriorityTypes[priorityValue]

      const value: string = priorityValue ? this.$t(priorityDetails.value) : ''

      return {
        ...priorityDetails,
        value,
      }
    },
  },
  created() {
    this.getItemHook().addAttribute('priorityRating')
  },
})
</script>
