<template>
  <div class="tabs-container d-flex align-center muted">
    <div class="tab-wrapper">
      <div
        v-for="(widget, tabIndex) in widgets"
        :key="tabIndex"
        ref="item"
        class="tab text-button"
        :class="isActiveTab(tabIndex) ? 'active-tab' : 'muted'"
      >
        <drag
          v-if="widget.is && hasToBeDraggable"
          class="dragging"
          :transfer-data="switchWidgetData(tabIndex)"
          hide-image-html
          @dragstart="onDragStart(widget, tabIndex)"
          @dragend="isBeingDragged = false"
          @hook:destroyed="isBeingDragged = false"
          @mouseenter="isDraggable = true"
          @mouseleave="isDraggable = false"
        >
          <DashboardWidgetTab
            :has-to-display-delete-tab="hasToDisplayDeleteTab(tabIndex)"
            :current-position="currentPosition(tabIndex)"
            v-bind="{
              widget,
              uid,
              tabIndex,
            }"
            @deleteTab="deleteTab(tabIndex)"
            @input="updateTabIndex(tabIndex)"
          />

          <div slot="image" class="drag-container" style="width: 300px">
            <DashboardWidgetCard :key="widget.uid" dark :item="widget" />
          </div>
        </drag>
        <div v-else class="dashboard--tab-header">
          <div class="py-2 dashboard--tab-divider-container">
            <v-divider
              v-if="showDivider(tabIndex)"
              class="dashboard--tab-divider ma-0"
              vertical
            />
          </div>

          <DashboardWidgetTab
            :has-to-display-delete-tab="hasToDisplayDeleteTab(tabIndex)"
            :current-position="currentPosition(tabIndex)"
            v-bind="{ widget, uid, tabIndex }"
            @deleteTab="deleteTab(tabIndex)"
            @input="updateTabIndex(tabIndex)"
          />
        </div>
      </div>
    </div>

    <!-- button: info -->
    <div v-if="showInfoButton" class="info-button">
      <v-btn
        rounded
        icon
        small
        class="opacity-faded transparent"
        @click="$emit('infoClick')"
      >
        <v-icon small v-text="`mdi-information`" />
      </v-btn>
    </div>
    <!-- / button: info -->

    <!-- button: add -->
    <div v-if="hasToDisplayAddTabButton" style="position: relative">
      <div class="add-button" @dragenter="onDraggingOver(widgets.length - 1)">
        <v-tooltip bottom :open-delay="tooltipOpenDelay">
          <template #activator="{ on }">
            <v-btn icon small v-on="on" @click.stop="addTab">
              <v-icon small> add </v-icon>
            </v-btn>
          </template>
          {{ $t('widgets.dashboard.add_tab') }}
        </v-tooltip>
      </div>
      <!-- drop zone -->
      <drop
        :style="{ visibility: incomingDragOver ? 'visible' : 'hidden' }"
        class="overlay-drop-zone"
        @drop="onDropAndAdd($event)"
        @dragleave="incomingDragOver = false"
      />
      <!-- / drop zone -->
    </div>
    <!-- / button: add -->
  </div>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import uniqueId from 'lodash/uniqueId'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { WidgetModels } from '@/tt-widget-components'
import {
  DRAG_OVER_DELAY,
  MIN_TAB_WIDTH,
  TOOLTIP_OPEN_DELAY,
} from '@/helpers/constants'
import { WidgetPosition } from './types'
import { DraggableInterface } from '@/apps/app.tracktik.insights.studio/types'
import { ChooseWidgetByDragging } from '@/plugins/o11n'
import DashboardWidgetCard from './DashboardWidgetCard.vue'
import DashboardWidgetTab from './DashboardWidgetTab.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import DashboardWidgetHook from './DashboardWidgetHook'
import { updateDOM } from '@/helpers/dom'
import DashboardWidgetDragAndDropService from './DashboardWidgetDragAndDropService'

type VueWithLayoutManager = VueConstructor<
  Vue & {
    layoutManager: LayoutManager
    dragAndDropService: () => DashboardWidgetDragAndDropService
  }
>

export default (Vue as VueWithLayoutManager).extend({
  name: 'DashboardWidgetTabs',
  components: {
    DashboardWidgetCard,
    DashboardWidgetTab,
  },
  inject: {
    layoutManager: {
      default: null,
    },
    dragAndDropService: {
      from: 'dashboardWidgetDragAndDropService',
    },
  },
  props: {
    widgets: {
      type: Array as PropType<WidgetModels[]>,
      required: true,
    },
    hook: {
      type: Object as PropType<DashboardWidgetHook>,
      required: true,
    },
    showInfoButton: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      isActiveTabOverlapped: true,
      uid: uniqueId(),
      isDraggable: false,
      isBeingDragged: false,
      incomingDragOver: false,
    }
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    hasOnlyOneTab(): boolean {
      return this.widgets.length === 1
    },
    hasToDisplayAddTabButton(): boolean {
      return this.editMode && this.isActiveTabOverlapped
    },
    dashboardHasOneRowAndCol(): boolean {
      return this.hook.hasOnlyOneRowOneCol()
    },
    isSideSheetOpen(): boolean {
      return this.layoutManager?.state?.sideSheet?.state
    },
    hasToBeDraggable(): boolean {
      return (
        !this.dashboardHasOneRowAndCol && !this.isSideSheetOpen && this.editMode
      )
    },
    debounceIncomingDragOver(): DebouncedFunc<() => void> {
      return debounce(() => (this.incomingDragOver = false), DRAG_OVER_DELAY)
    },
  },
  watch: {
    widgets() {
      // Added this logic here, because if you remove the last tab from the list, the index of the active tab no longer exists
      if (this.activeTab === this.widgets.length) {
        this.activeTab = this.widgets.length - 1
      }
      this.checkActiveTabOverlap()
    },
  },
  methods: {
    showDivider(index: number) {
      return !this.isActiveTab(index) && !this.isActiveTab(index + 1)
    },
    async updateTabIndex(tabIndex: number) {
      const index = Number(tabIndex)
      this.activeTab = tabIndex

      await updateDOM()

      this.$emit('input', index)
    },
    onDraggingOver(index: number): void {
      // if we drag over the current dragging item, we do nothing
      if (
        isEqual(
          this.currentPosition(index),
          this.dragAndDropService()?.getInitialPosition(),
        )
      )
        return

      this.incomingDragOver = true

      this.debounceIncomingDragOver()
    },
    async addTab() {
      this.$emit('addTab')
      // We want the new tab to be the active one
      this.activeTab = this.widgets.length
      await updateDOM()
      this.checkActiveTabOverlap()
    },
    deleteTab(index: number) {
      this.$emit('deleteTab', index)
    },
    isActiveTab(index: number): boolean {
      return this.activeTab === index
    },
    hasToDisplayDeleteTab(index: number): boolean {
      return this.editMode && !this.hasOnlyOneTab && this.isActiveTab(index)
    },
    async checkActiveTabOverlap(): Promise<void> {
      await this.$nextTick()

      const items = this.$refs['item'] as HTMLInputElement[]

      this.isActiveTabOverlapped = !items?.filter(
        (item) =>
          item.offsetWidth <= MIN_TAB_WIDTH &&
          item.className.includes('active-tab'),
      ).length
    },
    onDragStart(widget: WidgetModels, index: number) {
      this.isBeingDragged = true

      this.dragAndDropService()?.dragStartPosition(this.currentPosition(index))

      this.$analytics.track(
        ChooseWidgetByDragging.create({
          label: widget.title,
        }),
      )
    },
    currentPosition(tabIndex): WidgetPosition {
      return {
        rowIndex: this.rowIndex,
        colIndex: this.colIndex,
        tabIndex,
      }
    },
    switchWidgetData(tabIndex): DraggableInterface {
      return {
        type: 'WIDGET',
        from: 'switching',
        payload: this.currentPosition(tabIndex),
      }
    },
    onDropAndAdd(event: DraggableInterface) {
      this.incomingDragOver = false

      const index = this.dragAndDropService()?.dropAndAddWidget(
        event,
        this.currentPosition(this.widgets.length),
      )

      this.updateTabIndex(index)
      this.checkActiveTabOverlap()
    },
  },
})
</script>

<style scoped lang="scss">
.tab-wrapper {
  display: flex;
  height: 35px;
  max-width: calc(100% - 40px);
}

.tab {
  position: relative;
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.35);
  flex: 1 1 0;

  + .tab {
    border-left: 0;
  }

  .dragging {
    display: block;
    width: 100%;

    ::v-deep .tab-title {
      cursor: grab !important;
      &:active {
        cursor: grabbing !important;
      }
    }
  }

  // active tab
  &.active-tab {
    border-top: 2px solid orange !important;
    border-bottom: var(--v-level1-base) !important;
    color: rgba(0, 0, 0, 0.55);
    background: var(--v-level1-base);
  }
}
.tabs-container {
  background-color: var(--v-muted-base) !important;
  border-top-left-radius: 5px;

  .add-button {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
  }

  .info-button {
    display: none;
    background-color: #0000;
    margin-left: auto;
    height: 35px;
    width: 35px;
  }

  &:hover .info-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.v-application.theme--dark {
  .tab {
    color: rgba(225, 225, 225, 0.55);
    border-color: rgba(225, 225, 225, 0.1);
    &.active-tab {
      color: rgba(225, 225, 225, 0.75);
    }
    &:hover {
      background-color: rgba(225, 225, 225, 0.15);
    }
  }
  .add-button {
    border-color: rgba(225, 225, 225, 0.1);
  }
}

.overlay-drop-zone {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.186);
  border-radius: 5em;
}

.dashboard--tab-header {
  position: relative;
  height: 100%;
}

.dashboard--tab-divider-container {
  position: absolute;
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dashboard--tab-divider {
  min-height: 0;
  border-width: 0 0 0 1px !important;
  border-color: var(--v-level1-base);
}
</style>
