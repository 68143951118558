import { inject } from 'vue'

import { AuthModule } from '@tracktik/tt-authentication'

import { AssetManager } from './types'
import {
  createSubAssetsFetcher,
  fetchAssetPersons,
  fetchTaskSiteSettings,
} from './helpers'
import { createAssetManager } from './AssetManager'

export const prepareAssetManager = async (deps: {
  clientId: number
  taskTypeId: number
  employeeId: number | null
  auth: AuthModule
}): Promise<AssetManager> => {
  const { auth, clientId, taskTypeId, employeeId } = deps

  const [taskSiteSettings, checkedOutAssetsIds] = await Promise.all([
    fetchTaskSiteSettings(auth, clientId),
    employeeId
      ? fetchAssetPersons(auth, employeeId, clientId)
      : Promise.resolve(null),
  ])

  const taskInstructions = taskSiteSettings?.taskInstructions
  const fetchSubAssets = createSubAssetsFetcher(auth)

  return createAssetManager({
    taskInstructions,
    taskTypeId,
    fetchSubAssets,
    checkedOutAssetsIds,
  })
}

export const AssetManagerName = 'assetManager'

export const useAssetManager = () => {
  return inject<() => AssetManager>(AssetManagerName)
}
