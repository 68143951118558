<template>
  <v-treeview :items="assets" :load-children="onOpeningChildren">
    <template #label="{ item }">
      <div class="d-flex justify-space-between align-center">
        <TTooltipOnOverflow
          :text="`${item.type} : ${item.name}`"
          :disable-tooltip="!isOverflowing"
        >
          <OverflowObserver class="w-100" @overflow="onOverflow">
            <span
              :class="textColor"
              class="list--assets body-2 d-block text-truncate"
            >
              {{ item.type }} : {{ item.name }}
              <v-icon
                v-if="markAsChecked"
                small
                color="green"
                class="pl-2"
                v-text="`check`"
              />
            </span>
          </OverflowObserver>
        </TTooltipOnOverflow>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              v-if="item.checkedOut !== null"
              :color="item.checkedOut ? 'green' : 'red'"
              class="pl-2"
              v-on="on"
              v-text="
                item.checkedOut ? 'mdi-account-check' : 'mdi-account-cancel'
              "
            />
          </template>
          <span
            v-text="
              item.checkedOut
                ? $t('tt-entity-design.dispatch-tasks.checkedout-asset')
                : $t('tt-entity-design.dispatch-tasks.no-checkedout-asset')
            "
          />
        </v-tooltip>
      </div>
    </template>
  </v-treeview>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { TreeViewAsset } from '../asset-manager/types'
import TTooltipOnOverflow from '@/tt-ui/components/TTooltipOnOverflow.vue'
import OverflowObserver from '@/tt-widget-components/components/OverflowObserver.vue'

export default Vue.extend({
  name: 'TaskAssetsTree',
  components: { TTooltipOnOverflow, OverflowObserver },
  props: {
    assets: {
      type: Array as PropType<TreeViewAsset[]>,
      required: true,
    },
    markAsChecked: {
      type: Boolean,
      default: false,
    },
    /**
     * Callback to call when opening children of a node.
     * The callback should return a promise. Used to lazy-load extra information for the children.
     */
    onOpeningChildren: {
      type: Function as PropType<(assetId: number) => Promise<void>>,
      default: null,
    },
  },
  data: () => ({
    isOverflowing: false,
  }),
  computed: {
    textColor(): string {
      return this.markAsChecked ? 'green--text' : 'grey--text'
    },
  },
  methods: {
    onOverflow(isOverflowing: boolean) {
      this.isOverflowing = isOverflowing
    },
  },
})
</script>

<style scoped>
.v-treeview >>> .v-treeview-node__level {
  width: 12px !important;
}
.list--assets {
  display: flex;
}

.button--expand {
  font-size: 0.7em;
  height: 100%;
  padding: 0 1em;
  margin: 0 1em;
}
</style>
