<template>
  <ProvideCompoundFormManager
    :initial-value="value"
    @input="$emit('input', $event)"
    @errors="$emit('errors', $event)"
    @valid="$emit('valid')"
    @ready="setFormManager"
  >
    <json-form
      v-if="formManager"
      :form-options="formOptions"
      :schema="form.schema"
      :value="formManager.getFormValue(formId)"
      @input="formManager.setFormValue(formId, $event)"
      @errors="formManager.setFormErrors(formId, $event)"
      @valid="formManager.setFormErrors(formId, {})"
    >
      <json-field name="reportFields" />
    </json-form>
  </ProvideCompoundFormManager>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import ProvideCompoundFormManager from '@/tt-app-layout/components/ProvideCompoundFormManager.vue'
import { CompoundFormManager } from '@/tt-app-layout'
import {
  DefinitionOption,
  DefinitionOptionMap,
  EmptyValueRule,
  FormOptions,
} from '@tracktik/tt-json-schema-form'
import { getResourceDefinitions } from '@/tt-entity-forms'

import { ReportSubmissionModel, ReportFormData } from '../types'
import { REPORT_SCHEMA_GROUPS_NAMES } from '../constants'

export default Vue.extend({
  name: 'ReportFieldsForm',
  components: { ProvideCompoundFormManager },
  props: {
    form: {
      type: Object as PropType<ReportFormData>,
      required: true,
    },
    value: {
      type: Object as PropType<ReportSubmissionModel>,
      default: () => ({}),
    },
  },
  data() {
    return {
      formManager: null as CompoundFormManager | null,
    }
  },
  computed: {
    formId(): string {
      return this.$options.name
    },
    formOptionDefinitions(): DefinitionOptionMap {
      const resourceDefinitions = getResourceDefinitions(this.$appContext)

      return {
        ...resourceDefinitions,
        ...this.form.formOptions?.definitions,
        ...this.objectDefinitions,
        MultiBarcode: this.multiBarcodeDefinition,
      }
    },
    formOptions(): FormOptions {
      return {
        ...this.form.formOptions,
        definitions: this.formOptionDefinitions,
        emptyValues: EmptyValueRule.KEEP,
      }
    },
    multiBarcodeDefinition(): DefinitionOption {
      return {
        view: {
          props: {
            itemProps: {
              label: this.$t('report.views.create.barcode'),
            },
          },
        },
      }
    },
    objectDefinitions(): DefinitionOptionMap {
      if (!this.reportObjects) return null

      return Object.fromEntries(
        this.reportObjects.map((obj) => [
          obj,
          {
            ...this.form.formOptions?.definitions?.[obj],
            view: { is: 'ReportCreateObject' },
          },
        ]),
      )
    },
    reportObjects(): string[] {
      if (!this.form.schema) return null

      return Object.keys(this.form.schema.definitions ?? {}).filter((prop) =>
        REPORT_SCHEMA_GROUPS_NAMES.some((name) => prop.startsWith(name)),
      )
    },
  },
  methods: {
    setFormManager(formManager: CompoundFormManager) {
      this.formManager = formManager
    },
  },
})
</script>
