<template>
  <ResourceAllowedOperations
    v-slot="{ allowsActions }"
    :resource-name="resourceName"
  >
    <EntityActions
      v-if="allowsActions"
      v-slot="{ actions }"
      :entity-id="clientId"
      :resource-name="resourceName"
    >
      <v-btn
        v-if="hasAction(actions, actionName)"
        class="text-none"
        color="ttPrimary"
        dark
        depressed
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
        @click="openForm"
      >
        <v-icon>add</v-icon>
        <span
          v-if="$vuetify.breakpoint.mdAndUp"
          v-text="$t('vendors.tabs.add-vendors')"
        />
      </v-btn>
    </EntityActions>
  </ResourceAllowedOperations>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { FormOptions } from '@tracktik/tt-json-schema-form'

import { Resources } from '@/tt-entity-design/src/types'
import { ActiveWindow } from '@/tt-app-layout'
import {
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ClientActions } from '@/tt-entity-design/src/components/clients/types'

type VueWithInjections = VueConstructor<
  Vue & { formOptions: FormOptions; activeWindow: ActiveWindow }
>

export default (Vue as VueWithInjections).extend({
  name: 'AddVendorsButton',
  inject: {
    formOptions: { default: undefined },
    activeWindow: { default: undefined },
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    actionName(): string {
      return ClientActions.AssignVendors
    },
    resourceName(): string {
      return Resources.CLIENTS
    },
  },
  methods: {
    hasAction(actions: Action[], actionName: string): boolean {
      return actions.some((action) => action.actionName === actionName)
    },
    async openForm() {
      this.$appContext.eventManager.dispatchEvent(
        EntityIntentTypes.RUN_ACTION,
        {
          resourceName: this.resourceName,
          actionName: this.actionName,
          entityId: this.clientId,
        },
      )
    },
  },
})
</script>
