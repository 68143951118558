<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column">
      <div class="toolbar3 pa-2 flex--row align-center">
        <TSearchInput :value="search" :debounce-time="100" @input="setSearch" />

        <div class="d-flex align-center pl-1">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :class="showInactive && 'show-inactive--on'"
                class="button--icon"
                icon
                v-on="on"
                @click="showInactive = !showInactive"
              >
                <v-icon small v-text="`mdi-archive`" />
              </v-btn>
            </template>

            <span v-text="$t('components.region_filter_show.inactive')" />
          </v-tooltip>
        </div>

        <div class="d-flex align-center">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn class="button--icon" icon v-on="on" @click="openTree">
                <v-icon small v-text="`mdi-arrow-expand`" />
              </v-btn>
            </template>

            <span v-text="$t('components.region_filter.regions_tree')" />
          </v-tooltip>
        </div>
      </div>

      <v-divider />

      <div class="pa-2 region-filter--container toolbar3">
        <div v-show="showSelected" class="region-filter--left-bar" />

        <div class="flex--column">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                small
                icon
                :style="`visibility: ${selected.length ? 'visible' : 'hidden'}`"
                @click="clear"
                v-on="on"
              >
                <v-icon x-small v-text="`mdi-close`" />
              </v-btn>
            </template>

            <span v-text="$t('common.clear_all.btn')" />
          </v-tooltip>
        </div>

        <div class="">
          <v-btn
            :value="showSelected"
            depressed
            :class="{
              'toggle--active': showSelected,
              'dark--mode': $vuetify.theme.dark,
              'bg--transparent': !selected.length,
            }"
            :disabled="!selected.length"
            class="show--selected overline"
            @click="showSelected = !showSelected"
          >
            <span> {{ selected.length }} {{ $t('common.selected') }}</span>

            <v-icon x-small v-text="`mdi-eye`" />
          </v-btn>
        </div>
      </div>
    </div>

    <v-expand-transition>
      <RegionListMultipleSelector
        v-if="showList"
        v-model="selected"
        :search="search"
        :show-selected="showSelected"
        :user-regions="userRegions"
      />
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { WidgetComponentEvents } from '@/tt-widget-entity-flow'
import TSearchInput from '@/tt-ui/components/TSearchInput.vue'

import { Filter, FilterWithValue } from '../../types'
import { UserRegion } from '../treeview/utils'
import { LayoutWindowEvent } from '@/tt-app-layout/types'
import { updateDOM } from '@/helpers/dom/updateDOM'
import RegionListMultipleSelector from './RegionListMultipleSelector.vue'
import { isActive } from '@/tt-region-manager/utils'

export default Vue.extend({
  name: 'CustomFilterRegionRelation',
  components: { TSearchInput, RegionListMultipleSelector },
  props: {
    value: {
      type: Object as PropType<Filter>,
      required: true,
    },
    /**
     * We use a callback instead of an event to set the selected filter regions in the tree,
     * because the regions tree is a detached modal from the region filter,
     * and there is no parent to listen to the event.
     */
    onUpdate: {
      type: Function as PropType<(filter: Filter) => void>,
      required: true,
    },
  },
  data() {
    return {
      showList: false,
      search: '',
      showInactive: false,
      showSelected: false,
    }
  },
  computed: {
    orderedUserRegions(): UserRegion[] {
      const contextRegions = [
        ...this.$appContext.regionManager.getContextRegions(),
      ]

      return contextRegions.sort((a, b) => a.name.localeCompare(b.name))
    },
    userRegions(): UserRegion[] {
      return this.showInactive
        ? this.orderedUserRegions
        : this.orderedUserRegions.filter(isActive)
    },
    selected: {
      get(): string[] {
        // TODO: narrow down Filter type
        const filterValue = (this.value?.value || '') as string

        return filterValue.split(',').filter(Boolean)
      },
      set(newVal: string[] = []) {
        const value = newVal.join(',')
        this.$emit('input', { ...this.defaultModel, value })
      },
    },
    defaultModel(): FilterWithValue {
      return {
        attribute: this.value?.attribute,
        operator: FilterOperatorType.IN,
        value: null,
      }
    },
  },
  watch: {
    selected: {
      /**
       * Hide the "selection list" when the selected regions are cleared
       */
      handler(selected) {
        if (!selected || selected.length === 0) {
          this.showSelected = false
        }
      },
    },
  },
  async mounted() {
    await updateDOM()
    this.showList = true
  },
  methods: {
    setSearch(search: string) {
      this.search = search?.toLowerCase()
    },
    clear() {
      this.selected = []
    },
    async openTree() {
      await updateDOM()

      this.$emit('cancel')

      this.$eventManager.dispatchEvent(WidgetComponentEvents.REGION_FILTER, {
        filter: { ...this.defaultModel, value: this.selected },
        userRegions: this.$appContext.regionManager.getContextRegions(),
        onUpdate: (ids: string[]) => {
          this.onUpdate({
            ...this.defaultModel,
            value: ids.join(','),
          })
        },
        onClose: () => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)
        },
      })
    },
  },
})
</script>

<style scoped>
.show-inactive--on {
  color: var(--v-ttPrimary-base) !important;
  background-color: var(--v-ttPrimary-lighten5) !important;
}

.button--icon {
  width: 40px !important;
  height: 40px !important;
}

.region-filter--container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
}

.region-filter--left-bar {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-left: 2px solid var(--v-ttPrimary-base);
}

.v-btn.show--selected.toggle--active {
  color: var(--v-ttPrimary-base);
  background-color: var(--v-ttPrimary-lighten5);
}

.v-btn.show--selected.toggle--active.dark--mode {
  color: var(--v-ttPrimary-base);
  background-color: var(--v-ttPrimary-darken4);
}

.v-btn.show--selected .v-icon {
  width: 0;
  opacity: 0;
  transition: all 0.3s;
  margin-left: 6px;
}

.v-btn.show--selected.toggle--active .v-icon {
  width: 16px;
  opacity: 0.7;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.bg--transparent {
  background-color: transparent !important;
}
</style>
