<template>
  <DispatchTasksFormClientAddressTile
    v-if="!isCustomLocation"
    :client-items="clientItems"
  />

  <DispatchTasksFormCoordinatesTile
    v-else-if="isCoordinates && isCustomLocation"
  />

  <DispatchTasksFormAddressTile v-else-if="isAddress && isCustomLocation" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import { FormHookProvider } from '@/tt-widget-components'
import DispatchTasksFormClientAddressTile from './DispatchTasksFormClientAddressTile.vue'
import DispatchTasksFormCoordinatesTile from './DispatchTasksFormCoordinatesTile.vue'
import DispatchTasksFormAddressTile from './DispatchTasksFormAddressTile.vue'
import { ClientInterface, LocationType } from '../types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormLocationTile',
  components: {
    DispatchTasksFormClientAddressTile,
    DispatchTasksFormCoordinatesTile,
    DispatchTasksFormAddressTile,
  },
  inject: ['formHook', 'dispatchFormService'],
  computed: {
    clientItems(): ClientInterface {
      return this.dispatchFormService.getClientItems()
    },
    isCustomLocation(): boolean {
      return this.dispatchFormService.getIsCustomLocation()
    },
    isCoordinates(): boolean {
      return this.locationType === LocationType.COORDINATES
    },
    isAddress(): boolean {
      return this.locationType === LocationType.ADDRESS
    },
    locationType(): string {
      return this.formHook().getPathValue('locationType')
    },
  },
})
</script>
