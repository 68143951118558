<template>
  <div v-if="loading">
    <v-skeleton-loader type="text, text, sentences, sentences" />
  </div>
  <div v-else>
    <json-field name="name" />
    <template v-if="!extendable">
      <json-field name="details" />
      <json-field name="category" />
      <json-field name="type" />
      <json-field
        name="defaultLanguage"
        :placeholder="$t(defaultLanguagePlaceholder)"
      />
      <json-field name="tag" :label="$t(reportTagPlaceholder)" />
      <json-field name="translatable" as="BooleanSelector" />
      <json-field
        v-if="canShowAssociatedFeature"
        :name="associatedFeaturesName"
        as="ReportTemplateFeatureAssociationField"
        :label="$t(featureAssociationPlaceholder)"
        :features="features"
      />
    </template>
    <json-field name="approveAutomatically" />
    <template v-if="!extendable">
      <json-field name="adminOnly" :label="$t(adminOnlyPlaceholder)" />
      <json-field name="notifyAlertQueue" />
      <json-field name="excludeFromShiftReport" />
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { getFeatureAssociationsForAccount } from './helpers'
import { ReportTemplateType } from './types'
import { Attribute } from '@/tt-entity-design/src/schema-types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'ReportTemplatesForm',
  inject: ['formHook'],
  data() {
    return {
      features: null,
      loading: true,
    }
  },
  computed: {
    adminOnlyPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.admin-only.placeholder'
    },
    defaultLanguagePlaceholder(): string {
      return 'tt-entity-design.report-templates.form.default-language.placeholder'
    },
    reportTagPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.tag.placeholder'
    },
    featureAssociationPlaceholder(): string {
      return 'tt-entity-design.report-templates.form.feature-association.placeholder'
    },
    accountId(): number | null {
      return this.formHook().getPathValue('assignedSite')
    },
    reportType(): string {
      return this.formHook().getPathValue('type')
    },
    canShowAssociatedFeature(): boolean {
      return (
        this.accountId &&
        this.reportType !== ReportTemplateType.DISPATCHABLE_TASK
      )
    },
    associatedFeaturesName(): Attribute<'report-templates'> {
      return 'associatedFeatures'
    },
    extendable(): boolean {
      return this.formHook().getPathValue('extendable')
    },
  },
  watch: {
    reportType: {
      immediate: true,
      handler(type: string) {
        if (type === ReportTemplateType.DISPATCHABLE_TASK) {
          this.formHook().setObjectValue(this.associatedFeaturesName, [])
        }
      },
    },
  },
  created() {
    if (this.accountId !== null) {
      this.loadFeatureAssociation()
    } else {
      this.loadingDone()
    }
  },
  methods: {
    async loadFeatureAssociation() {
      try {
        const response = await getFeatureAssociationsForAccount(
          this.$auth,
          this.accountId as number,
        )
        this.features = response.reportTemplateFeatures.features
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.loadingDone()
      }
    },
    loadingDone() {
      this.loading = false
    },
  },
})
</script>
