export enum WidgetName {
  COLUMN_CHART_WIDGET = 'ColumnChartWidget',
  DASHBOARD_WIDGET = 'DashboardWidget',
  DATA_TABLE_WIDGET = 'DataTableWidget',
  GAUGE_CHART_WIDGET = 'GaugeChartWidget',
  HEAT_MAP_WIDGET = 'HeatMapWidget',
  HORIZONTAL_CHART_WIDGET = 'HorizontalChartWidget',
  LINE_CHART_WIDGET = 'LineChartWidget',
  LIST_WIDGET = 'ListWidget',
  MAP_WIDGET = 'MapWidget',
  MAP_MARKERS_WIDGET = 'MapMultiMarkersWidget',
  METRIC_TABLE_WIDGET = 'MetricTableWidget',
  PIE_CHART_WIDGET = 'PieChartWidget',
  PIVOT_TABLE_WIDGET = 'PivotTableWidget',
  RICH_TEXT_WIDGET = 'RichTextWidget',
  SCHEDULER_WIDGET = 'SchedulerWidget',
  SCHEDULER_TIMELINE_WIDGET = 'SchedulerTimelineWidget',
  CALENDAR_WIDGET = 'CalendarWidget',
  CALENDAR_WEEKLY_WIDGET = 'CalendarWeekWidget',
  SINGLE_COUNTER_WIDGET = 'SingleCounterWidget',
  TQL_QUERY_WIDGET = 'TQLQueryWidget',
  TREE_MAP_WIDGET = 'TreeMapWidget',
  VIMEO_WIDGET = 'VimeoWidget',
  YOUTUBE_WIDGET = 'YoutubeWidget',
}

export enum AttributeName {
  ACCOUNT_ATTRIBUTE = 'accountAttribute',
  DATE_RANGE_ATTRIBUTE = 'dateRangeAttribute',
  REGION_ATTRIBUTE = 'regionAttribute',
}
