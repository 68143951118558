// ******** This file is autogenerated DO NOT MODIFY **********

import { modularManager, PresetTypes, PresetLevels } from '@/tt-app-modular'
import { Resources } from '../../types'
import custom from './custom'
import EntityLabelTranslationsTile from './EntityLabelTranslationsTile.vue'
import EntityLabelTranslationsColumns from './EntityLabelTranslationsColumns'
import EntityLabelTranslationsDetail from './EntityLabelTranslationsDetail.vue'
import EntityLabelTranslationsReference from './EntityLabelTranslationsReference.vue'

export default {
  install(Vue) {
    Vue.use(custom)
    Vue.component('EntityLabelTranslationsTile', EntityLabelTranslationsTile)
    Vue.component(
      'EntityLabelTranslationsDetail',
      EntityLabelTranslationsDetail,
    )
    Vue.component(
      'EntityLabelTranslationsReference',
      EntityLabelTranslationsReference,
    )

    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_TRANSLATIONS,
      PresetTypes.LIST_ITEM,
      {
        title: 'Default',
        data: { is: 'EntityLabelTranslationsTile' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_TRANSLATIONS,
      PresetTypes.COLUMNS,
      {
        title: 'Default',
        data: EntityLabelTranslationsColumns,
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_TRANSLATIONS,
      PresetTypes.DETAILS_CARD,
      {
        title: 'Default',
        data: { is: 'EntityLabelTranslationsDetail' },
        level: PresetLevels.DEFAULTS,
      },
    )
    modularManager.addResourcePreset(
      Resources.ENTITY_LABEL_TRANSLATIONS,
      PresetTypes.RELATION_REFERENCE,
      {
        title: 'Default',
        data: { is: 'EntityLabelTranslationsReference' },
        level: PresetLevels.DEFAULTS,
      },
    )
  },
}
