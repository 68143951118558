<template>
  <div
    v-if="isTranslationLoaded"
    id="leave-requests-view"
    class="d-flex flex-column fill-height"
  >
    <header class="py-3 px-6">
      <h6 class="text-h6" v-text="$t(leaveRequestsTitle)" />
    </header>
    <div id="leave-request-view">
      <v-toolbar class="toolbar3 text-capitalize py-0 px-6" flat short>
        <v-tabs
          v-if="!isXSmall"
          v-model="selectedTab"
          show-arrows
          slider-color="var(--v-ttPrimary-base)"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title"
            class="text-capitalize px-2 mr-6"
            active-class="active-icon"
            :data-analytics="`leave-requests-${tab.key}-tab`"
            @click="closeSidePanel"
          >
            <v-icon small left v-text="tab.icon" />
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <template v-if="isXSmall" #extension>
          <v-tabs
            v-model="selectedTab"
            slider-color="var(--v-ttPrimary-base)"
            show-arrows
            align-with-title
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.title"
              active-class="active-icon"
              class="text-capitalize"
              :data-analytics="`leave-requests-${tab.key}-tab`"
              @click="closeSidePanel"
            >
              <v-icon small left v-text="tab.icon" />
              {{ $t(tab.title) }}
            </v-tab>
          </v-tabs>
        </template>
        <v-spacer />
      </v-toolbar>
    </div>
    <div class="fill-height d-flex flex-column">
      <v-tabs-items v-model="selectedTab" class="fill-height">
        <v-tab-item
          v-for="tab in tabs"
          :key="tab.key"
          active-class="fill-height"
        >
          <component
            :is="tab.is"
            :key="tab.key"
            :allow-actions="allowActions"
            :is-premium="isPremium"
            :started-on="startedOn"
            :ended-on="endedOn"
            :origin="origin"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
  <div v-else>
    <v-skeleton-loader type="text, table-row, table" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import isEmpty from 'lodash/isEmpty'
import LeaveRequestsCompletedTab from './completed/LeaveRequestsCompletedTab.vue'
import LeaveRequestsPendingTab from './pending/LeaveRequestsPendingTab.vue'
import LeaveRequestsAbsentTab from './absent/LeaveRequestsAbsentTab.vue'
import LeaveRequestsCancellationsTab from './cancellations/LeaveRequestsCancellationsTab.vue'
import LeaveRequestsTimelineView from './LeaveRequestsTimelineView.vue'
import {
  LeaveRequestInjectedOrigin,
  LeaveRequestsAvailableTabs,
  LeaveRequestsTab,
} from '../types'
import { LayoutComponents } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import { UnsubscribeFunction } from '@tracktik/tt-event-manager'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { Resources } from '@/tt-entity-design/src/types'

export default (
  Vue as VueConstructor<Vue & { layoutManager: LayoutManager }>
).extend({
  name: 'LeaveRequestsView',
  components: {
    LeaveRequestsCompletedTab,
    LeaveRequestsPendingTab,
    LeaveRequestsAbsentTab,
    LeaveRequestsCancellationsTab,
    LeaveRequestsTimelineView,
  },
  inject: ['layoutManager'],
  props: {
    allowActions: {
      type: Boolean,
      default: true,
    },
    initialTab: {
      type: String,
      default: '',
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
    displayAbsentTab: {
      type: Boolean,
      default: false,
    },
    startedOn: {
      type: String,
      default: () => '',
    },
    endedOn: {
      type: String,
      default: () => '',
    },
    /* Product Decision to keep track of the origin of the Leave Request
     * Based on the place this view is used we are going to see different filters:
     * - Payroll -> Filter by Period (currently it has no support for presets)
     * - Dashboard -> Filter by Start Date (keep current production behavior, with support for presets)
     *
     * More info: https://tracktik.atlassian.net/browse/BOSS-4090?focusedCommentId=381434
     */
    origin: {
      type: String as PropType<LeaveRequestInjectedOrigin>,
      default: '',
    },
  },
  data() {
    return {
      selectedTab: 0,
      baseTranslationKey: 'tt-entity-design.leave-management.leave-requests',
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    isTranslationLoaded(): boolean {
      return !isEmpty(this.$i18n.messages)
    },
    isXSmall(): boolean {
      return this.$vuetify.breakpoint.xs
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    leaveRequestsTitle(): string {
      return `res.leave-requests.label`
    },
    tabs(): LeaveRequestsTab[] {
      const absentTab = {
        key: LeaveRequestsAvailableTabs.ABSENT,
        title: this.$t(
          `${this.baseTranslationKey}.tabs.absent.title`,
        ) as string,
        icon: 'mdi-account-off',
        is: 'LeaveRequestsAbsentTab',
      }
      const pendingTab = {
        key: LeaveRequestsAvailableTabs.PENDING,
        title: this.$t(
          `${this.baseTranslationKey}.tabs.pending.title`,
        ) as string,
        icon: 'mdi-timer-sand-empty',
        is: 'LeaveRequestsPendingTab',
      }
      const cancellationsTab = {
        key: LeaveRequestsAvailableTabs.CANCELLATIONS,
        title: this.$t(
          `${this.baseTranslationKey}.tabs.cancellations.title`,
        ) as string,
        icon: 'mdi-minus-circle-outline',
        is: 'LeaveRequestsCancellationsTab',
      }
      const completedTab = {
        key: LeaveRequestsAvailableTabs.COMPLETED,
        title: this.$t(
          `${this.baseTranslationKey}.tabs.completed.title`,
        ) as string,
        icon: 'mdi-check',
        is: 'LeaveRequestsCompletedTab',
      }
      const calendarTab = {
        key: LeaveRequestsAvailableTabs.CALENDAR,
        title: this.$t(
          `${this.baseTranslationKey}.tabs.calendar.title`,
        ) as string,
        icon: 'mdi-calendar',
        is: 'LeaveRequestsTimelineView',
      }
      const fixedTabs = [
        pendingTab,
        cancellationsTab,
        completedTab,
        calendarTab,
      ]

      return this.displayAbsentTab ? [absentTab, ...fixedTabs] : fixedTabs
    },
  },
  mounted() {
    const tabIndex = this.getTabIndexByKey(this.initialTab)
    if (tabIndex < 0) return

    this.selectedTab = tabIndex
  },
  created() {
    // close side panel when actioning a leave request
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.LEAVE_REQUESTS) {
          this.closeSidePanel()
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribeFn) this.unsubscribeFn()
  },
  methods: {
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
    getTabIndexByKey(key: LeaveRequestsAvailableTabs | string): number {
      return this.tabs.findIndex(
        (tab: LeaveRequestsTab) => tab.key === key.toLowerCase(),
      )
    },
  },
})
</script>
<style scoped>
.leave-requests-title {
  height: 3.5rem;
  display: flex;
  align-content: center;
}
/*
  Overriding default behaviour for flex items min-width: auto.
  This will allow the tabs to adapt the content properly, see: https://stackoverflow.com/a/36247448
*/
#leave-request-view {
  min-width: 0;
}

.active-icon .v-icon {
  color: var(--v-ttPrimary-base);
}
</style>
