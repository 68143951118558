import { Status } from './types'
import { DISPATCHABLE_TYPE, TYPE_VALUES } from './constants'
import { CTAType } from './cta-types'

const DEFAULT_STATUS_COLOR = 'grey'

const STATUS_COLOR_MAP: Record<Status, string> = {
  OPEN: 'red',
  CLOSED: 'green',
  PENDING: 'orange',
}

export const getStatusColor = (status: Status) =>
  STATUS_COLOR_MAP[status] || DEFAULT_STATUS_COLOR

export const isDispatchableSystemExceptionTickets = (type: CTAType): boolean =>
  DISPATCHABLE_TYPE.includes(type)

export const isPanicTriggeredTicket = (type: CTAType): boolean =>
  TYPE_VALUES.PANIC_TRIGGERED_TICKET === type
