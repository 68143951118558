// Enter your custom installation here

import { Resources } from '@/tt-entity-design/src/types'
import { setAttributeView } from '@/tt-widget-entity-flow/helper'
import LoneWorkerCheckInStatus from '@/tt-entity-design/src/components/lone-worker-check-ins/LoneWorkerCheckInStatus.vue'
export default {
  install(Vue) {
    Vue.component('LoneWorkerCheckInStatus', LoneWorkerCheckInStatus)

    setAttributeView(Resources.LONE_WORKER_CHECK_INS, 'status', {
      is: 'LoneWorkerCheckInStatus',
    })
  },
}
