import { registerResourceForm } from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'

import TaskSiteInstructionsForm from './TaskSiteInstructionsForm.vue'
import TaskSiteInstructionsLinkAsset from './TaskSiteInstructionsLinkAsset.vue'

export default {
  install(Vue) {
    Vue.component('TaskSiteInstructionsForm', TaskSiteInstructionsForm)
    registerResourceForm(
      Resources.TASK_SITE_INSTRUCTIONS,
      'TaskSiteInstructionsForm',
    )

    Vue.component(
      'TaskSiteInstructionsLinkAsset',
      TaskSiteInstructionsLinkAsset,
    )
    registerResourceForm(
      Resources.TASK_SITE_INSTRUCTIONS,
      'TaskSiteInstructionsLinkAsset',
      'link-asset',
    )
  },
}
