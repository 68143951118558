import { WidgetName } from '@/tt-widget-components/lib/names'
import { WidgetTypeDefinition } from '@/tt-widget-factory'

import hook from './SchedulerWidgetHook'
import schema from './schema'
import { handleAsyncComponentError } from '@/tt-widget-components/lib/handle-async-component-error'

const template = () =>
  import('./SchedulerWidget.vue').catch(handleAsyncComponentError)

const SchedulerWidget: WidgetTypeDefinition = {
  name: WidgetName.SCHEDULER_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  config: {
    icon: 'mdi-calendar',
    color: '#f23d3d',
    // @todo: FE-1788 get an appropriate thumbnail
    thumbnail: require('./data-table.png'),
    description: 'Scheduler',
  },
}

export default SchedulerWidget
